.assignment-library {
  height: 100vh;

  .header {
    padding: 0;

    div.left {
      height: 100%;

      .md-button.bar-item {
        min-width: 6rem;
        margin: 0 15px 0 0;
        min-height: unset;

        span {
          margin: 3px 0 0 10px;
          height: 100%;
          text-transform: none;
        }
      }

      .logo {
        margin-left: 24px;
        height: 30px;
      }

      .logo:hover {
        cursor: pointer;
      }

      .logo:focus {
        outline: none;
      }
    }

  }

  .body {
    height: 100%;

    .user-info {
      margin: 0;
      padding: 1.6rem .8rem;
      background: $ckGreen;

      .classkick-header {
        color: white;
      }

      md-select {
        font-weight: bold;
        font-size: 1.4rem;
      }

      .search-tools {

        md-input-container {
          padding: 0 .8rem;
          margin: 0;
        }

        md-select-value {
          border: white 1px solid;
          border-radius: 8px;
          height: 4.8rem !important;
          padding-left: 5.0rem;
        }

        .search-input {
          margin: 16px 0 0 0;
          width: 100%;
          font-size: 1.4rem;

          .anchor {
            top: 8px;

            .ck-search {
              top: 0.8rem !important;
              font-size: 2.1rem;
              left: 2rem !important;
              color: $lightText;
            }
          }

          input {
            border: white 1px solid;
            border-radius: 8px;
            height: 4.8rem;
            padding-left: 5.5rem !important;
          }

          input::placeholder {
            color: $lightText;
            opacity: 1;
          }
        }

        md-input-container.subject-option {
          width: 200px;
          margin-left: 0;
        }

        .grade-option {
          width: 150px;
        }

        .org-option {
          md-select[disabled] .md-select-value {
            background-image: none;
          }

          .md-select-value {
            padding-left: 5.5rem;
          }
        }

        .ck-library, .ck-pound, .ck-backpack {
          color: $ckGreenProgress;
          margin-right: 1.5rem;
          position: absolute;
          top: 1.4rem;
          left: 2.1rem;
          z-index: 5;
          font-size: 2rem
        }

        .ck-backpack {
          font-size: 2.4rem;
        }
      }
    }

    .md-virtual-repeat-container {
      height: 100vh;

      &.with-banner {
        min-height: calc(#{$teacherListHeightWithBanner} - 5em);
        max-height: calc(#{$teacherListHeightWithBanner} - 5em);
      }

      md-list {
        overflow: auto;
        padding: 2rem 4.8rem 4rem 4.8rem;
        background-color: transparent !important;


        md-list-item.assignment-preview-list-item {

          .assignment-sheet-container {
            display: inline-block;
            position: relative;
            width: 30.6rem;
            height: 23rem;
            margin: 24px 24px 24px 0;
            cursor: pointer;

            & > * {
              position: absolute;
            }

            .slide-count-image {
              position: absolute;
              bottom: 1.2rem;
              left: 1.2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 4.8rem;
              width: 4.8rem;
              border-radius: 50%;
              box-shadow: 0px 12px 16px 0px #0000000A;
              box-shadow: 0px 4px 8px 0px #00000014;
              box-shadow: 0px 2px 4px 0px #0000001F;
              background: $white;

              .ck-slide-play {
                font-size: 1.9rem;
                color: $ckHeaderGrey;
              }
            }

            .slide-count {
              background-color:$ckHeaderGrey;
              color: $white;
              height: 2.4rem;
              min-width: 2.4rem;
              border-radius: 50%;
              text-align: center;
              line-height: 2.4rem;
              position:absolute;
              bottom: 0.35rem;
              left: 4.4rem;
              padding: 0 0.1rem;
            }

            assignment-sheet {
              width: inherit;
              height: inherit;
              overflow: hidden;
              background-color: white;
              border: 0.5px solid $lightText;
              border-radius: 12px;

              outline: none;

              /* Our canvas is 2:1, so calculate height based on width */
              svg {
                pointer-events: none;
                height: calc(2 * #{$teacherAssignmentEditSheetWidth});
              }
            }

            .copy-button {
              min-width: auto;
              width: 4.8rem;
              height: 4.8rem;
              border-radius: 100px;
              color: white;
              background-color: $ckGreen;
              position: absolute;
              bottom: 0;
              right: 0;
              margin: 12px;
              padding: 1.5rem;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 12px 16px rgba(0, 0, 0, 0.04);

              &:hover{
                background-color: $ckGradientGreen;
              }

              .ck-plus {
                font-size: 1.8rem;
              }
            }
          }

          .assignment-info {
            height: 30.6rem;
            max-height: 30.6rem;
            padding: 24px 0 24px 24px;
            color: $ckHeaderGrey;

            .author-name, .assignment-tags {
              font-size: 1.3rem;
              letter-spacing: 2px;
              line-height: 1.6rem;
            }

            .author-name {
              text-transform: uppercase;
              color: $ckGreenProgress;
              margin-bottom: 2.4rem;
            }

            .assignment-title {
              font-family: $fontRoundedMedium;
              margin-bottom: 12px;
              font-size: 1.5rem;
              outline: none;
              cursor: pointer;
            }

            .assignment-description {
              -webkit-line-clamp: 4;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              font-size: 1.5rem;
              max-height: 88px;
              margin-bottom: 24px;
              color: $ckHeaderGrey;
              line-height: 1.4em;
              white-space: pre-wrap;
              font-family: $fontRoundedBook;
            }

            .assignment-tags {
              font-family: $fontRoundedMedium;
              text-transform: uppercase;
              color: $lightText;

              .subject, .grade {
                margin-bottom: 1.6rem;

                .subject-tags {
                  white-space: nowrap;
                  max-width: inherit;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .ck-pound, .ck-backpack {
                margin-right: 1rem;
                font-size: 2rem;
              }

              > * {
                margin-right: 24px;
              }
            }
          }
        }

        .list-helper a {
          text-transform: none;
        }
      }
    }
  }

  .list-helper {
    padding: 32px 64px;
  }

  .permission-error {
    background-color: $backgroundGrey;
  }

  .error, .loading {
    text-align: center;

    .loading-text, .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }

}

