.account-clever {
  background-color: $backgroundGrey;

  div.root-content-parent {
    height: 100%;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    text-align: center;
    font-family: $fontRoundedBook;

    div {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}
