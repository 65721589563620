.user-profile {

  height: 100vh;

  .header .left {
    height: inherit;
  }

  .loading, .error {
    text-align: center;
    height: 100%;

    .loading-text, .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }

  .separator {
    border-top: 1px solid $disabledGrey;
    margin: 3rem 0 3rem 0;
  }

  .tabs {
    padding: 0 19px;
    height: 32px;
    margin-top: 8px;

    .tab {
      min-width: 101px;
      padding: 0 12px;
      margin: 0 24px 0 0;
      border-radius: 0;
      color: $ckGreen;
      text-transform: none;
      cursor: pointer;
      outline: none;
      border-bottom: 3px solid transparent;

      &.active {
        color: $ckHeaderGrey;
        border-bottom: 3px solid $ckHeaderGrey;
      }

      &:hover {
        background-color: white;
      }

      .md-ripple-container {
        border-radius: 0;
      }
    }
  }


  .body {
    overflow-y: auto;

    &.with-banner {
      min-height: $teacherListHeightWithBanner;
      max-height: $teacherListHeightWithBanner;
    }
  }

  .delete-account {
    .md-button {
      color: $ckWarn;
      margin-left: 0;
    }
  }

  .edit-profile {

    .info-view, .plan-view{
      padding: 2.4rem
    }

    .edit-profile-column:first-child {
      padding-right: 2.4rem;
    }

    .edit-profile-column {
      box-sizing: border-box;

      > * {
        box-sizing: border-box;
      }

      min-width: 20em;
      width: 100%;
      max-width: 40em;

      md-input-container.first-name,
      md-input-container.last-name,
      md-input-container.email,
      md-input-container.username,
      md-input-container.student-age {
        margin-top: 12px;
        padding: 0;
      }

      md-autocomplete {
        margin-top: 12px;
      }

      md-autocomplete.prefix,
      md-autocomplete.role {
        margin-top: 0;
      }
    }

    .pro-plans, .basic-plan, .schools-view {

      .school {
        padding: 3.2rem 2.4rem;
        border-bottom: 1px solid $dividerGrey;
      }

      .unverified {
        display: flex !important;
        background-color: $ckLightWarn;
        margin: 1.5rem 2.4rem 1.0rem 2.4rem;
        border-radius: 10px;
        line-height: 1.8em;

        .unverified-bold {
          font-weight: bolder;
        }

        .headericon {
          margin: .1em 1em 0em 0em;
        }
      }

      .grandfathered {
        padding: 1.2rem 2.4rem;
        border-bottom: 1px solid $dividerGrey;
        display: flex !important;
        background-color: $backgroundGreen;
        margin: 1.5rem 2.4rem 1.0rem 0;
        border-radius: 10px;
        line-height: 1.8em;
        a {
          color: $ckHeaderGrey;
          text-transform: none;
          text-decoration: underline;
        }

        .grandfathered-bold {
          font-weight: bolder;
        }

        .headericon {
          margin: .1em 1em 0em 0em;
        }
      }

      .plan, .school {
        font-family: $fontRoundedBook;
        display: grid;
        grid-template-columns: 2fr 1fr;

        .contract-name, .school-name {
          font-family: $fontRoundedMedium;
        }

        .school-name {
          margin-bottom: 8px;
          font-size: 1.6em;
          line-height: 1.25em;
        }

        .school-location {
          line-height: 1.4em;
          margin-bottom: 0.9em;

          .verification-status {
            color: $ckWarn;
            font-weight: bold;
            margin-top: 0.2em;
            margin-bottom: 0.2em;
            letter-spacing: 2px;
            font-size: x-small;
            display: flex;

            .verification-status-inner {
              margin: .2em 0em 0em 1em;
            }
          }
        }

        .admin-section {
          .admin-title {
            font-weight: bold;
          }
        }

        .leave-org {
          display: block;
          margin-top: 1.25em;
          color: $ckWarn;
        }

        .unlimited-assignments {
          color: $lightText;
          margin: 21px 0 0 0;
        }

        .verify-btn {
          border: 1px solid;
        }
        .verify-container {
          display: flex;
          align-self: flex-start;
          justify-content: flex-end;
        }
      }

      .plan {
        margin-bottom: 24px;
      }

      .clever-message {
        margin-top: 35px;
      }

      .join-org {
        display: block;
        padding: 3.2rem 2.4rem;
      }

      .plan:last-child {
        margin-bottom: 0;
      }

      .expiration-explainer {
        padding: 0 2em;
        max-width: 35em;
        font-family: $fontRoundedBook;
        word-break: break-word;
        font-size: 1.4rem;
        color: $ckAltGrey;

        .date {
          font-weight: bold;
        }

        .upgrade-link {
          border: honeydew;
          background: none;
          font-style: inherit;
          color: $red;
          font-family: $fontRoundedMedium;
          text-decoration: none;
          cursor: pointer;
          padding: 0;
          text-align: left;
          word-break: break-word;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .assignments-used-visual {
        height: 15px;

        .used, .total {
          position: absolute;
          height: 15px;
          border-radius: 10px;
        }

        .used {
          background-color: $ckGreen;
          transition: width 0.5s ease-in-out;
        }

        .total {
          width: 500px;
          background-color: $disabledGrey;
        }
      }

      .assignments-used-text {
        color: $lightText;
        font-family: $fontRoundedBook;
        margin: 21px 0 21px 0;
      }

      .upgrade-to-pro .md-button {
        margin: 0;
      }
    }

    .update-password-form, .add-password-form {

      .change-password-form, .new-password-form {

        h4 {
          margin: 0 0 2rem 0;
        }

        md-input-container {
          padding-left: 0;
          margin-top: 12px;
        }

        md-input-container:first-of-type {
          margin-top: 0;
        }

        .show-new-password {
          margin-top: 12px;
          margin-left: -2px;
        }

        .md-button.ck-primary {
          margin-left: 0;
        }

        md-checkbox.md-checked .md-icon {
          background: $ckGreen;
        }
      }
    }

    .pro-invite-form {

      h4 {
        margin: 0 0 2rem 0;
      }

      md-input-container {
        padding-left: 0;
      }

      md-input-container:first-of-type {
        margin-top: 0;
      }

      .md-button.ck-primary {
        margin: 1.6rem 0 0 0;
      }
    }
  }
}
.icon {
  font-size: large;
}
