.financial-aid-dialog {
  min-width: unset;
  max-height: unset;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close, .ck-back {
      color: $ckGreen;
    }
  }

  .ck-dialog-body {
    max-width: 500px;
    min-width: 500px;
    margin: 0 60px 60px 60px;

    .ck-dialog-title {
      @extend .classkick-header;
      text-align: center;
      margin-bottom: 15px;
      text-transform: capitalize;
    }

    p {
      margin: 0 0 15px 0;
      font-family: $fontRoundedBook;
      text-align: center;
    }

    md-input-container.name,
    md-input-container.email,
    md-input-container.signature {
      padding-left: 0;
      padding-right: 0;

      .label {
        text-transform: capitalize;
      }
    }

    md-input-container.signature input {
      font-family: cursive;
    }

    md-checkbox {
      margin: 20px 0 25px 0;

      .md-label {
        font-family: $fontRoundedBook;
        margin-top: 0;
      }
    }

    .error-messages {
      div {
        font-size: 0.8rem;
      }
    }

    .md-button.ck-primary {
      margin: 20px 0 0 0;
    }
  }
}

