.assignment-question-header {
  height: $teacherHeaderHeight;
  border-bottom: 1px solid $dividerGrey;
  color: $ckHeaderGrey;
  padding: 0 1.6rem 0 0.5rem;

  .assignment-question-header-left {
    height: $teacherHeaderHeight;
  }
}

assignment-question {
  @include flex-column(stretch, stretch);
  width: 100%;
}

.assignment-question {

  min-height: 100%;
  width: 100%;

  .loading {
    text-align: center;
    height: 100vh;

    .loading-text {
      padding: 2em;
      font-family: $fontRoundedBook;
    }
  }


  .floating-toolbar-container {
    background-color: transparent;
    height: 0;
    width: 100%;

    z-index: 78;
    pointer-events: none;

    &.bottom {
      bottom: 73px;
      position: fixed;
    }

    .floating-toolbar {
      padding: .75em 1em 1.5em 1em;
      pointer-events: none;

      .student-pager, .pager {
        border-radius: 2em;
        background: white;
        box-shadow: $boxShadowCard;
        pointer-events: auto;

        .change-question-btn {
          color: $ckHeaderGrey;
          min-width: 40px;
          min-height: 40px;
          margin: 0;
        }

        .change-question-btn:disabled {
          color: $disabledGrey;
        }

        .option {
          margin: 0;
          width: 100%;

          .md-select-value {
            border: none;

            .md-select-icon {
              width: 12px;
              margin: 2px 0 0 4px;
            }
          }
          md-select-value.md-select-value {
            height: 2.4em;
          }
        }
      }

      .student-pager {
        margin-left: 1em;
      }
    }

    .helper-banner {
      position: absolute;
      top: 110px;
      left: 5px;

      &.view-only{
        top: 61px;
      }

      .teacher,
      .student,
      .feedback {
        width: 30vw;
        max-width: 320px;
        padding: 8px 10px 7px 10px;
        margin: 0.1em 0;
        border-radius: 0.15em;
        color: white;
        font-family: $fontRoundedMedium;

        span {
          margin: 0 0.2em 0 0;
        }

        div {
          margin: auto 0;
        }
      }

      .teacher {
        background-color: $ckTeacherHelper;
      }

      .student {
        background-color: $ckStudentHelper;

        span::first-letter {
          text-transform: capitalize;
        }
      }

      .feedback {
        background-color: $ckHeaderGrey;
        line-height: unset;

        span::first-letter {
          text-transform: capitalize;
        }

        span.icon {
          margin: auto 5px auto 0;
        }
      }
    }
  }

  .content {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    min-height: 100%;
    background-color: $backgroundGrey;

    .spacer-top {
      padding-top: 2.5em;
    }

    .assignment-sheet-ctn {
      text-align: center;

      .background-footer {
        height: 3em;
      }
    }

    .spacer-bottom {
      padding-bottom: 3.5em;
    }
  }
}


@media (max-width: 959px) {
  .large-canvas {
    display: inline-block;
    height: 200vw;
    margin: 1em 0;
    width: 100vw;
  }
}

// Angular Material places the md-select menu in the global scope, but lets you define a container class
.slide-pager-select-menu,
.student-pager-select-menu {

  md-select-menu {
    max-height: none;
    md-content {
      max-height: 95vh;
      min-width: 100px;
    }
  }

  @extend .ck-select-menu;
}
