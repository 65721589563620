.admin-invites-teachers-dialog {

    min-width: unset;
    width: 600px;
    border-radius: 12px;
    max-height: 100vh;

    .ck-dialog-body {
        min-height: 300px;
        overflow-x: hidden;
    }

    md-checkbox {
        .md-icon {
            transform: scale(1.2);
            border-radius: 8px;
            border: 1px solid $slate;
        }

        &.md-checked .md-icon {
            background-color: $ckGreenProgress;
            border: 1px solid $ckGreenProgress;

            &:after {
                margin-left: 2px;
                margin-top: 1px;
                transform: rotate(29deg);
                border-radius: 1px;
                border-width: 2.5px;
                left: 3.5px;
            }
        }
    }

    .contract-options {
        justify-content: center;
        border-radius: 0px;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: end;

        .contract-options-dropdown {
            padding-top: 25px;
            padding-right: 10px;
        }
    }

    .checkbox-instructions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .instructions {
            text-transform: uppercase;
            font-family: $fontRoundedBold;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 2px;
            padding-right: 12px;

            span {
                position: relative;
                bottom: 2px;
            }

            div {
                display: inline-block;
                height: 24px;
                width: 24px;
                background-color: $backgroundGrey;
                border-radius: 8px;
                color: $slate;

                &.email {
                    position: relative;
                    top: 6px;

                    i {
                        font-size: 24px;
                    }
                }

                &.return {
                    position: relative;
                    bottom: 3px;

                    i {
                        font-size: 14px;
                        position: relative;
                        left: 3px;
                        top: 5px;
                    }
                }
            }
        }
    }

    .header {
        min-height: 30px;
        margin: 3.2rem 0 1.0rem 0;
        color: $ckAltGrey;
        text-align: center;

        .header-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding: 0 12px;
    
            div {
              min-height: 48px;
            }

            .title {
                display: flex;
                align-items: center;
                font-size: 24px;
                font-family: $fontRoundedBold;
                text-align: center;
                max-width: 43rem;
                padding: 0 1.5rem;
            }
        }

        .close {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-start;
  
            &:hover {
              i {
                color: $ckGreenProgress;
              }
            }
  
            i {
              color: $ckAltGrey;
              font-size: 14px;
              font-weight: 600;
            }
          }
    }

    .select-all-checkbox {
        font-family: $fontRoundedBold;
        margin: 0px 0px 10px 25px;

        &.dashmark {
            .md-container {
                .md-icon {
                    background-color: $ckGreenProgress;
                    border: 1px solid $ckGreenProgress;

                    &:after {
                        background-color: white;
                        border: white;
                        box-sizing: border-box;
                        transform: rotate(90deg);
                        position: absolute;
                        left: 5.75px;
                        top: 3.22222px;
                        display: table;
                        width: 2.66667px;
                        height: 10.33333px;
                        border-width: 2px;
                        border-style: solid;
                        border-top: 0;
                        border-left: 0;
                        content: '';
                        margin-left: 2px;
                        margin-top: 1px;
                    }
                }
            }

        }

        .md-label {
            height: 48px;
            margin-left: 32px;
            letter-spacing: 1px;
            font-size: 10px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
        }
    }

    .error-messages {
        padding: 12px 12px 12px 42px;
        line-height: 20px;
        font-size: 14px;

        &.selection-error {
            margin-bottom: 32px;
        }
    }
    
    .referral-email {
        margin-top: 10px;
        .referral {
          min-height: 48px;
          margin: 1.2rem 0 1.2rem 0;
          color: $ckAltGrey;
    
          .referral-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding: 0 32px;
    
            div {
              min-height: 48px;
            }
    
            .close {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              align-self: flex-start;
    
              &:hover {
                i {
                  color: $ckGreenProgress;
                }
              }
    
              i {
                color: $ckAltGrey;
                font-size: 14px;
                font-weight: 600;
              }
            }
    
            .title {
              display: flex;
              align-items: center;
              font-size: 24px;
              font-family: $fontRoundedBold;
              text-align: center;
              max-width: 43rem;
              padding: 0 5.5rem;
            }
          }
        }
    
        .trial, .pro {
          margin: 0 32px 32px 32px;
    
          .error-messages {
            margin-left: 90px;
    
            @media screen and (max-width: 750px) {
              margin-left: 0px;
              left: 0px;
            }
          }
    
          .info {
            font-weight: bold;
            border-radius: 8px;
            padding: 14px 20px 14px 14px;
            gap: 12px;
            background-color: $ckProTrial;
            display: flex;
            margin: 12px 0px 24px 0px;
            color: $white;
    
            .announcement:after {
              position: relative;
              top: -4px;
              content: url('../../../assets/ckicon/announcement.svg');
            }
    
            .info-icon {
              font-size: 18px;
            }
    
            .info-title {
              text-transform: uppercase;
              font-family: $fontRoundedMedium;
              font-size: 10px;
              letter-spacing: .2em;
              padding: 3px 0px 10px 0px;
              font-weight: 400;
            }
    
            .info-body {
              font-size: 12px;
              font-family: $fontRoundedBook;
              line-height: 20px;
            }
          }
    
          .submit-referral {
            width: auto;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 1000;
            height: 42px;
            margin-top: 17px !important;
            background-color: $ckGreenProgress;
            color: $ckAltGrey;
            padding: 1.6rem 2.4rem;
          }

          .copy-invite {
            margin: 20px 20px 0 0;
            text-align: center;
            padding: 1.4rem;
            background-color: $veryLightGrey;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            text-transform: uppercase;
            color: $ckAltGrey;
            font-size: 1rem;
            line-height: 1.6rem;
            font-weight: 600;
            letter-spacing: 1px;
            cursor: pointer;

            &:hover {
              color: $ckGreenProgress;

              &:active {
                background-color: $ckAltGrey;
                color: $white;
              }
            }

            .ck-new-copy-file {
              margin-right: 1.4rem;
              font-size: 2rem;
            }
          }


    
          .referral-container {
            padding-left: 0;
            padding-right: 0;
            color: $ckAltGrey;
            margin: 0px 0px;
    
            .md-input {
              color: $ckAltGrey;
            }
    
            &.to {
              .mdr-select {
                width: 100%;
                max-width: 464px;
    
                @media screen and (max-width: 747px) {
                  margin-left: 69px;
                }
    
                @media screen and (max-width: 728px) {
                  margin-left: 0px;
                  max-width: none;
                }
    
                .error-messages {
                  margin-left: 5px;
                }
              }
            }
    
            span {
              left: 0px;
              top: 0px;
              border-radius: 0px;
              font-size: 1rem;
              font-weight: 600;
              letter-spacing: 2px;
              text-transform: uppercase;
              width: 500px;
              padding-bottom: 10px;
    
              @media screen and (max-width: 750px) {
                text-align: start;
              }
            }

            .clip-board {
              letter-spacing: 1px;
              text-underline-offset: 2px;
              cursor: pointer;
            }

            input, textarea {
              border: .5px solid $ckHeaderGrey;
              border-radius: 8px;
              padding: 12px;
              font-size: 1.4rem;
              height: 4.8rem;
    
              &:focus {
                border: 2px solid $ckGreenProgress;
              }
            }
    
            .email-body {
              padding: 12px !important;
            }
    
            
            input[name="toInput"], input[name="subjectInput"] {
              max-width: 548px;
    
              @media screen and (max-width: 750px) {
                margin-left: 0px;
              }
    
              @media screen and (max-width: 728px) {
                max-width: none;
              }
    
              &.error {
                border: 2px solid $ckWarn;
              }
            }
    
            input[name="toInput"] {
              color: $ckAltGrey;
              font-family: $fontRoundedBold;
              font-size: 1.4rem;
    
              &::placeholder {
                color: $slate;
              }
    
              &:focus {
                &::placeholder {
                  color: $ckAltGrey;
                }
              }
            }
    
            textarea[name="bodyInput"] {
              line-height: 2.0rem;
              overflow: auto;
              min-height: 200px;
            }
          }
    
          textarea.md-input {
            line-height: normal;
            padding-top: 8px !important;
            overflow-y: scroll;
          }
    
          .md-button.ck-primary {
            margin: 20px 0 0 150px;
          }
        }
      }
    
      .referral-email-container {
        padding-bottom: 5.5em;
    
        .ck-dialog-header {
          height: 5.5em;
        }
    
        .ck-dialog-body {
          min-height: auto;
          margin: 0 132px;
    
          &.centered {
            margin: auto;
          }
        }
    
        .md-button.bar-item {
          height: 70%;
    
          &.md-ink-ripple {
            padding: 0px;
            width: 45px;
          }
        }
      }
}