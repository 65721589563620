.assignment-rosters {
  height: 100vh;

  @media screen and (max-width: 1220px)   {
    width: max-content;
    overflow-x: auto;
  }

  .header {
    padding: 0;
    color: $ckAltGrey;
    font-size: 1.4rem;
    font-weight: bold;

    .ck-home-2023, .ck-contextualhelp, .ck-moreoptions {
      font-size: 2rem !important;
      color: $ckAltGrey !important;
    }

    .ck-gift {
      font-size: 2rem !important;
    }

    .ck-bell {
      font-size: 2.5rem !important;
    }

    .ck-forward-arrow-2023 {
      font-size: 1.6rem !important;
    }

    .md-button.back {
      margin-left: 10px;
    }

    .assignment-name {
      margin-top: 2px;
    }

    .nav-toggle {
      width: 44.4rem;

      &.show-preview-button {
        width: 65.5rem;

        .md-button {
          width: 19rem;
        }
      }

      .background-border, .md-button, .md-button[disabled] {
        border-radius: 100px;
      }

      .background-border {
        width: inherit;
        border-color: $backgroundGrey;
        background-color: $backgroundGrey;
      }

      .md-button {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 16px;
        color: $ckAltGrey;
        background-color: $backgroundGrey;
        width: 14.8rem;
        letter-spacing: 2px;
        font-weight: bold;
      }

      .md-button[disabled] {
        background-color: $ckAltGrey;
        border-color: $ckAltGrey;
        color: $white;

      }
    }

    .bar-item {
      width: 45px;
    }

    @media screen and (min-width: 1172px) {
      &.mobile {
        display: none;
      }

      .condensed-button {
        display: none;
      }
    }

    @media screen and (max-width: 1171px) {
      display: block;
      height: auto;
      padding: 0 16px 8px 16px;

      &.desktop {
        display: none;
      }

      .header-row {
        margin: 10px 0;
      }

      .md-button.back {
        margin-left: 0px;
      }

      .assignment-name {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .saving-indicator {
        display: flex;
        align-items: center;
        justify-content: center;

        .saving-message {
          margin-top: 8px;
        }
      }

      .nav-toggle {
        display: flex;
        width: 100%;

        .md-button {
          width: inherit;
          min-width: auto;
        }

        .student-preview {
          min-width: 15rem;
        }
      }

      .background-border {
        position: relative !important;
        margin-bottom: 20px;
      }

    }
  }

  .loading, .error {
    text-align: center;

    .loading-text, .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }

  .body {
    background: $backgroundGrey;
    width: 100vw;
  }

  .rosters {
    @media screen and (max-width: 1220px)   {
      width: max-content;
      overflow-x: auto;
    }

    .list-header {
      box-shadow: $boxShadow;
    }

    md-list {
      overflow: scroll;
    }

    .highlight {
      background: $ckGreen;
      color: white !important;
      border: 1px solid $ckGreen;
    }

    .bar-item.wide, .col.wide {
      width: 90px;

      margin: 0;
      padding: 0;
    }

    .md-button-text {
      font-family: $fontRoundedMedium;
      text-transform: uppercase;
    }

    .md-button.md-button-text {

      .contents {
        height: 13px;

        span {
          margin: 0 0.5rem 0 0;
        }
      }
    }

    .col {

      &.wide {
        @include flex-row(center, center);
      }

      &.helps {
        @include flex-row(center, center);
        width: 100px;
      }

      &.hide-assignment, .hide-assignment,
      &.lock-assignment, .lock-assignment,
      &.show-grades, .show-grades,
      &.peer-helpers, .peer-helpers,
      &.allow-pdf, .allow-pdf {
        @include flex-row(center, center, nowrap);
        width: 90px;
        min-width: 90px;
        cursor: pointer;

        .anchor {
          height: 0;
          width: 0;
          position: relative;

          .contextual-help {
            color: $ckHeaderGrey;
            margin-left: 0;
            margin-bottom: 8px;
            min-width: 24px;
            min-height: 24px;
            height: 24px;

            .ck-contextualhelp {
              margin-right: 1px;
            }

            &:hover {
              background-color: transparent;
            }
          }
        }

        ck-switch {
          label.switch {
            margin-right: 0;
          }
        }
      }

      &.roster-name, .roster-name {
        width: 15em;
        min-width: 15em;
        text-align: left;
      }

      .roster-circle {
        border-style: none;
      }

      &.roster-remove {
        margin-left: 0;
        padding-left: 0;
        width: 60px;
      }
    }
  }

  div.tip {
    @extend .section-header;
    color: $ckHeaderGrey;
    box-sizing: border-box;
    pointer-events: auto;
    text-align: center;
    margin-top: 48px;

    a {
      color: $ckGreen;
      text-transform: none;
    }
  }

  .new-ck-green-button {
    line-height: 2.8rem;
    font-family: $fontRoundedBold;
  }

  .export-grades {
    min-width: 16rem;
    width: 16rem;

    .export-grades-pro {
      &.anchor {
        position: absolute;
        margin-left: 13rem;
        top: 0.25rem;
      }

      .pro-tag {
        padding: .3rem;
      }
    }
  }
}

.choose-roster-dialog {

  .dialog-nav {
    height: 60px;
  }

  .dialog-header {
    text-align: center;
    height: 40px;
  }

  md-dialog-content {
    padding: 0;
    width: 440px;
    max-height: 30em;
    min-height: 4em;

    .choose-roster {

      md-list {
        border-top: 1px solid $dividerGrey;
        overflow: auto;
      }

      md-list-item {
        background-color: white;

        &.roster * {
          font-family: $fontRoundedBook;
          color: $ckHeaderGrey;
        }

        .md-list-item-inner {
          padding-left: 30px;
          padding-right: 30px;
        }
      }

      md-list-item:not(:last-child) {
        border-bottom: 1px solid $dividerGrey;
      }

      md-dialog-actions {
        min-height: 90px;
        padding: 0 11px 13px 0;
        border-top: 1px solid $dividerGrey;

        .md-button {
          margin: 0;
        }

        .md-button.disabled {
          color: $ckHeaderGrey;
          opacity: .38;
        }

        .anchor {
          position: relative;
          width: 0;
          height: 0;
          display: block;

          .pro-tag {
            @extend .pro-tag;
            position: relative;
            bottom: 35px;
            right: 18px;
            width: 16px;
            padding: 3px;
          }
        }
      }
    }

    .new-roster {

      .new-roster-form {
        padding: 0 60px 30px 60px;

        md-input-container {
          margin-top: 0;
        }
      }

      .roster-color {
        margin-top: 1em;
        margin-left: 0;
        margin-right: 0;

        div {
          text-transform: none;
          color: $ckHeaderGrey;
        }
      }
    }
  }
}
