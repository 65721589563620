@mixin display-flex() {
  display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
}

@mixin display-inline-flex() {
  display: -webkit-inline-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: inline-flex;
}

@mixin flex-flow($direction: row, $wrap: wrap){
  -webkit-box-flex-flow: $direction $wrap;
  -webkit-flex-flow: $direction $wrap;
  flex-flow: $direction $wrap;
}

@mixin justify-content($position: flex-start) {
  -webkit-box-justify-content: $position;
  -webkit-justify-content: $position;
  justify-content: $position;
}

@mixin align-content($position: flex-start) {
  -webkit-align-content: $position;
  align-content: $position;
}

@mixin align-items($position: center) {
  -webkit-align-items: $position;
  align-items: $position;
}

@mixin flex($val1: 0, $val2: 0, $val3: auto) {
  -webkit-box-flex: $val1 $val2 $val3;   /* OLD - iOS 6-, Safari 3.1-6 */
  -webkit-flex: $val1 $val2 $val3;       /* Safari 6.1+. iOS 7.1+, BB10 */
  -ms-flex: $val1 $val2 $val3;           /* IE 10 */
  flex: $val1 $val2 $val3;
}

@mixin flex-row($verticalAlign: flex-start, $justify: flex-start, $wrap: wrap) {
  @include display-flex();
  @include flex-flow(row, $wrap);
  @include justify-content($justify);
  @include align-items($verticalAlign);
}

@mixin flex-column($horizontalAlign: flex-start, $justify: flex-start, $wrap: wrap) {
  @include display-flex();
  @include flex-flow(column, $wrap);
  @include justify-content($justify);
  @include align-items($horizontalAlign);
}

@mixin content-width() {
  width: -moz-max-content;
  width: -webkit-max-content;
  width: max-content;
}

@mixin content-height() {
  height: -moz-max-content;
  height: -webkit-max-content;
  height: max-content;
}

@mixin content-size() {
  @include content-width();
  @include content-height();
}

@mixin hide-focused-placeholder() {
  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; }
  input:focus::-moz-placeholder { color:transparent; }
  input:focus:-ms-input-placeholder { color:transparent; }
}

@mixin color-placeholder($color: white) {
  input::-webkit-input-placeholder { color:$color; }
  input:-moz-placeholder { color:$color; }
  input::-moz-placeholder { color:$color; }
  input:-ms-input-placeholder { color:$color; }
}

@mixin transition($property: width, $duration: 1s, $timingFunction: ease-in-out) {
  -webkit-transition:$property $duration $timingFunction;
  -moz-transition:$property $duration $timingFunction;
  -o-transition:$property $duration $timingFunction;
  transition:$property $duration $timingFunction;
}

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin color-pulse($color1: green, $color2: white) {
  color: $color1;

  animation-name: color-pulse;
  animation-duration: 2s;
  animation-iteration-count: 2;

  @keyframes color-pulse {
    0% {
      color: $color1;
    }
    50% {
      color: $color2;
    }
    100% {
      color: $color1;
    }
  }
}
