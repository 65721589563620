.view-help-dialog {

  max-height: unset;

  .view-help-dialog-header {
    height: 48px;

    .bar-item {

      width: 48px;

      .ck-close {
        color: $ckGreen;
      }
    }
  }

  .view-help-dialog-title {
    padding: 8px 0 16px 0;
  }

  .view-help-dialog-image-container {

    .view-help-dialog-image {
      margin: 0 56px;

      img {
        border: 2px solid $backgroundGrey;
        width: 100%;
      }
    }

    .view-help-dialog-images {
      max-width: 700px;
      height: 450px;
      overflow: hidden;
      margin: 0 100px;
      border: 2px solid $backgroundGrey;

      img {
        position: relative;
        width: 700px;
        min-width: 700px;
        height: 450px;
        object-fit: scale-down;
      }
    }
  }



  .view-help-dialog-text {
    margin: 16px 102px 16px 102px;
    text-align: center;
    .text {
      font-family: $fontRoundedBook;
    }
    max-width: 700px;
    word-wrap: break-word;
  }

  .nav-buttons {

    .dot-container {
      height: 100%;
    }

    .dot-button {
      outline: none;
      cursor: pointer;

      .dot {
        width: 8px;
        height: 8px;
        background-color: $disabledGrey;
        border-radius: 4px;
        margin: 8px;

        &.active {
          background-color: $ckGreen;
        }
      }
    }

    .md-button.prev, .md-button.next {
      width: 32px;
      min-width: 32px;
      padding: 0;
      margin: 2px;
    }

    .prev.md-button[disabled] ,
    .next.md-button[disabled] {
      color: $disabledGrey;
    }
  }

  .view-help-dialog-footer {
    height: 48px;
  }

}
