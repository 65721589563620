.select-mdr-org-dialog {

  .dismiss-button {
    cursor: pointer;
    color: $ckGreen;
    padding: 2em;
    &:hover {
      background-color: $disabledGrey;
    }
  }

  .outer-dialog {
    box-sizing: border-box;
    max-width: 600px;
    border-radius: 12px;
    height: 642px;
    padding: 4rem 3.2rem 3.2rem 3.2rem;

    .orgs-list {
      max-width: 500px;
      padding: 2em 0em 1em 0em;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;

      .org-container {
        display: grid;
        grid-template-columns: 5fr 3fr;
        box-shadow: $boxShadowCard;
        border-radius: 7px;
        margin: .5em;
        padding: 1em;
      }

      .org-name {
        margin-bottom: 8px;
        line-height: 1.25em;
        font-size: 1em;
      }

      .org-location {
        line-height: 1.4em;
        margin-bottom: 0.9em;
        text-transform: uppercase;
        color: #b2aeae;
      }

      .verify-button {
        border: 2px solid;
        font-weight: bold;
      }

      .loading {
        font-size: x-large;
        display: flex;
        justify-content: center;
        margin: 28%;
      }
    }

    .outer-buttons {
      display: flex;
      justify-content: flex-end;
    }

    .more-button {
      font-size: x-small;
      border-radius: .7em;
      background-color: $backgroundGrey;
      color: $ckHeaderGrey;
    }

    .more-button:hover {
      background-color: #f0ecec;
    }

    .invisible {
      opacity: 0;
      cursor: default;
    }

    .unverified {
      background-color: $ckLightWarn;
      margin-top: 3em;
      border-radius: 7px;
      line-height: 1.8em;
      padding: 1em;
      display: flex;

      .icon {
        padding-right: .5em;
      }
    }

    .ck-dialog-header {
      font-family: $fontRoundedBold;
      font-weight: 400;
      line-height: 3.2rem;
      font-size: 2.4rem;
    }

    .dialog-body {
      .instructions {
        font-family: $fontRoundedBook;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.4rem;
      }

      .current-school {
        display: flex;
        text-transform: uppercase;
        line-height: 1.6rem;
        border-radius: 8px;
        background-color: #FFEBEB;
        padding: 1.5rem 1.4rem;
        margin-bottom: 3.2rem;
        font-size: 1rem;

        .ck-alert-outline {
          margin-right: 1.4rem;
          font-size: 1.4rem;
        }
      }

      .select-school-form {
        font-size: 1.4rem;


        md-input-container {

          input,
          .md-select-value {
            background-color: #FFFFFF;
            border-radius: 8px;
            height: 4.8rem;
            border-width: 2px;
            border-color: $ckHeaderGrey;
          }

          &.md-input-focused {
            border-color: $ckGreen
          }

          &.md-input-invalid {
            .md-input {
              border-color: $ckWarn;
            }
          }
        }

        md-select {
          &:not([disabled]):focus .md-select-value {
            border-color: $ckGreen;
          }

          &.ng-invalid.ng-touched .md-select-value {
            border-color: $ckWarn  !important;
          }
        }

        md-autocomplete md-input-container .label {
          top: -31px;
        }

        md-input-container .label {
          font-size: 1.4rem;
        }

        .location {
          margin-bottom: 3.2rem;
        }

        .asterisk {
          color: $ckWarn;
        }

        .school-city {
          width: 67%;
          margin-right: 3.2rem;
          margin-top: 1.5rem;
        }

        .school-state {
          padding: 0;
          margin: 0;
          width: 33%;

          .state-label {
            display: block;
            margin-bottom: 1.4rem;
          }
        }

        .school-name {
          margin-top: 1.7rem;

          .md-input:disabled {
            border-color: $disabledGrey;
          }
        }

        .submit-button {
          margin: 2em 0em 0em 31.5em;
        }

        .error-messages {
          margin-top: 1em;
          color: $ckWarn;

          .other-error {
            text-transform: uppercase;
            padding-top: 1em;
            font-size: 0.8rem;
          }
        }

        @media screen and (max-width: 420px) {
          .location {
            display: block;
          }
        }
      }
    }
  }
}

.organization-address {
  color: $lightText;
}