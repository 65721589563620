md-dialog.image-picker-dialog {
  min-width: unset;
  max-width: unset;
  max-height: unset;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
  }

  md-content {
    padding: 1rem 4rem;
    max-width: 100rem;

    .md-button.sticker {
      min-width: 7rem;
      max-width: 7rem;
      min-height: 7rem;
      max-height: 7rem;
      height: 7rem;
      padding: 1rem;

      .contents {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .actions {
    width: 100%;
    padding: 0 1rem 1rem 2.4rem;
    margin-top: 16px;

    .md-button {
      margin: 0 0 0 1.6rem;
    }
  }

  @media screen and (max-height: 610px) {
    md-content {
      max-width: 94rem;
    }
  }
}
