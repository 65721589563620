.signup-component {
  min-height: 100vh;
  width: 100vw;
  background: $ckGreen;
  background-image: url('../../../assets/images/scribs.png');
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 8px 20px 8px;
  overflow: auto;

  &.shared-work-view {
    min-height: auto;
    .major-header {
      margin-top: 2rem;
    }

    &.loading-view {
      min-height: 50vh;
    }
  }

  .major-header {
    img {
      width: 16rem;
    }

    margin-top: 6rem;
    margin-bottom: .25em;
  }

  .body {
    width: 60rem;
    height: 60rem;
    max-width: 100%;
    max-height: 100%;
    background-color: white;
    border-radius: 24px;
    box-shadow: $boxShadowCard;
    padding: 0 1.5rem;

    &.teacherPassword {
      height: 80rem;
    }
    &.studentPassword {
      height: 68rem;
    }

    .body-header {

      .t-header,
      .s-header {
        padding: 32px 0px 32px 0px;
        font-size: 2.2rem;
        font-weight: 800;
        display: flex;
        flex-direction: column;
      }

      .t-header span {
        text-align: center;
        width: 40rem;
      }

      .bar-item {
        i {
          font-size: 1.8rem;
        }
        &.teacher {
          bottom: 12px;
        }

        &:hover {
          background-color: transparent;
          i {
            color: $ckGreenProgress;
          }
        }
      }

    }

    .body-content {

      .info {
        font-size: 1.4rem;
        margin-bottom: 2.3em;
        font-family: $fontRoundedBook;

        span {
          padding-bottom: .5em;
        }
      }

      md-input-container {
        padding-left: 0;
        padding-right: 0;

        &.class-code {
          width: 34.5rem;
          margin: 0 0 24px 0;

          span {
            display: inline-block;
            height: 24px;
            margin-bottom: 8px;
            font-family: $fontRoundedBold;
            font-size: 1.4rem;
            color: $ckAltGrey;
          }

          input {
            border-radius: 8px;
            height: 48px;
            border: .5px solid $ckAltGrey;
          }
        }
      }

      .checkboxes {
        margin-top: 32px;

        .sign-up {
          width: 11.7rem;
          font-size: 1.7rem;
          box-shadow: 0px 12px 16px 0px #0000000A;
          box-shadow: 0px 4px 8px 0px #00000014;
          box-shadow: 0px 2px 4px 0px #0000001F;
        }
        .ck-checkbox-2023 {
          font-size: 1rem;
          letter-spacing: 2px;
          font-weight: 800;
          text-transform: uppercase;
        }

      }

      md-checkbox {
        margin: 0;
      }

      md-checkbox.md-checked .md-icon {
        background: $ckGreen;
      }

      .md-button {
        margin: 0;
        color: $white;
        border: none;
        padding: 1em;
        height: 4.8rem;
        width: 34.4rem;

        &.google-sign-in {
          text-wrap: initial;
          background-color: $googleRed;

          .img-container {
            margin-right: 10px;
          }

          img {
            width: 17px;
          }
        }

        &.clever-sign-in {
          text-wrap: initial;
          background-color: $cleverBlue;

          .img-container {
            margin-right: 12px;
          }

          img {
            width: 17px;
            padding-right: 1px;
          }

          margin-top: 16px;
        }

        &.google-sign-in,
        &.clever-sign-in {
          .img-container {
            background-color: $white;
            height: 1.8em;
            min-width: 1.8em;
            border-radius: 50%;
          }
        }

        &.google-sign-in:disabled img,
        &.clever-sign-in:disabled img {
          opacity: 0.5;
        }

        &.google-sign-in:disabled,
        &.clever-sign-in:disabled,
        &.email-or-username-sign-in:disabled {
          opacity: .5;
        }

        &.email-or-username-sign-in {
          text-wrap: initial;
          margin-top: 16px;
          background-color: $ckGreenProgress;

          .ck-email-2023 {
            font-size: 2.4rem;
            margin-right: 0.5em;
          }
        }
        .label-container {
          font-size: 1.07rem;
          letter-spacing: 2px;
          font-family: $fontRoundedBold;
        }
      }

      p.error-message {
        margin: 0 0 8px 0;
        color: $ckWarn;
        text-align: center;
      }

      .separator {
        margin: 32px 0 0px 0;
        height: 1px;
        width: 90%;
        background-color: $dividerGrey;
      }

      .agreement {
        color: $ckAltGrey;
        text-align: center;
        margin-top: 32px;
        height: 48px;
        justify-content: center;

        a {
          text-transform: none;
          cursor: pointer;
        }
      }

      .auth-password {
        padding: 32px 32px 0px 32px;

        md-input-container {
          span {
            display: inline-block;
            height: 24px;
            margin-bottom: 8px;
            font-family: $fontRoundedBold;
            font-size: 1.4rem;
            color: $ckAltGrey;
          }

          input {
            border-radius: 8px;
            height: 48px;
            border: .5px solid $ckAltGrey;

            &::placeholder {
              color: $lightText;
            }
          }
        }

        .asterisk {
          color: $ckWarn;
          padding-left: 3px;
        }

        .half-size {
          width: 260px;
        }

        .password-container, .name-container {
          column-gap: 16px;
        }

        .name-container {
          md-input-container {
            margin: 0;
          }
        }

        .email-or-username {
          margin: 0;
        }
      }
    }

    .body-footer {
      height: 16px;
      padding-top: 16px;
      align-items: start;
      span {
        font-size: 3.2rem;
        letter-spacing: -7px;
        color: $backgroundGrey;
      }
      .active {
        color: $ckGreen;
        z-index: 1;
      }
    }
  }

  .loading {
    margin-top: 8em;
    height: 100px;
    width: 100px;
    background-color: white;
    border-radius: 10px;
  }

  .back-to-login {
    border: none;
    height: 3.7em;
    text-transform: capitalize;
    width: 30rem;
    font-weight: 800;
    margin-top: 4em;
    margin-left: 10em;
    padding: .14em 1em;
    background-color: $borderGrey;

    i {
      padding: 0 1rem 0 0;
      font-size: 1.5em;
    }

    .flip-horizontal {
      transform: rotate(180deg);
    }
  }

  .login-button{
    display: flex;
    flex-flow: row;
    align-items: center;
    text-wrap: initial;
  }

}
