.student-account-assignment-feedback {

  height: 100vh;

  .assignment-question-header {
    background-color: $ckStudentHelper;
    color: white;

    .md-button.bar-item.back {
      color: white;
    }

    help-inbox {
      margin-left: 1em;
    }

    .classkick-header {
      color: white;
    }
  }

  .error {
    text-align: center;
    font-size: 1.5em;
  }
}
