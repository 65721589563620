.assignments-list {
  min-height: calc(100vh);
  max-height: calc(100vh);

  .assignment-header {
    font-size: 1.3em;
    padding-left: 15px;

    .assignments-list-title {
      @extend .classkick-header;
    }
  }

  .assignment-limit-anchor {
    position: relative;
    left: 0;
    height: 0;
    width: 100%;

    .assignment-limit {
      left: 0;
      top: 1px;
      width: 100%;
      color: $lightText;
      font-size: $bodySub;
      text-transform: none;
    }
  }

  .breadcrumbs {

    min-height: $teacherBreadcrumb;
    max-height: $teacherBreadcrumb;

    .md-button {
      text-transform: inherit;
    }

    .breadcrumb {
      line-height: 1;
      margin-left: 16px;
      margin-right: 16px;
      vertical-align: middle;
    }
  }

  .list-header {
    .md-button {
      width: 100%;

      &.modified {
        width: 140px;
      }
    }
  }

  md-list {
    min-height: $teacherListHeight;
    max-height: $teacherListHeight;
    overflow: auto;
  }

  md-list.with-breadcrumbs {
    min-height: $teacherListHeightWithBreadcrumb;
    max-height: $teacherListHeightWithBreadcrumb;
  }

  md-list.with-banner {
    min-height: $teacherListHeightWithBanner;
    max-height: $teacherListHeightWithBanner;
  }


  .newly-copied-list-item {
    background-color: $highlightPrimary;
  }

  .error,
  .loading {
    text-align: center;

    .loading-text,
    .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }

  md-list-item .col.name i {
    padding-right: 16px;
  }

  .list-header,
  md-list-item {

    &.disabled {
      background-color: $dividerGrey;

      .col {
        color: $slate;
      }
    }

    &.drag-over button.md-no-style {
      border: 2px solid $ckHeaderGrey;
      box-sizing: border-box;
    }

    &.dragging {

      .md-ripple-container {
        display: none;
      }

      .md-list-item-inner {
        max-width: 200px;

        .tools,
        .modified,
        .helps {
          display: none;
        }
      }
    }


    .col {

      &.name {
        .truncate {
          margin-top: 2px;
          display: block;
        }
      }

      &.modified {
        width: 140px;
        padding-left: 15px;
      }

      &.checks,
      .checks,
      &.helps,
      .helps {

        @include flex-row(center, center, nowrap);

        min-width: 80px;
        width: 80px;

        span.check {
          color: $ckGreen;
        }

        span.help {
          color: $ckYellow;
        }

        span.request-carrot {
          @include flex-row(center, center, nowrap);
          margin: 0 0.2em;
          min-width: 1em;
          max-width: 1em;
          min-height: 1em;
          max-height: 1em;
        }

        .circle-green,
        .circle-yellow {
          border-radius: 50%;
          width: 35px;
          height: 35px;

          i.ck-hand {
            color: white;
          }
        }

        .circle-green {
          background-color: $ckYellow;
        }

        .circle-yellow {
          background-color: $ckGreen;
        }

      }

      .tools,
      &.tools {
        width: 170px;

        &.folder-options {
          justify-content: flex-end;
        }
      }

      &.tools:not([disabled]):hover {
        background-color: transparent;
        cursor: default;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
  
  .load-pdf {
    position: fixed; /* Ensure it overlays the page */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust positioning to account for its size */
    background-color: white; /* White background */
    color: black; /* Text color for contrast with white background */
    z-index: 500; /* Ensure it is on top of other elements */
    width: 15vw; /* Set width to 20% of the viewport width */
    height: 15vw; /* Same as width for a perfect square */
    border-radius: 8px; /* Optional: Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for aesthetics */
    display: flex; /* Use flexbox to align content inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    text-align: center;
  }
}