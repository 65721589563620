.student-account-assignment-overview {
  height: 100vh;

  .assignment-question-header {

    box-shadow: $boxShadow;
    z-index: 1;

    help-inbox {
      margin-left: 1em;
    }

    .feedback-inbox-button {
      @extend .bar-item;

      width: 75px;
    }

  }

  .content {
    overflow: auto;
    background-color: $backgroundGrey;
    padding-top: 8px;

    assignment-sheet {
      /* Aspect ratio is 4:3. */
      height: 33vw;
      width: 44vw;
      background: white;

      /* Our canvas is 2:1, so height of the canvas should be twice that of its short dimension */
      svg {
        height: 88vw;
      }
    }

    .work {
      height: 33vw;
      width: 44vw;
      background: white;
      overflow: hidden;
      margin: .5em;
      box-shadow: $boxShadowCard;
      cursor: pointer;
    }

    .anchor {
      z-index: 10;
      position: relative;
      width: 0;
      height: 0;

      .question-overlay {
        pointer-events: none;
        height: 33vw;
        width: 44vw;

        .question-overlay-bottom {

          .question-number {
            background-color: white;
            height: 36px;
            width: 36px;
          }

          .question-score {
            position: relative;
            width: 128px;
            height: 32px;
            margin: 3px;
            color: $ckHeaderGrey;

            grade-input {
              height: 2.6rem;
              width: 120px;
              margin: auto;
            }
          }
        }
      }
    }
  }

  .error {
    text-align: center;
    font-size: 1.5em;
  }
}
