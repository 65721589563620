@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 * Colors must be hsl so we can use the opacity modifiers.
 */

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --subtle: 215.4 20% 60%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 155 68.4% 45.9%; /* CK Green 300 #25c582 */
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 1rem;
  }
}

@layer base {
  .react-container {
    zoom: 1.6; /* Legacy Refactor: scaling up due to html font-size of 10px in classkick.scss */
    @apply tw-border-border;
  }
}
