.student-info-menu {
  font-size: $bodyCopy;
  font-family: $fontRoundedBook;
  color: $ckHeaderGrey;
  box-shadow: $boxShadowCard;
  background-color: white;
  min-width: 250px;

  .student-info-menu-header {
    border-bottom: 1px solid $dividerGrey;
    font-family: $fontRoundedMedium;
    color: $ckHeaderGrey;
    padding: 0 0 0 16px;
    height: 48px;
  }

  .student-info-menu-body {
    padding: 16px 16px 0 16px;

    .info-item {
      margin-bottom: 16px;
      line-height: 1;

      .account-help {
        position: relative;
        left: 10px;
        outline: none;
        cursor: pointer;
      }
    }
    .link {
      color: $ckGreen;
      cursor: pointer;
    }
  }

}


