.canvas-context-menu {
  @extend .md-open-menu-container;
  opacity: 1;
  left: auto;
  top: auto;

  md-menu-item {
    pointer-events: auto;
  }

  md-menu-content > * {
    opacity: 1;

    md-button {
      &:hover {
        background-color: $lightHover;
      }
    }
  }

  font-size: $bodyCopy;
}
