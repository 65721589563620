md-dialog.pro-info-dialog {

  max-height: 95%;
  min-width: unset;
  max-width: unset;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
    margin-bottom: 24px;
  }

  .features-list {
    padding: 0 60px 60px 60px;

    .feature {
      margin: 0 0 16px 0;
      min-width: 90%;

      img {
        width: 58px;
        margin-right: 10px;
      }

      .feature-description {
        .feature-name {
          margin: 0;
        }
        p {
          color: $ckHeaderGrey;
          margin: 0;
          font-family: $fontRoundedBook;
        }
        margin-left: 1em;
      }

    }

    .actions {

      padding: 0 48px;

      .md-button {
        width: 240px;
        margin: 24px 0 0 0;
      }
    }

  }

  @media screen and (max-height: 600px) {
    .features-list {

      .feature {
        margin-bottom: 16px;

        img {
          width: 50px;
        }
      }

      .feature:last-child {
        margin-bottom: 8px;
      }
    }
  }

  .loading {

    min-width: unset;

    .ck-dialog-body {
      overflow: hidden;
      padding: 0;

      .spinner {
        margin: 20px;
      }
    }
  }
}

