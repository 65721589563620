.order-detail {
  height: 100vh;
  overflow: hidden;

  .view-quote {
    background-color: $backgroundGrey;
    overflow: auto;

    .leaderboard {
      max-width: 352px;
      padding: 53px 16px 16px 16px;
      box-shadow: inset 0.5px 0px 0px #B8BABC;

      .info {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 16px;
        padding: 0px 12px 14px 12px;
        box-shadow: inset 0px -0.5px 0px #B8BABC;
        font-weight: 800;
      }

      md-list {
        box-shadow: inset 0px -0.5px 0px #B8BABC;
        padding-bottom: 12px;

        md-list-item {
          font: $fontRoundedMedium;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }
    }

    .header {
      height: 72px;
      padding: 20px 16px;
      background-color: white;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 8px 12px rgba(0, 0, 0, 0.08), 0px 12px 16px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04);

      .left {
        height: 100%;

        .logo {
          max-height: 100%;
          display: flex;
          align-items: center;

          .ck-classkick-hand {
            color: $ckGreenProgress;
            font-size: 3.2rem;
            margin-right: 10px;
          }

          span {
            color: $ckAltGrey;
            font-size: 2.4rem;
            line-height: 3.2rem;
          }

          &:hover {
            cursor: pointer;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .right {
        .md-open {
          .bar-item {
            color:$ckGreenProgress;
          }
        }

        .bar-item {
          color: $ckAltGrey;
          min-width: max-content;

          &:hover, &:focus, &:active {
            color:$ckGreenProgress;
            background-color: transparent;
          }
        }

        .contents {
          .ck-shopping-cart {
            font-size: 1.9rem;
            margin-right: 1.3rem;
          }

          .purchase-copy {
            font-size: 1rem;
            line-height: 1.6rem;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-weight: bold;
          }
        }

        .shoutout-card {
          color: $ckAltGrey;
          padding: 12px;
          flex-direction: row;
          border-radius: 8px;
          box-shadow: none;
          cursor: pointer;
          align-items: center;

          .ck-fire {
            margin-right: 9px;
            font-size: 1.9rem;
          }

          .ck-contextualhelp {
            margin-left: 2px;

            &:hover {
              color: $ckGreenProgress;
            }
          }
        }

        .email-admin {
          @extend .referral-button-assignment;
          background-color: $white;
          margin-left: 15px;
          padding: 12px;

          .md-ripple-container {
            border-radius: 8px;
            background-color: transparent;
          }

          .ck-paper-airplane{
            margin-right: 13px;
            font-size: 2.2rem;
          }

          &:active {
            background-color: $ckAltGrey;
          }


          @media screen and (min-width: 869px) {
            &.mobile-view{
              display: none;
            }
          }
          @media screen and (max-width: 868px) {
            &.desktop-view{
              display: none;
            }

            &.mobile-view {
              display: flex;
              justify-content: center;
              min-width: 5rem;

              .ck-paper-airplane{
                margin-right: 4px;
              }
            }
          }
        }

        .purchase {
          @extend .referral-button-assignment;
          background-color: $azureBlue;
          color: $white;
          margin-left: 15px;
          padding: 12px;

          .md-ripple-container {
            border-radius: 8px;
            background-color: transparent;
          }

          .ck-paper-airplane{
            margin-right: 13px;
            font-size: 2.2rem;
          }

          &:hover, &:focus {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), $azureBlue;
          }

          &:active {
            background-color: $ckAltGrey;
          }


          @media screen and (min-width: 869px) {
            &.mobile-view{
              display: none;
            }
          }
          @media screen and (max-width: 868px) {
            &.desktop-view{
              display: none;
            }

            &.mobile-view {
              display: flex;
              justify-content: center;
              min-width: 5rem;

              .ck-paper-airplane{
                margin-right: 4px;
              }
            }
          }
        }

        .reserve-price {
          @extend .referral-button-assignment;
          background-color: $veryLightGrey;
          color: $boxShadow;
          margin-left: 15px;
          padding: 12px;

          .md-ripple-container {
            border-radius: 8px;
            background-color: transparent;
          }

          .ck-time-clock{
            margin-right: 13px;
            font-size: 2.2rem;
          }

          &:hover, &:focus {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), $veryLightGrey;
          }

          &:active {
            background-color: $ckAltGrey;
          }
        }
      }

      .teacher-resources,
      .order-pro,
      .order-login {
        margin-left: 30px;
        cursor: pointer;
        outline: none;
      }

      .ck-menu {
        color: $ckHeaderGrey;
      }
    }

    .action-bar {
      background-color: white;
      box-shadow: $boxShadow;
      height: $teacherHeaderHeight;
      z-index: 10;
      padding: 0 80px;

      .action-container {
        height: 100%;

        .md-button.credit-card,
        .md-button.purchase-order {
          @extend .ck-primary;
        }

        .md-button.credit-card {
          margin-left: 0;
        }

        .md-button.ck-secondary {
          color: $ckGreen;
          border-color: $ckGreen;
        }

        .md-button.ck-secondary:last-child {
          margin-right: 0;
        }
      }
    }

    .view-quote-body {
      padding: 60px 60px 0 60px;

      .custom-quote-info {
        width: 918px;
        padding: 25px 100px;

        @media screen and (max-width: 500px) {
          padding: 25px;
        }

        @media screen and (min-width: 525px) and (max-width: 800px) {
          padding: 25px 50px;
        }

        @media screen and (min-width: 801px) and (max-width: 1100px) {
          padding: 25px 80px;
        }


        h1 {
          margin: 0;
          font-size: 2.4rem;
        }

        h2 {
          font-size: 1.8rem;
        }

        p {
          color: $ckHeaderGrey;
        }

        ul {
          margin: 0;
          li {
            color: $ckHeaderGrey;
          }
          li + li {
            margin: 10px 0 0;
          }
        }

        .custom-quote-info-content {
          padding: 0;
        }
      }

      .expiration-info {
        margin: 40px 0 30px 0;
        width: 918px;
        padding: 8px 12px;
        text-transform: capitalize;

        .school-actions {
          width: inherit;
          margin-bottom: 16px;

          .school-name{
            color: $ckAltGrey;
            font-size: 2.4rem;
            line-height: 3.2rem;
            font-family: $fontRoundedBold;
            text-transform: capitalize;
          }

          .quote-actions {
            display: flex;
            justify-content: flex-end;

            .md-button {
              margin: 0px;
              padding: 0px;
              min-width: auto;
              background-color: transparent;
              color: $slate;
              padding: 0 14px ;

              &:hover {
                color: $ckGreenProgress;
                background-color: transparent;
              }

              .ck-forward-arrow-2023 {
                font-size: 1.6rem
              }
              .ck-print {
                font-size: 2.1rem;
              }
            }
          }
        }

        .countdown {
          color: $ckWarn;
          font-size: 4.0rem;
          line-height: 4.8rem;
          font-family: $fontRoundedBold;
          text-transform: none;
        }

        .expiration-date {
          background-color: $veryLightGrey;
          color: $ckWarn;
          padding: 11px 16px;
          margin-left: 12px;
          border-radius: 8px;
          font-size: 1rem;
          line-height: 1.6rem;
          letter-spacing: 2px;
          text-transform: uppercase;
          font-weight: bold;
        }
      }

      .canvas-container {
        margin-bottom: 60px;
        box-shadow: $boxShadowCard;

        .anchor {
          background-color: red;
          height: 0;
          width: 100%;

          .order-overlay {
            position: relative;
            height: 100px;
            width: 100px;
          }
        }
      }
    }

    .w9-container {
      margin-bottom: 60px;
      box-shadow: $boxShadowCard;
    }
  }

  .renewal-quote{

    .renewal-body-container {
      padding: 0 30px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $backgroundGreen;
      font-size: 2.5rem;
    }

    .renewal-body{
      word-break: break-word;
      a {
        text-transform: none;
      }
    }

    .header {
      width: 100%;
      padding-left: 0;

      .logo {
        margin-left: 24px;
        height: 30px;
      }

      .logo:hover {
        cursor: pointer;
      }

      .logo:focus {
        outline: none;
      }
    }
  }

  .view-invoiced {
    background: $ckGreen;
    background-image: url('../../../assets/images/scribs.png');
    background-repeat: no-repeat;
    background-position: center;

    .body {
      margin: 60px 0;
      width: 630px;
      background-color: white;
      border-radius: 10px;

      .body-header {
        height: 5.6em;
        margin-top: 20px;
        text-align: center;
      }

      .body-content {
        padding-bottom: 3rem;

        i.ck-check {
          margin-top: 45px;
        }

        .instruction {
          font-family: $fontRoundedBook;
          margin: 60px 0 0 0;
        }

        .instruction.started {
          margin-top: 30px;
        }

        a {
          margin-top: 30px;
        }
      }

      .order-details {
        background-color: $listHeaderBackground;
        width: 60%;
        margin: 0 auto;
        padding: 20px;
        box-sizing: border-box;

        .order-line-items-left {
          width: 75%;
        }

        .order-line-items-right {
          width: 15%;
        }

        .order-type {
          text-transform: capitalize;
        }

        .order-status {
          text-transform: uppercase;
        }

        .mail-check {
          margin-top: 30px;
        }
      }
    }
  }

  .view-canceled {
    background: $ckGreen;
    background-image: url('../../../assets/images/scribs.png');
    background-repeat: no-repeat;
    background-position: center;

    .body {
      margin: 60px 0;
      width: 630px;
      background-color: white;
      border-radius: 10px;

      .body-header {
        height: 5.6em;
        text-align: center;
        margin: 20px 60px 0 60px;
      }

      .body-content {
        padding-bottom: 6rem;

        i.ck-alert {
          margin-top: 45px;
        }

        .instruction {
          font-family: $fontRoundedBook;
          margin: 60px 0 0 0;

          a {
            text-transform: none;
          }
        }
      }
    }
  }

  .error, .loading {
    text-align: center;

    .loading-text, .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }
}

.purchase-options {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12)) drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08)) drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.04)) drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.04));
  border-radius: 12px;
  width: 320px;

  &:hover {
    background-color: $backgroundGrey;
  }

  .menu-item-content {
    align-items: center;
    color: $ckAltGrey;
    font-size: 1rem;
    line-height: 1.6rem !important;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: bold;

    i {
      margin-right: 14px;

      &.ck-shopping-cart {
        font-size: 1.9rem;
      }

      &.ck-credit-card {
        font-size: 1.6rem;
      }
      &.ck-time-clock {
        font-size: 1.6rem;
      }
    }

    .span {
      margin: 16px;
    }
  }
}


.md-tooltip {
  .shoutout-metrics {
    font-size: 1.4rem;
    white-space: normal;
    line-height: 20px;
    max-width: 40rem;

    .title {
      font-weight: bold
    }

    .body {
      font-size: 1.2rem;

      .subtext {
        margin-bottom: 5px;
      }

      span {
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
}
