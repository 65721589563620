.payment {
  height: 100vh;

  .header {
    border-bottom: none;
  }

  .headers {
    height: 100px;
  }

  .body {
    overflow: auto;
  }

  form {
    width: 410px;

    .error-messages {
      position: relative;
    }

    .plan {
      margin: 4px 0 4px 0;

      label {
        position: relative;
        bottom: 5px;
        font-size: 8.25pt;
        font-family: $fontRoundedMedium;
      }
    }

    .ck-primary {
      margin: 12px 0 0 0;
      min-height: 3.2rem;
    }

  }

  .card-container {
    height: 80px;

    md-input-container.card {
      pointer-events: none;
      margin-top: 8px;
      padding-left: 0;
      padding-right: 0;

      input {
        position: absolute;
      }

      .error-messages {
        position: absolute;
        top: 47px;
      }
    }

    .card-element {
      position: relative;
      top: 1px;
      left: 4px;
      margin: 0 8px;
      transition: opacity 0.2s;
    }

    .ck-card-empty:not(.ck-card-focused) {
      opacity: 0;
    }
  }

  .alternative, .error, .loading {
    text-align: center;
    margin-bottom: 150px;

    .loading-text, .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }

}
