.login {
  min-height: 100vh;
  width: 100vw;
  background: $ckGreen;
  background-image: url('../../../assets/images/scribs.png');
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 8px 20px 8px;

  .major-header {
    img {
      width: 16rem;
    }
    margin-top: 6rem;
    margin-bottom: .25em;
  }

  .body {
    background-color: $ckBackgroundGrey;
    box-shadow: $boxShadowCard;
    border-radius: 10px;
    width: 350px;
    max-width: 100%;

    .body-header {
      height: 5.6em;
    }

    .body-content {
      padding: 0 1.6rem 2rem 1.6rem;

      md-input-container.class-code {
        margin: 0 8px 0 8px;
        padding: 0;

        input {
          text-transform: uppercase;
        }
      }

      .what-is-classcode {
        margin-top: 20px;
      }

      md-input-container.name {
        margin: 12px 8px 24px 8px;
        padding: 0;
      }

      .md-button.ck-primary {
        margin-top: 0;
        margin-bottom: 0;
      }

      .separator {
        margin: 20px 8px;
        height: 2px;
        background-color: $dividerGrey;
      }

      .login-signup {

        .message {
          font-family: $fontRoundedBook;
          margin-bottom: 8px;
        }

        .login-button, .signup-button {
          width: 100%;
        }
      }

      .hide-student-work-message {
        font-family: $fontRoundedBook;
        text-align: center;
        padding: 16px 16px 4px 16px;
      }
    }
  }

  .loading {
    margin-top: 8em;
    height: 100px;
    width: 100px;
    background-color: white;
    border-radius: 10px;
  }

}

#what-is-a-class-code{
  color: $ckHeaderGrey;
  text-align: center;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  md-dialog-content {
    padding: 0 35px;

    .primary-message {
      @extend .classkick-header;
      margin-bottom: 24px;
    }

    .secondary-message {
      @extend .classkick-header;
      font-family: $fontRoundedBook;
      margin-bottom: 24px;
    }
  }

  md-dialog-actions {
    padding: 0 2.4rem 2.4rem 2.4rem;
    margin-top: 36px;

    .md-button {
      margin: 0 0 0 1.6rem;
    }
  }
}
