.chat-message {

  .user-info {
    margin: 14px 16px 3px 16px;
    @extend .chat-message-text;

    .name {
      color: $ckHeaderGrey;
      margin-right: 12px;
    }
    .time {
      color: $lightText;
    }
  }

  .chat-text {
    border-radius: 2px;
    margin: 4px 16px;
    padding: 6px 6px;

    i {
      margin: 0 8px 0 2px;
    }

    .text, i, a {
      @extend .chat-message-text;
      text-transform: none;
      overflow-wrap: break-word;
    }
  }
  .chat-text.help {
    background-color: $ckYellow;
    color: white;
  }
  .chat-text.check {
    background-color: $ckGreen;
    color: white;
  }
  .chat-text.hand_down {
    background-color: $backgroundGrey;

    .text, i {
      color: $lightText;
    }
  }
  .chat-text.message {
    background-color: $backgroundGrey;
    color: $ckHeaderGrey;
  }
  .chat-text.blend-top {
    border-radius: 0 0 2px 2px;
    margin: 0 16px 4px 16px;
  }
  .chat-text.blend-bottom {
    border-radius: 2px 2px 0 0;
    margin: 4px 16px 0 16px;
  }
  .chat-text.blend-both {
    border-radius: 0;
    margin: 0 16px;
  }
}

.chat-message.previous-from-other {
  border-top: 1px solid $dividerGrey;
  margin-top: 8px;
}
