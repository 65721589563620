.grade-export-dialog {
  box-sizing: border-box;
  color: $darkGrey;
  box-shadow:
          0px 24px 32px 0px rgba(0, 0, 0, 0.12),
          0px 8px 12px 0px rgba(0, 0, 0, 0.06),
          0px 4px 8px 0px rgba(0, 0, 0, 0.08),
          0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 12px;


  i.ck.ck-close {
    font-size: 1.4rem;
  }

  .ck-dialog-title {
    font-family: $fontRoundedBold;
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.02em;
    text-align: center;
    margin: 1.2rem;
  }

  .loading {
    padding: 3.2rem;
    width: 60rem;
    max-width: 100%;
    height: 31.6rem;
    max-height: 100%;
  }

  .lds-ring {
    color: $newCkGreen;
  }

  .lds-ring,
  .lds-ring div {
    box-sizing: border-box;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 15.4rem;
    height: 15.4rem;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 15.4rem;
    height: 15.4rem;
    margin: 8px;
    border: 15px solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
  }

  .lds-ring {
    .gray-section {
      border-color: $backgroundGrey;
    }
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .bar-item {
    width: auto;
    position: absolute;
    right: 3.2rem;
    top: 3.8rem;
    height: 3rem;
    color: $darkGrey;

    &.header-button {
      position: initial;
      right: auto;
      top: auto;
    }
  }

  .ck-loading {
    font-size: 15.4rem;
    color: $newCkGreenHover;
  }

  .loading {
    .ck-dialog-body {
      margin-top: 2.9rem;
    }
  }

  .request-access {
    width: 60rem;
  }

  .grade-export-view {
    padding: 3.2rem;
    max-width: 100%;
    max-height: 100%;

    .ck-dialog-body {
      margin-top: 3.2rem;
    }

    .title {
      display: flex;
      align-items: center;
      color: $darkGrey;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      text-align: left;
      margin-bottom: .8rem;

      i {
        font-size: 2.4rem;
        margin-right: 1.2rem;
      }
    }

    p {
      margin: 0;
      font-family: $fontRoundedBook;
      font-size: 2.0rem;
      padding: 1.2rem 1.4rem;

      &.reverify-email {
        margin-bottom: 0.8rem;
      }

      &.class-selection {
        font-size: 1.4rem;
      }
    }


    .access-requested-copy {
      margin-bottom: 3.4rem;
    }
  }

   md-input-container {
    margin: 1.6rem 0 3.2rem 0;
    padding-left: 0;
    padding-right: 0;
    color: $newSlate;

    &.lms-email {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    &.md-input-focused {
      label {
        color: $newSlate;
        border-radius: 8px;
      }

      .md-input {
        border-radius: 8px;
        border: 2px solid $newCkGreen;
      }
    }

    label {
      font-family: $fontRoundedBook;
      position: relative;
      font-size: 1.6rem;
    }

    .md-input{
      margin-top: 0.8rem;
      border: .5px solid $newSlate;
      border-radius: 8px;
      padding: 12px;
      font-size: 1.4rem;
      height: 4.8rem;

      &.ng-invalid {
        border: 2px solid $ckWarn;
        padding: 1.2rem;
        border-radius: 8px;
      }
    }
  }

  .new-ck-green-button, .new-ck-white-button {
    height: 5rem;
    font-size: 1.5rem;
  }

  .warning-section {
    padding: 1.2rem;
    margin-bottom: 5.1rem;
    font-family: $fontRoundedBold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0.02em;
    text-align: left;
    background-color: $backgroundGrey;
    border-radius: 8px;
    box-shadow:
            0px 2px 8px 0px #0000000A,
            0px 2px 4px 0px #0000001F;
  }

  .email-checkbox-confirmation {
    font-family: $fontRoundedBook;
    font-size: 1.6rem;
    margin-bottom: 3.2rem;

    .md-label {
      height: auto;
      margin-top: 0;
      margin-left: 3.5rem;
    }

    .md-icon {
      transform: scale(1.2);
      border: 0.5px solid $newSlate;
      border-radius: 8px;
    }

    .md-container {
      margin-left: 0.5rem;
    }

    &.md-checked .md-icon {
      background-color: $newCkGreen;
      border: 1px solid $newCkGreen;
      border-radius: 8px;

      &:after {
        margin-left: 2px;
        margin-top: 1px;
        transform: rotate(35deg);
        border-radius: 1px;
        border-width: 2.5px;
        left: 3.5px;
      }
    }
  }

  .class-section, .students-section {
    width: 60rem;
  }

   .md-button {
     & .md-ripple-container {
       border-radius: 12px;
     }
     &.lms-class, &.lms-students {
       height: auto;
       margin-left: 0;
       margin-top: 0;
       margin-right: 0;
       width: inherit;
       text-transform: initial;
       font-size: 1.6rem;
       line-height: 2.4rem;
       color: $newSlate;
       padding: 1.2rem;
       box-shadow: 0px 2px 8px 0px #0000000A, 0px 2px 4px 0px #0000001F;
       border-radius: 12px;
     }
   }

  .class-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .md-button[disabled] {
    background-color: $disabledGrey;
  }

  .lms-class {
    margin-bottom: 1.6rem;
  }

  .lms-students {
    text-align: left;
    margin-bottom: 3.2rem;
  }

  .arrow-right {
    margin-left: .6rem;
    width: 0;
    height: 0;
    border-top: .8rem solid transparent;
    border-bottom: .8rem solid transparent;
    border-left: .8rem solid $darkGrey;
  }

  .action {
    min-width: 8.9rem;
  }

  .circle-indicator {
    height: .8rem;
    width: .8rem;
    border-radius: 50%;
    background-color: $newCkYellow;
    margin-right: .7rem;

    &.green {
      background-color: $newCkGreen;
    }
  }

  .match-class, .student-list {
    height: auto;
    margin-left: 0;
    margin-top: 0;
    margin-right: 0;
    max-width: 100%;
    width: 50rem;
    text-transform: initial;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $newSlate;
    padding: 1.2rem;
    box-shadow: 0px 12px 16px 0px #00000014, 0px 8px 12px 0px #0000000F, 0px 4px 8px 0px #00000014, 0px 2px 4px 0px #0000001F;
    border-radius: 12px;
    align-self: center;
    margin-bottom: 1.9rem;

    md-checkbox.md-checked .md-icon {
      background: $ckGreen;
    }

    md-list {
      background-color: white;

      md-list-item.list-item {
        box-shadow: none;
        border-bottom: 0px;
      }

      .col {
        margin: 0;
      }
    }

    md-input-container {
      height: auto;
      width: inherit;
      text-transform: initial;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $darkGrey;
      border-radius: 12px;
      margin: 0.25rem 0 0.25rem 0
    };

    md-select-value {
      color: $darkGrey;
      border: none;

      .md-text {
        display: flex;
      }
    }

    .md-select-value.md-select-placeholder {
      height: 4rem;
      border: none;
    }

    .md-select-icon {
      transform: rotate(270deg);
    }

    .lms-class {
      color: $darkGrey;
      box-shadow: none;
    }

    .ck-board {
      font-size: 2.4rem;
      margin-right: 1.2rem;
      color: $darkGrey;
    }

    .action {
      min-width: 1rem;
    }

    .md-ripple-container {
      display: none;
    }

    .md-select-value .md-select-icon:after {
      display: block;
      content: '\25BC';
      position: relative;
      speak: none;
      font-size: 1.9rem;
      color: $darkGrey;
      transform: scaleY(0.5) scaleX(1);
    }

    md-input-container md-select:not([disabled]):focus .md-select-value {
      border: none;
    }

    .indicator-circle {
      margin-right: 1.5rem;
    }
  }

  .student-list {
    padding: 3.2rem;
    width: 90rem;
    max-width: 100%;
    max-height: 100%;
  }

  .match-student {
    .md-select.green {
      background-color: white;
    }

    .md-select.yellow {
      background-color: $ckYellow;
    }
  }
}

md-select-menu md-content md-option[selected] {
  &.lms-option {
    color: $newCkGreen;
  }
}

