.quote-iframe-dialog {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: $ckAltGrey;
  width: 100%;
  height: 100%;
  align-items: center;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.12), 0px 16px 24px rgba(0, 0, 0, 0.08), 0px 24px 32px rgba(0, 0, 0, 0.08), 0px 32px 40px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  padding: 32px;
  max-height: 90% !important;

  .close {
    cursor: pointer;
    height: fit-content;
    display: flex;
    align-self: end;

    &:hover {
      i {
        color: $ckGreenProgress;
      }
    }

    i {
      color: $ckHeaderGrey;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .dialog-header {
    font-family: $fontRoundedBold;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: .8rem 0 4rem 0;
    height: inherit;
    width: inherit;

    iframe {
      border: $ckAltGrey 2px solid;
      height: inherit;
      width: inherit;
    }
  }
}
