.signup-choice {
  min-height: 100vh;
  width: 100vw;
  background: $ckGreen;
  background-image: url('../../../assets/images/scribs.png');
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 8px 8px 8px;
  overflow: auto;

  .major-header {
    img {
      width: 16rem;
    }
    margin-top: 6rem;
    margin-bottom: .25em;
  }

  .body {
    background-color: white;
    border-radius: 24px;
    width: 60rem;
    max-width: 100%;
    max-height: 100%;
    box-shadow: $boxShadowCard;

    .body-header {

      .top-header {
        text-transform: none;
        font-weight: 800;
        font-size: 2.4rem;
        line-height: 3.2rem;
        margin-top: 40px !important;
        div {
          text-align: center;
          width: 30rem;
        }
      }

      i {
        font-size: 1.8rem;
      }

      .bar-item:hover {
        background-color: transparent;
        i {
          color: $ckGreenProgress;
        }
      }
    }

    .body-content {
      padding: 32px;
      gap: 32px;

      .md-button.teacher,
      .md-button.student {
        min-height: 16em;
        width: 25.2rem;
        text-align: center;
        background-color: $backgroundGrey;
        border-radius: 12px;
        margin: 0;

        img {
          margin: 1em auto;
          width: 12rem;
        }

        .inner-icon {
          color: $ckAltGrey;
          font-size: 8.0rem;
          margin-bottom: .5em;
        }

        .classkick-header {
          text-transform: none;
          font-size: 2.4rem;
          line-height: 3.2rem;
        }

        &:hover {
          background-color: $lightHover;
        }
      }
    }

    .body-footer {
      height: 1.6rem;
      padding-bottom: 32px;
      span {
        font-size: 3.2rem;
        letter-spacing: -7px;
        color: $backgroundGrey;
      }
      .active {
        color: $ckGreen;
        z-index: 1;
      }
    }
  }

  .loading {
    margin-top: 8em;
    height: 10rem;
    width: 100px;
    background-color: white;
    border-radius: 10px;
  }

}

