.anon-student-assignment-work {
  height: 100vh;

  @extend .student-account-assignment-work;

  .assignment-question-header {
    .class-code-link {
      margin: 0 1em;
      padding-top: 1.75em;
    }

    .feedback-inbox-button {
      @extend .bar-item;

      width: 75px;
    }
  }

  .loading {
    position: absolute;
    background-color: white;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 60%;
  }
}

.anon-student-profile-menu .username {
  color: $ckHeaderGrey;
}
