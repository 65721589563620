md-dialog.add-manipulative-image-dialog {
  max-height: 95%;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-body {
    min-height: 100px;
    min-width: 550px;
    padding: 0 60px;

    .ck-dialog-title {
      @extend .classkick-header;
      text-align: center;
      margin-bottom: 2.4rem;
    }

    img {
      height: 300px;
    }

    .description {
      margin: 2.4rem 0 6rem 0;
      font-family: $fontRoundedBook;
      text-align: center;

      a {
        text-transform: none;
      }
    }
  }

  .ck-dialog-actions {
    padding: 0 2.4rem 2.4rem 2.4rem;

    .md-button {
      margin: 0;
    }
  }
}
