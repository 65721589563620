.assignment-edit {

  height: 100vh;

  $thumbnailWidth: 208px;
  $thumbnailHeight: 156px;
  $thumbnailPadding: 1.2rem;

  .header {
    padding: 0;
    color: $ckAltGrey;
    font-size: 1.4rem;
    font-weight: bold;

    .ck-home-2023, .ck-contextualhelp, .ck-moreoptions {
      font-size: 2rem !important;
      color: $ckAltGrey !important;
    }

    .ck-gift {
      font-size: 2.1rem !important;
    }

    .ck-bell {
      font-size: 2.5rem !important;
    }

    .ck-forward-arrow-2023 {
      font-size: 1.6rem !important;
    }

    .md-button.back {
      margin-left: 10px;
    }

    .assignment-name {
      margin: auto 0;
      overflow: hidden;
      padding-top: 2px;
    }

    .right-side {

      .share-button {
        color: $ckAltGrey;
        font-size: 14px;
        text-transform: capitalize;
        border-radius: 8px;
        padding: 16px 13px;
        height: 4.8rem;
        background: $backgroundGrey;
        margin-right: 2.4rem;

        &:hover {
          background: $ckAltGrey;
          color: $white;
        }

        i {
          margin-right: 14px;
        }
      }

    }

    .nav-toggle {
      width: 44.4rem;

      &.show-preview-button {
        width: 65.5rem;

        &.co-teacher-nav {
          width: 57.5rem;
        }

        .md-button {
          width: 19rem;
        }
      }

      .background-border, .md-button, .md-button[disabled] {
        border-radius: 100px;
      }

      .background-border {
        width: inherit;
        border-color: $backgroundGrey;
        background-color: $backgroundGrey;
      }

      .md-button {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 16px;
        color: $ckAltGrey;
        background-color: $backgroundGrey;
        width: 14.8rem;
        letter-spacing: 2px;
        font-weight: bold;
      }

      .md-button[disabled] {
        background-color: $ckAltGrey;
        border-color: $ckAltGrey;
        color: $white;

      }
    }

    .bar-item {
      width: 45px;
    }

    @media screen and (min-width: 1172px) {
      &.mobile {
        display: none;
      }

      .condensed-button {
        display: none;
      }
    }

    @media screen and (max-width: 1171px) {
      display: block;
      height: auto;
      padding: 0 16px 8px 16px;

      &.desktop {
        display: none;
      }

      .header-row {
        margin: 10px 0;
      }

      .md-button.back {
        margin-left: 0px;
      }

      .assignment-name {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .saving-indicator {
        display: flex;
        align-items: center;
        justify-content: center;

        .saving-message {
          margin-top: 8px;
        }
      }

      .nav-toggle {
        display: flex;
        width: 100%;

        .md-button {
          width: inherit;
          min-width: auto;
        }

        .student-preview {
          min-width: 15rem;
        }
      }

      .background-border {
        position: relative !important;
        margin-botton: 20px;
      }

      .right-side {
        .share-button {
          margin-right: 16px;
        }
      }
    }
  }

  .loading, .error {
    text-align: center;

    .loading-text, .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }

  .body {
    background: $backgroundGrey;
    overflow: auto;
    height: calc(100vh - #{$teacherHeaderHeight});
    width: 100vw;

    & > * {
      width: 100%;
      min-width: 50em;
    }
  }

  .metadata {

    padding: 0 0 16px 0;
    background-color: white;
    box-shadow: $boxShadow;
    margin-bottom: 3px;

    & > div {
      margin: 0 6rem;
    }

    .access-option {
      margin-left: 16px;
      width: 220px;

      md-select[disabled] .md-select-value {
        background-image: none;
        border-bottom-color: $disabledGrey !important;
      }
    }

    .grade-option,
    .subject-option {
      margin-left: 16px;
      width: 110px;
    }

    md-input-container {
      padding: 0;
      margin-top: 12px;
    }

    md-input-container .md-resize-handle {
      bottom: 4px;
    }
  }

  .questions {

    background: $backgroundGrey;

    .slides-header {
      @extend .section-header;
      @include flex-row(flex-start, space-between);
      padding: 1em 6rem;

      &.center {
        align-items: center;
      }
    }

    .student-preview {
      font-size: 1.3rem;
      color: $ckAltGrey;
      padding: 12px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      background-color: $veryLightGrey;
      border-radius: 8px;
      height: fit-content;

      &:hover {
        text-decoration: none;
        background-color: $ckAltGrey;
        color: $white;
      }

      .ck-roster {
        margin-right: 12px;
        font-size: 1.6rem;
      }

      .ck-play {
        margin-left: 12px;
        padding: 7px;
        font-size: 1.2rem;
      }
    }

    .questions-container {
      padding: 0 4.8rem 2em 4.8rem;

      & > * {
        display: inline-block;
        width: calc(#{$thumbnailWidth} + (#{$thumbnailPadding} * 2));
        height: calc(#{$thumbnailHeight} + (#{$thumbnailPadding} * 2));
        padding: $thumbnailPadding;
      }

      .question {
        display: inline-block;
        position: relative;
        width: $thumbnailWidth;
        height: $thumbnailHeight;
        outline: none;
        cursor: pointer;

        & > * {
          position: absolute;
        }

        .move-question-bar {
          width: 2px;
          height: 100%;
          display: none;
          background-color: $ckHeaderGrey;
          pointer-events: none;

          &.left {
            display: inherit;
            left: -12px;
          }

          &.right {
            display: inherit;
            right: -14px;
          }
        }

        .question-mask {
          background-color: $whiteMask;
          width: 100%;
          height: 45px;
          bottom: 0;
          left: 0;

          & > * {
            height: 45px;
          }

          .page-number, .menu-button {
            width: 45px;
          }

          .question-score {
            margin: 0;
            width: 5em;
            height: 2.75em;

            input {
              text-align: center;
              height: 100%;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
            }
          }

          .menu-button {

            md-menu {
              padding: 0;
            }

            .md-button {
              border-radius: 0;
              width: 45px;
              height: 45px;
              min-width: 45px;
              padding: 0;
              margin: 0;

              .md-ripple-container {
                border-radius: 0;
              }
            }
          }

          grade-input {
            width: 6em;
            height: 2em;
            background-color: white;
            color: $ckHeaderGrey;
          }
        }

        assignment-sheet {
          width: $teacherAssignmentEditSheetWidth;
          height: $teacherAssignmentEditSheetHeight;
          overflow: hidden;
          background-color: white;
          pointer-events: none;
          box-shadow: $boxShadowCard;

          /* Our canvas is 2:1, so calculate height based on width */
          svg {
            height: calc(2 * #{$teacherAssignmentEditSheetWidth});
          }
        }
      }

      .add-question {
        width: 208px;
        height: 156px;
        margin: 0;
        padding: 0;
        font-size: inherit;

        border-style: dashed;
        border-width: 2px;
        border-color: $ckGreen;
        border-radius: 0;

        .md-ripple-container {
          border-radius: 0;
        }
      }
    }
  }

  div.tip {
    @extend .section-header;
    color: $ckHeaderGrey;
    box-sizing: border-box;
    pointer-events: auto;
    text-align: center;

    a {
      color: $ckGreen;
      text-transform: none;
    }
  }
}

.assignment-more-options-menu {
  max-height: unset;
}

.move-question-dialog {

  width: 420px;

  .dialog-nav {
    height: 60px;
  }

  .dialog-header {
    text-align: center;
    height: 40px;
  }

  .dialog-title {
    text-align: center;
    border-bottom: solid 1px $dividerGrey;
    padding-left: 2em;
    padding-right: 2em;
  }

  md-dialog-content {
    padding: 0;
    max-height: 30em;
    min-height: 4em;
    border-top: 1px solid $dividerGrey;

    md-list-item {
      background-color: white;

      * {
        font-family: $fontRoundedBook;
        color: $ckHeaderGrey;
      }
    }

    md-list-item:not(:last-child) {
      border-bottom: 1px solid $dividerGrey;
    }

  }

  md-dialog-actions {
    min-height: 90px;
    padding: 0 11px 13px 0;
    border-top: 1px solid $dividerGrey;

    .md-button {
      margin: 0;
    }
  }
}
