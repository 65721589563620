.student-assignments-list {

  height: 100vh;
  text-align: center;

  .header {
    border: none;
  }

  .search-and-classcode {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 1.5rem;

    .classcode-input {
      label {
        width: auto;
        margin: 0;
      }
    }
  }

  .list-header {

    .md-button {
      margin: 0;
      padding: 0 8px;
    }
  }

  .content {
    text-align: left;
    overflow: auto;
    background-color: $backgroundGrey;
  }

  .body i:not(.ck-loading), .content i {
    width: 2em;
    text-align: center;
    pointer-events: none;

    &.gray {
      color: $lightText;
    }

    &.ck-carrot {
      display: inline;
      opacity: 0;
      @include transition(all, 200ms);
      transform: rotate(-180deg);

      &.show {
        opacity: 100;
      }
      &.flip {
        transform: rotate(0);
      }
    }
  }

  .accordion {
    box-shadow: $boxShadow;

    i {
      width: 2em;
      text-align: center;
      pointer-events: none;

      &.gray {
        color: $lightText;
      }
    }

    md-list-item {
      background-color: white;
      border-bottom: 1px solid $dividerGrey;
      height: $teacherListItemHeight;
      box-sizing: border-box;
      color: $ckHeaderGrey;

      .md-button {
        color: $ckHeaderGrey;
        font-family: $fontRoundedBook;
      }

      .gray {
        color: $lightText;
      }

      .ck-star-filled:before {
        font-size: 2rem;
        color: $blueViolet;
      }
      
      .ck-pin-clicked {
        font-size: 1.7rem;
        color: $blueViolet;
      }
    }

    .top {

      i.ck-pagerback {
        @include transform(rotate(90deg));
        @include transition(transform, 300ms);
        &.closed {
          @include transform(rotate(270deg));
        }
      }

      .text {
        margin-left: 0.8rem;
        pointer-events: none;

        span {
          position: relative;
          top: 1px;
        }
      }
    }

    .body {
      @include transition(max-height, 300ms);
      max-height: 200px;
      overflow: hidden;

      &.closed {
        max-height: 0;
      }

      .text {
        margin-left: 0.8rem;
        pointer-events: none;

        span {
          position: relative;
          top: 1px;
        }
      }
    }
  }

  .total-score {
    padding: 0 8px;
    width: 184px;
    min-height: 2.6rem;

    .star {
      font-size: 100%;
      color: $ckGreenProgress;
      animation-name: starBounce;
      animation-duration: 1s;
    }
  }

  .total-feedback {
    padding: 0 8px 0 0;
    width: 184px;
    min-height: 48px;

    .md-button.bar-item {
      min-height: 48px;
      width: 81px;
    }
  }

  .empty-message {
    margin: 2em;
    text-align: center;
    font-family: $fontRoundedBook;
  }

  .empty-folder-message {
    font-family: $fontRoundedBook;
  }

  .error, .loading {
    text-align: center;

    .loading-text, .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }
}

@keyframes starBounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-10px);color: $ckClassroom; font-size: 110%}
  60% {transform: translateY(-5px);color: $ckClassroom; font-size: 110%}
}
