.student-account-choice-dialog {

  max-width: unset;
  max-height: 95%;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .content {
    padding: 0 60px 60px 60px;

    .dialog-title {
      @extend .classkick-header;
      margin-bottom: 24px;
      text-align: center;
    }

    .anon,
    .portfolio {

      .md-button {
        min-height: 180px;
        margin: 0 2.5em;
        text-align: center;
        box-shadow: $boxShadowCard;
        padding: 2.5em;

        img {
          margin: 1em auto;
          width: 10rem;
        }

        .classkick-header {
          color: $ckGreen;
          text-transform: none;
        }

        .anchor {
          display: block;
          height: 0;
          width: 0;

          .pro-tag {
            position: relative;
            background-color: $ckPro;
            border-radius: 2px;
            color: white;
            font-size: 5pt;
            padding: 3px;
            height: 5px;
            margin: 0;
            width: 16px;
            bottom: 24px;
            left: 35px;
          }
        }
      }

      .details {
        padding: 16px 32px 0px 16px;
        font-family: $fontRoundedBook;

        li {
          min-height: 32px;
          width: 280px;
        }
      }
    }

    md-checkbox {
      margin-bottom: 0;
    }
  }
}
