.edit-user {

  &.anon-student {
    height: 100vh;

    .body {
      height: inherit;
    }
  }

  background-color: $backgroundGrey;

  .header {
    background-color: white;

    .left-side {
      height: inherit;
    }
  }

  .body {
    background-color: white;
    box-shadow: $boxShadow;

    .edit-profile-column {
      padding: 1em;

      md-input-container {
        width: 100%;

        .md-input[disabled] {
          border-bottom-color: $disabledGrey;
        }
      }

      md-checkbox {
        margin-top: 0.5em;
        margin-left: 0.5em;
      }

      .md-button {
        margin-top: 1.6em;
        max-width: 50%;
      }
    }

    .edit-anon-user {

      .edit-profile-column {
        align-items: center;
        padding: 8.1rem 0 8.1rem 0;
      }

      md-input-container {
        width: 400px;

        label {
          padding-bottom: 0.5rem;
          margin-left: 0;
        }

        .md-input {
          height: 4.4rem
        }
      }
    }
  }

  .error, .loading {
    text-align: center;

    .loading-text, .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }

}
