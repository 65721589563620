.address-book {
  md-virtual-repeat-container {
    height: 150px;
    width: 600px;

    md-list {
      background: white;
      color: $ckHeaderGrey;
      padding: 0 0 0 0;

      md-list-item {
        box-sizing: border-box;
        font-family: $fontRoundedBook;
        min-height: 10px;
        height: 30px;
      }

      md-list-item-inner {
        min-height: 30px;
      }
    }

    border: 1px solid $borderGrey;
    border-radius: 5px;

    md-checkbox {
      .md-icon {
        transform: scale(0.8);
        border: 1px solid $slate;
      }

      &.md-checked .md-icon {
        background-color: $ckGreenProgress;
        border: 1px solid $ckGreenProgress;

        &:after {
          margin-left: 2px;
          margin-top: 1px;
          transform: rotate(29deg);
          border-radius: 1px;
          border-width: 2.5px;
          left: 3.5px;
        }
      }
    }

    .peers-list {
      background-color: transparent;

      .loading-spinner {
        display: flex;
        justify-content: center;
        font-size: 35px;
        margin: 8%;
      }

      .peer-checkbox {
        margin-right: 10px;
      }

      .checkbox-label {
        font-size: 0.8em;
        font-family: $fontRoundedBook;
        font-weight: 600;
        color: $ckAltGrey;
      }
    }
  }

  md-list-item md-checkbox {
    margin-right: 1em;
    margin-left: -2px;
  }

  md-input-container {
    margin: 10px 10px 5px 10px;
    padding-left: 0;
    padding-top: 0;

    i {
      margin-left: -210px;
      padding-top: 7px;
      font-size: 10px;
    }

    input {
      border: 1px solid $borderGrey;
      border-radius: 5px;
      font-size: 1.2rem;
      width: 220px;
      height: 25px;
      padding-left: 26px;

      &:focus {
        border: 2px solid $ckGreenProgress;
        border-radius: 5px;
      }

      &::placeholder {
        color: $lightText;
      }
    }
  }
}