.select-purchase-period {

  .yearly-price {
    margin-bottom: 12px;

    md-checkbox {
      font-size: $bodyCopy;
      margin-bottom: 0;
      width: 130px;
    }

    .purchase-period,
    .original-price,
    .discounted-price {
      visibility: hidden;
      margin-top: 0.3rem;
      height: 1.3rem;
      color: $lightText;
      font-family: $fontRoundedBook;
    }

    &.active {
      .purchase-period,
      .original-price,
      .discounted-price {
        visibility: inherit;
      }
    }

    .purchase-period {
      width: 175px;
    }

    .original-price {
      width: 65px;
      text-decoration: line-through;
    }

    .discounted-price {
      margin-left: 8px;
      width: 65px;
      text-align: right;
    }
  }

  .total {
    border-top: 1px solid $borderGrey;
    padding-top: 8px;
    margin-top: 12px;

    .price {
      color: $ckGreen;
    }
  }

}
