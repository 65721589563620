.verify-organization-member-dialog {
  padding: 0;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-body {
    padding: 0 35px;
    text-align: center;
    margin-bottom: 36px;

    .primary-message {
      @extend .classkick-header;
      margin-bottom: 24px;
    }

    .secondary-message {
      @extend .classkick-header;
      font-family: $fontRoundedBook;
      margin-bottom: 24px;
    }
  }

  md-dialog-actions {
    padding: 0 2.4rem 2.4rem 2.4rem;

    .md-button {
      margin: 0 0 0 1.6rem;
    }
  }
}

