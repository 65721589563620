ai-assistant-capture {

  position: relative;
  min-height: 390px;
  min-width: 200px;

  .start, .ai-assistant-loading {
    p {
      margin-top: 5px;
    }
  }

  .ai-assistant-loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
