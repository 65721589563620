.public-assignments-list {
  min-height: 100vh;
  max-height: 100vh;

  .header {

    padding-left: 0;

    .logo {
      margin-left: 24px;
      height: 30px;
    }
    .logo:hover {
      cursor: pointer;
    }
    .logo:focus {
      outline: none;
    }
  }

  .body {

    .user-info {
      height: 100px;
      margin: 0 32px;

      @media (min-width:925px) {
        margin: 0 120px;
      }
    }

    .list-container {
      @media (min-width:925px) {
        margin: 0 120px;
        box-shadow: $boxShadow;
      }

      .list-header {
        .md-button {
          width: 100%;

          &.modified {
            width: 140px;
          }
        }
      }

      md-list {
        overflow: auto;

        &.with-banner {
          min-height: calc(#{$teacherListHeightWithBanner} - 10rem);
          max-height: calc(#{$teacherListHeightWithBanner} - 10rem);
        }

        .ck-assignment {
          margin-right: 18px;
        }
        .ck-folder {
          margin-right: 18px;
        }
      }

    }
  }

  .error, .loading {
    text-align: center;

    .loading-text, .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }

}
