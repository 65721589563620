.contract-user-reset-password-dialog {
  width: 40em;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
  }

  .body {
    height: 20em;

    md-input-container {
      max-height: 3em;
      width: 27em;
      margin-bottom: 12px;
    }

    .checkbox-container {
      width: 100%;
      padding-top: 8px;
    }

    .reset-password-buttons {
      padding: 1em;
      width: 100%;
    }
  }
}
