md-toast._md-bottom.ck-snackbar {
  left: 20vw;
  bottom: 0;
  width: 60vw;

  .md-toast-content {
    width: 100%;
    padding: 10px;
  }

  .md-button.ck-snackbar-button {
    color: $ckGreen;
  }

}

@media (max-width: 959px) {

  md-toast._md-bottom.ck-snackbar {
    left: 0;
    bottom: 0;
    width: 100vw;

  }

}
