.roster-lock-dialog {
  width: 600px;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
  }

  .lock-menu-body {
    padding: 16px 60px 60px 60px;

    .lock-menu-inner {
      width: 80%;
      margin: 0 auto;
    }

    .lock-list {
      list-style-type: none;
      padding-left: 0;
    }

    .lock-list-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 32px;
    }

    // Add padding on the 2nd+ list items
    .lock-list-item + .lock-list-item {
      padding-top: 1.5em;
    }

    .md-label {
      margin-left: 40px;
    }

    md-checkbox {
      font-size: $bodyCopy;

      .md-label {
        margin-top: 0.4rem;
      }
    }

    .body-subtext {
      margin: 0;
      pointer-events: none;
    }
  }
}
