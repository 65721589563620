.pro-trial-overlay {
    max-width: 600px;
    padding: 32px;
    border-radius: 24px;

    .header {
        display: flex;
        padding-bottom: 32px;
        border-bottom: none;

        .header-title {
            display: flex;
            align-items: center;
            font-size: 24px;
            font-family: $fontRoundedBold;
            color: $ckAltGrey;
        }

        .close {
            cursor: pointer;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &:hover {
                i {
                    color: $ckGreenProgress;
                }
            }

            i {
                color: $ckHeaderGrey;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    .body {

        .body-title {
            color: $ckGreenProgress;
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            justify-content: center;
            padding: 12px;
        }

        .body-content {
            padding: 12px 12px 32px 12px;

            .prompt {
                color: $ckAltGrey;
                font-size: 10px;
                line-height: 16px;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-weight: 800;
            }

            .detail {
                padding: 2px 0px;
                width: 512px;
                max-width: 100%;
                font-family: $fontRoundedBook;

                p {
                    font-weight: 325;
                    font-size: 12px;
                    line-height: 20px;
                    color: $ckAltGrey;

                    a {
                        text-transform: lowercase;
                    }
                }
            }
        }

        .body-footer {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            button {
                height: 48px;
                font-weight: 800;
                display: flex;
                justify-content: center;
            }
            .benefits {
                text-transform: capitalize;
                font-size: 14px;
            }
            .refer {
                font-size: 16px;
                width: 169px;
            }
        }
    }

}