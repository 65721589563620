.session-watch {

  $cellHeight: 30px;
  $cellWidth: 90px;
  $cellPadding: 10px;
  $rowHeight: calc(#{$cellHeight} + #{$cellPadding} + #{$cellPadding});

  $headerCellHeight: 32px;
  $headerRowHeight: 48px;

  $studentInfoWidth: 280px;

  .loading, .error {
    text-align: center;

    .loading-text, .error-text {
      font-family: $fontRoundedBook;
      font-size: $bodyCopy;
    }
  }

  .error-container {
    background-color: $backgroundGrey;

    .error {
      background: white;
      box-sizing: border-box;
      padding: 1em 2em;
      text-align: center;
      width: 100%;
      font-size: 125%;
    }

    .no-roster {
      @extend .classkick-header;
      margin-top: 60px;
      background-color: $backgroundGrey;
      text-align: center;

      a {
        text-transform: none;
        cursor: pointer;
      }
    }
  }

  .question-cell .md-button,
  .horizontal-scroll-button.back .md-button,
  .horizontal-scroll-button.next .md-button {
    min-width: 100%;
    min-height: 100%;
    padding: 0;
    line-height: $cellHeight;
  }

  .body {
    overflow: auto;
    width: 100vw;

    .body-head {
      box-shadow: $boxShadow;
      z-index: 11;

      .horizontal-scroll-container {
        max-height: $headerRowHeight;
        overflow-y: hidden;

        .horizontal-scroll {
          box-sizing: content-box;
        }
      }

      .student-info {
        width: $studentInfoWidth;
        min-width: $studentInfoWidth;
        height: $headerRowHeight;
        padding: 0 1.3rem 0 2.4rem;
      }

      .horizontal-scroll-button {
        width: $headerCellHeight;
        height: $headerRowHeight;

        &.back {
          border-left: 1px solid $dividerGrey;
        }

        &.back .md-button, &.next .md-button {
          @extend .md-button.square;

          min-height: $headerCellHeight;
          margin: 0;
          color: $ckHeaderGrey;
          line-height: 1;

          &[disabled] {
            color: $disabledGrey;
          }
        }
      }

      .horizontal-scroll-end-spacer {
        width: 1.2rem;
      }
    }

    .body-content {
      overflow: auto;
      box-sizing: content-box;
      background-color: $backgroundGrey;

      .student-info, .horizontal-scroll {
        @include content-height();
        min-height: calc(100% - 2.5em);
        box-sizing: border-box;

        .column:first-child > div {
          padding-left: $headerCellHeight;
        }

        .row {
          box-sizing: border-box;
          border-bottom: 1px solid $dividerGrey;
          padding: 0;
          margin: 0;
          height: $rowHeight;

          &.info {
            padding: 0.8rem 0.8rem 0.8rem 2.4rem;
            color: $lightText;
            font-family: $fontRoundedMedium;

            &.online{
              color: $ckHeaderGrey;
            }

            .truncate {
              margin-top: 1px;
            }

            .anon-tag {
              color: $lightText;
              margin: 0 6px 1px 8px;
              background-color: $disabledGrey;
              border-radius: 2px;
              font-size: 6pt;
              min-width: 27px;
              padding: 3px 3px 2px 3px;
              cursor: pointer;
              outline: none;
            }

            .date {
              font-size: $bodySub;
              font-family: $fontRoundedBook;
              color: $lightText;
            }

            .total-score {
              margin: 1px 8px 0 0;
            }

            .md-button.peer-help-inbox-btn {
              color: $lightText;
              padding: 0 10px;
              margin: 0;
              max-width: 75px;
              min-width: unset;
              height: 2.2rem;

              .ck-studenthelpers {
                margin-right: 3px;
              }

              &.active {
                color: $ckGreen;
              }
            }
          }

        }
      }

      .student-info {
        width: $studentInfoWidth;
        min-width: $studentInfoWidth;
        background-color: white;
        z-index: 10;
        box-shadow: $boxShadow;

        .name {
          cursor: pointer;
          outline: none;

          &:hover {
            color: $ckHeaderGrey;
          }
        }
      }

      .horizontal-scroll-spacer {
        margin-right: 0.8rem;
      }

      .question-cell {
        .current,
        .unstarted {
          box-sizing: border-box;
          position: relative;
          bottom: $cellHeight;
          width: $cellWidth;
          height: $cellHeight;
          pointer-events: none;
        }
        .current {
          border: 3px solid $ckHeaderGrey;
        }
        .unstarted {
          background-color: $disabledGrey;
        }
        .md-button {
          background-color: white;
        }
        .anchor {
          height: 0;
          width: 50%;

          .md-button {
            z-index: 2;
            $handWidth: $cellWidth * 0.5;
            $handHeight: $handWidth * 0.8;
            position: relative;
            bottom: $cellHeight - 2px;
            left: $cellWidth * 0.5 - 2px;
            width: $handWidth;
            min-width: $handWidth;
            height: $handHeight;
            min-height: $handHeight;

            box-shadow: $boxShadowCard;
            border-radius: $handWidth $handWidth 0 0;
            color: white;

            i {
              padding-top: 1px;
            }

            &.active-helper {
              background-color: white;
              i {
                @extend .ck-studenthelpers;
                color: $ckGreen;
              }
            }
            &.help {
              background-color: $ckYellow;
            }
            &.check {
              background-color: $ckGreen;
            }
            &.chat {
              background-color: white;
              i {
                @extend .ck-chat;
                color: $ckGreen;
              }
            }
          }
        }
        .question-answer {
          max-width: 50%;

          span {
            @extend .truncate;
          }
        }
      }

      .tip {
        @extend .section-header;

        width: 100%;
        height: 65px;
        margin: 0;
        box-sizing: border-box;

        a {
          cursor: pointer;
          text-transform: none;
        }

        a.class-code,
        a.show-students {
          margin-left: 0.5em;
          text-transform: uppercase;
        }
      }
    }

    .horizontal-scroll {

      overflow-y: hidden;
      overflow-x: auto;
      @include content-height();

      .horizontal-scroll-content {
        @include content-width();
        flex-basis: max-content;
      }

      &.md-orient-horizontal .md-virtual-repeat-scroller {
        &::-webkit-scrollbar {
          display: none;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .question-cell {
        padding: 0;
        box-sizing: border-box;
        display: inline-block;
        height: $cellHeight;
        width: $cellWidth;
        margin: $cellPadding;
        overflow: hidden;
        background-color: white;

        .md-button {
          @extend .md-button.square;

          margin: 0;
          height: 100%;
          min-width: 100%;
          width: 100%;
          color: $ckHeaderGrey;
        }

        .question-work-content {
          width: 100%;
          height: 30px;
          color: white;
          padding: 0 0.9em;

          > div {
            width: 10%;
          }
        }
      }

      .question-cell-header {
        height: $headerRowHeight;
        width: calc(#{$cellWidth} + #{$cellPadding} * 2);
        margin: 0;


        > .md-button {
          @extend .md-button.square;

          padding: 0 10px;
          box-sizing: border-box;
          border: 2px solid $borderGrey;
          border-radius: 4px;
          width: $cellWidth;
          height: $headerCellHeight;
          min-height: $headerCellHeight;
          margin: 0;
          color: $ckHeaderGrey;

          i.ck-edit {
            display: none;
            margin-bottom: 1px;
          }

          .value {
            color: $lightText;
            font-size: $bodySub;
            margin-bottom: 1px;
            text-transform: none;
          }

          &:hover {
            .value {
              display: none;
            }
            i.ck-edit {
              display: inherit;
            }
          }
        }
      }
    }
  }
}
