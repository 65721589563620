.video-player-dialog {

  max-height: unset;
  max-width: unset;

  .video-player-dialog-header {
    height: 60px;

    .bar-item {

      width: 60px;

      .ck-close {
        color: $ckGreen;
      }
    }
  }

  .video-player-dialog-footer {
    height: 60px;
  }

}

