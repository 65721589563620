.session-work {

  $cellHeight: 100px;
  $cellWidth: 133px;
  $cellPadding: 10px;

  $headerCellHeight: 32px;
  $headerRowHeight: 48px;


  $studentInfoHeight: 24px;
  // TODO Why in the world does having this set to 100vw make our header cells perform better on horizontal scroll?
  $studentInfoWidth: 100vw;

  .loading, .error {
    text-align: center;

    .loading-text, .error-text {
      font-family: $fontRoundedBook;
      font-size: $bodyCopy;
    }
  }

  .error-container {
    background-color: $backgroundGrey;

    .error {
      background: white;
      box-sizing: border-box;
      padding: 1em 2em;
      text-align: center;
      width: 100%;
      font-size: 125%;
    }

    .no-roster {
      @extend .classkick-header;
      margin-top: 60px;
      background-color: $backgroundGrey;
      text-align: center;

      a {
        text-transform: none;
        cursor: pointer;
      }
    }
  }

  .question-cell .question-button,
  .horizontal-scroll-button.back .md-button,
  .horizontal-scroll-button.next .md-button {
    min-width: 100%;
    min-height: 100%;
    padding: 0;
    line-height: $cellHeight;
  }

  .body {
    overflow: auto;
    width: 100vw;

    .body-head {
      box-shadow: $boxShadow;
      z-index: 11;

      .horizontal-scroll-container {
        max-height: $headerRowHeight;
        overflow-y: hidden;

        .horizontal-scroll {
          max-height: $headerRowHeight;
          box-sizing: content-box;

          .horizontal-scroll-content {
            max-height: $headerRowHeight;
          }
        }
      }

      .horizontal-scroll-button {
        width: $headerCellHeight;
        height: $headerRowHeight;

        &.back .md-button, &.next .md-button {
          @extend .md-button.square;

          min-height: $headerCellHeight;
          margin: 0;
          color: $ckHeaderGrey;
          line-height: 1;

          &[disabled] {
            color: $disabledGrey;
          }
        }

        &.back {
          border-left: 1px solid $dividerGrey;
        }

        &.next {
          margin-right: 1.2rem;
        }
      }
    }

    .body-content {
      display: block;
      position: relative;
      overflow: auto;
      box-sizing: content-box;
      background-color: $backgroundGrey;

      .hidden {
        display: none;
      }

      .student-info-container {
        position: relative;
        width: 0;
        height: 0;
        overflow: visible;
      }

      .flex-break {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .virtual-container {
        width: 100%;
        height: 100%;
      }

      .student-info, .horizontal-scroll {
        @include content-height();
        min-height: calc(100% - 2.5em);
        box-sizing: border-box;

        .row {
          box-sizing: border-box;
          border-bottom: 1px solid $dividerGrey;
          margin: 0;

          // cell height + cell padding + cell padding + student info height
          // 100px + 10px + 10px + 24px
          height: 144px;

          // We want the edge of the first cell to be 32px away from the edge of the screen, but there's
          // a 10px margin around each cell. So 32px minus 10px gives us the right padding-left of 22px.
          padding: $studentInfoHeight 0 0 22px;

          .question-cell:last-child {
            margin-right: 54px;
          }
        }
      }

      .student-info {
        width: $studentInfoWidth;
        min-width: $studentInfoWidth;
        background-color: transparent;
        pointer-events: none;
        position: absolute;
        z-index: 10;

        .info {
          background-color: transparent;
          height: $studentInfoHeight;
          padding: 0.8rem 0.8rem 0 3.2rem;

          // We want each student info to be at the top of each row, so each subsequent student info should
          // be the row height minus the height of the student info below the previous student info.
          // row height - student info height
          // 144px - 24px = 120px
          margin-bottom: 120px;
          color: $lightText;
          font-family: $fontRoundedMedium;

          > * {
            pointer-events: auto;
          }

          &.online{
            color: $ckHeaderGrey;
          }

          .name {
            margin: 2px 15px 0 0;
            outline: none;
            cursor: pointer;

            &:hover {
              color: $ckHeaderGrey;
            }
          }

          .total-score {
            margin: 2px 8px 0 0;
          }

          .md-button.peer-help-inbox-btn {
            color: $lightText;
            padding: 0;
            margin: -3px 0 0 0;
            max-width: 45px;
            min-width: 45px;
            height: 2.2rem;

            .ck-studenthelpers {
              margin: 1px 3px 0px 0;
            }

            span {
              margin-top: 2px;
            }

            &.active {
              color: $ckGreen;
            }
          }

          .account-type {
            margin: 2px 0 0 15px;

            .anon-tag {
              color: $lightText;
              background-color: $disabledGrey;
              border-radius: 2px;
              font-size: 6pt;
              width: 26px;
              padding: 3px 3px 2px 3px;
              cursor: pointer;
              outline: none;
            }
          }
        }

        .tip {
          @extend .section-header;

          height: 65px;
          box-sizing: border-box;
          pointer-events: auto;

          a {
            cursor: pointer;
            text-transform: none;
          }

          a.class-code,
          a.show-students {
            margin-left: 0.5em;
            text-transform: uppercase;
          }
        }
      }

      .question-cell {
        display: inline-block;
        position: relative;
        width: $cellWidth;
        height: $cellHeight;
        cursor: pointer;

        &.selected-cell {
          border: 4px solid $ckGreenProgress;
        }

        &.hovering {
          border: 4px solid $ckClassroom;
        }

        assignment-thumbnail {
          position: absolute;
          top: 0;
          left: 0;
          width: $cellWidth;
          height: $cellHeight;
          overflow: hidden;

          &.work {
            background-color: unset;
          }

          /* Our canvas is 2:1, so calculate height based on width */
          svg {
            height: calc(2 * #{$cellWidth});
            pointer-events: none;
          }
        }

        .current,
        .unstarted {
          position: absolute;
          top: 0;
          left: 0;
          pointer-events: none;
        }

        .current {
          width: calc(#{$cellWidth} - 6px);
          height: calc(#{$cellHeight} - 6px);
          border: 3px solid $ckHeaderGrey;
        }

        .unstarted {
          width: $cellWidth;
          height: $cellHeight;
          background-color: $disabledGrey;
        }

        > .question-button {
          text-align: center;
          outline: none;
        }

        .question-score {
          position: relative;
          width: 42px;
          height: 27px;
          margin: 3px;
        }

        .question-answer {
          position: absolute;
          top: 0;
          min-width: 42px;
          max-width: calc(#{$cellWidth} - 6px);
          height: 27px;
          margin: 3px;
          padding: 0 3px;

          span {
            @extend .truncate;
            overflow: visible;
          }

          &.scientific {
            height: fit-content;
          }
        }

        .question-answer.correct {
          color: $ckCharcoalGrey;
          background-color: $ckGradingMeets;
        }

        .question-answer.incorrect {
          color: $ckCharcoalGrey;
          background-color: $ckGradingBelow;
        }

        .question-answer.no-correct {
          color: white;
          background-color: $ckHeaderGrey;
        }

        .question-answer.hide {
          display: none;
        }

        .anchor {
          height: 0;
          width: 100%;

          .question-status-indicator {
            pointer-events: none;
            z-index: 2;
            $handWidth: 45px;
            $handHeight: $handWidth * 0.8;
            position: absolute;
            bottom: -8px;
            right: 2px;
            width: $handWidth;
            min-width: $handWidth;
            height: $handHeight;
            min-height: $handHeight;

            box-shadow: $boxShadowCard;
            border-radius: $handWidth $handWidth 0 0;
            color: white;

            i {
              margin-bottom: 4px;
            }
            &.active-helper {
              background-color: white;
              i {
                @extend .ck-studenthelpers;
                color: $ckGreen;
              }
            }
            &.help {
              background-color: $ckYellow;
            }
            &.check {
              background-color: $ckGreen;
            }
            &.chat {
              background-color: white;
              i {
                @extend .ck-chat;
                color: $ckGreen;
              }
            }
          }
        }
      }

      &.placing-sticker {
        $stickerCursor: url("../../../assets/images/cursors/quickfeedback.svg") 16 16, auto;

        cursor: $stickerCursor;

        .question-cell .question-button {
          cursor: $stickerCursor;
        }
      }

      &.placing-stop {
        $closedEyeCursor: url("../../../assets/ckicon/closed-eye.svg") 16 16, auto;

        cursor: $closedEyeCursor;

        .question-cell .question-button {
          cursor: $closedEyeCursor;
        }
      }
    }

    .horizontal-scroll {

      overflow-y: hidden;
      overflow-x: auto;
      @include content-height();

      .horizontal-scroll-content {
        @include content-width();
        flex-basis: max-content;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .question-cell {
        padding: 0;
        box-sizing: border-box;
        display: inline-block;
        height: $cellHeight;
        width: $cellWidth;
        margin: $cellPadding;
        overflow: hidden;
        background-color: white;

        > .question-button {
          @extend .md-button.square;

          margin: 0;
          height: $cellHeight;
          min-width: $cellWidth;
          width: $cellWidth;
          color: $ckHeaderGrey;
        }

        .question-work-content {
          width: $cellWidth;
          height: $cellHeight;
          color: white;

          .help-icons {
            background-color: $ckHeaderGrey;
          }
        }
      }

      .question-cell-header {
        height: $headerRowHeight;
        margin: 0 calc(#{$cellPadding} * 2) 0 0;

        > .md-button {
          @extend .md-button.square;

          padding: 0 10px;
          box-sizing: border-box;
          border: 2px solid $borderGrey;
          border-radius: 4px;
          width: $cellWidth;
          height: $headerCellHeight;
          min-height: $headerCellHeight;
          margin: 0;
          color: $ckHeaderGrey;

          i.ck-edit {
            display: none;
            margin-bottom: 1px;
          }

          .value {
            color: $lightText;
            font-size: $bodySub;
            margin-bottom: 1px;
            text-transform: none;
          }

          &:hover {
            .value {
              display: none;
            }
            i.ck-edit {
              display: inherit;
            }
          }
        }
      }
    }
  }
}
