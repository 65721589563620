.referral-dialog {
  min-width: unset;
  width: 600px;
  border-radius: 12px;
  max-height: 100vh;

  .ck-dialog-body {
    min-height: 300px;
    overflow-x: hidden;
  }

  .referral-email {

    .referral {
      min-height: 48px;
      margin: 3.2rem 0 2.8rem 0;
      color: $ckAltGrey;

      .referral-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 0 32px;

        div {
          min-height: 48px;
        }

        .close {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;

          &:hover {
            i {
              color: $ckGreenProgress;
            }
          }

          i {
            color: $ckAltGrey;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .title {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-family: $fontRoundedBold;
          text-align: center;
          max-width: 43rem;
          padding: 0 1.5rem;
        }
      }
    }

    .trial, .pro {
      margin: 0 32px 32px 32px;

      .error-messages {
        margin-left: 90px;

        @media screen and (max-width: 750px) {
          margin-left: 0px;
          left: 0px;
        }
      }

      .info {
        font-weight: bold;
        border-radius: 8px;
        padding: 14px 20px 14px 14px;
        gap: 12px;
        background-color: $ckProTrial;
        display: flex;
        margin: 12px 0px 24px 0px;
        color: $white;

        .announcement:after {
          position: relative;
          top: -4px;
          content: url('../../../assets/ckicon/announcement.svg');
        }

        .info-icon {
          font-size: 18px;
        }

        .info-title {
          text-transform: uppercase;
          font-family: $fontRoundedMedium;
          font-size: 10px;
          letter-spacing: .2em;
          padding: 3px 0px 10px 0px;
          font-weight: 400;
        }

        .info-body {
          font-size: 12px;
          font-family: $fontRoundedBook;
          line-height: 20px;
        }
      }

      .submit-referral {
        width: auto;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 1000;
        height: 48px;
        margin-top: 17px !important;
        background-color: $ckGreenProgress;
        color: $ckAltGrey;
        font-size: 1.6rem;
        padding: 1.6rem 2.4rem;
      }

      .referral-container {
        padding-left: 0;
        padding-right: 0;
        color: $ckAltGrey;
        margin: 8px 0px;

        .md-input {
          color: $ckAltGrey;
        }

        &.to {
          .mdr-select {
            width: 100%;
            max-width: 464px;

            @media screen and (max-width: 747px) {
              margin-left: 69px;
            }

            @media screen and (max-width: 728px) {
              margin-left: 0px;
              max-width: none;
            }

            .error-messages {
              margin-left: 5px;
            }
          }
        }

        span {
          left: 0px;
          top: 0px;
          border-radius: 0px;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 2px;
          text-transform: uppercase;
          width: 70px;
          text-align: end;

          @media screen and (max-width: 750px) {
            text-align: start;
          }
        }

        input, textarea {
          border: .5px solid $ckHeaderGrey;
          border-radius: 8px;
          padding: 12px;
          font-size: 1.4rem;
          height: 4.8rem;

          &:focus {
            border: 2px solid $ckGreenProgress;
          }
        }

        .email-body {
          padding: 12px !important;
        }

        .ck-selected-admin-emails {
          margin-left: 16px;

          @media screen and (max-width: 750px) {
            margin-left: 0px;
          }
        }

        input[name="toInput"], input[name="subjectInput"] {
          max-width: 448px;
          margin-left: 16px;

          @media screen and (max-width: 750px) {
            margin-left: 0px;
          }

          @media screen and (max-width: 728px) {
            max-width: none;
          }

          &.error {
            border: 2px solid $ckWarn;
          }
        }

        input[name="toInput"] {
          color: $ckAltGrey;
          font-family: $fontRoundedBold;
          font-size: 1.4rem;

          &::placeholder {
            color: $slate;
          }

          &:focus {
            &::placeholder {
              color: $ckAltGrey;
            }
          }
        }

        textarea[name="bodyInput"] {
          line-height: 2.0rem;
          overflow: auto;
        }
      }

      textarea.md-input {
        line-height: normal;
        padding-top: 8px !important;
        overflow-y: scroll;
      }

      .md-button.ck-primary {
        margin: 20px 0 0 0;
      }
    }
  }

  .referral-email-container {
    padding-bottom: 5.5em;

    .ck-dialog-header {
      height: 5.5em;
    }

    .ck-dialog-body {
      min-height: auto;
      margin: 0 132px;

      &.centered {
        margin: auto;
      }
    }

    .md-button.bar-item {
      height: 70%;

      &.md-ink-ripple {
        padding: 0px;
        width: 45px;
      }
    }
  }

  .copy-invite {
    margin: 20px 20px 0 0;
    text-align: center;
    padding: 1.4rem;
    background-color: $veryLightGrey;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    text-transform: uppercase;
    color: $ckAltGrey;
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;

    &:hover {
      color: $ckGreenProgress;

      &:active {
        background-color: $ckAltGrey;
        color: $white;
      }
    }

    .ck-new-copy-file {
      margin-right: 1.4rem;
      font-size: 2rem;
    }
  }

  .loading {
    .ck-dialog-body {
      overflow: hidden;
    }
  }
}
