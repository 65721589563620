// Colors
$ckGreen: #25C582;
$ckGreenHover: #13AA68;
$ckGreenProgress: #25C582;
$ckGradientGreen: #88C043;
$ckGradientGreen2: #6d9a37;
$backgroundGreen: #f2f5d5;
$referralGreen: #d3f3e6;
$ckCharcoalGrey: #404041;
$ckAltGrey: #45494b;
$ckBackgroundGrey: #fcfcfd;
$ckHeaderGrey: #6d6e70;
$lightText: #b8babc;
$disabledGrey: #e5e5e5;
$borderGrey: #e5e5e5;
$dividerGrey: #eeeeef;
$veryLightGrey: rgba(0, 0, 0, 0.03);
$white: #ffffff;
$black: #000000;
$slate: #808487;
$lightIconGrey: #979797;
$azureBlue: #3498FE;
$red: #FF0000;
$googleRed: #DB4437;
$cleverBlue: #3158D7;
$backgroundGrey: #f7f7f7;
$listHeaderBackground: #f1f1f2;
$ckWarn: #ff6c6c;
$ckLightWarn: #ffeaeb;
$ckYellow: #ffdf78;
$ckLightBlue: #e4f4f3;
$ckTeacherHelper: $ckHeaderGrey;
$ckStudentHelper: #ffde78;
$ckGradingBelow: #f48a8c;
$ckGradingApproaches: #ffbd7b;
$ckGradingMeets: #cae097;
$ckPro: #b568a9;
$ckProTrial: #58BBB7;
$ckClassroom: #57bab7;
$ckSchool: $ckPro;
$ckDistrict: #e25956;
$ckSaleOrange: #E05A59;
$ckPenBlue: #2dcdf8;
$cleverBlue: #428ecb;
$blueViolet: #723DFF;
$ckOrange: #E86826;

$highlightPrimary: lighten($ckGreen, 37%);
$activeWhite: #eaeaea;
$whiteMask: rgba(255, 255, 255, 0.95);

$boxShadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1);
$boxShadowCard: 0 0 8px 1px rgba(64, 67, 69, 0.17);
$altShadow1: rgba(0, 0, 0, 0.12);
$altShadow2: rgba(0, 0, 0, 0.08);
$altShadow3: rgba(0, 0, 0, 0.04);
$lightHover: rgba(158, 158, 158, 0.2);

//new CK colors
$newCkGreen: #25C582;
$newCkGreenHover: #66D781;
$darkGrey: #384145;
$newSlate: #697073;
$newCkYellow: #FCE411;

// Fonts
$fontRoundedBook: GothamRoundedBook, "Varela Round", sans-serif;
$fontRoundedMedium: GothamRoundedMedium, "Varela Round", sans-serif;
$fontRoundedBold: GothamRoundedBold, "Varela Round", sans-serif;

// Font sizes
$bodyCopy: 1.3rem;
$bodySub: 1.1rem;
$errorCopy: 0.8rem;

// Student work heights
$studentHeaderHeight: 60px;
$assignmentToolbarHeight: 45px;
$assignmentSheetMax: calc(100vh - #{$assignmentToolbarHeight} - #{$studentHeaderHeight});
$assignmentSheetMin: calc(100vh - #{$studentHeaderHeight});
$assignmentNavSeparator: calc(#{$assignmentToolbarHeight} - 10px);

// Teacher prep
$teacherSidenavWidth: 320px;
$teacherSidenavTabHeight: 48px;
$teacherHeaderHeight: 60px;
$teacherListItemHeight: 48px;
$teacherListHeaderHeight: 48px;
$teacherBreadcrumb: 54px;
$teacherBanner: 7.2rem;
$teacherListHeight: calc(100vh - #{$teacherListHeaderHeight} - #{$teacherHeaderHeight});
$teacherListHeightWithBreadcrumb: calc(100vh - #{$teacherListHeaderHeight} - #{$teacherHeaderHeight} - #{$teacherBreadcrumb});
$teacherListHeightWithBanner: calc(100vh - #{$teacherListHeaderHeight} - #{$teacherHeaderHeight} - #{$teacherBanner});
$teacherAssignmentEditSheetHeight: 12em;
$teacherAssignmentEditSheetWidth: 16em;

$publicAssignmentSheetHeight: 15em;
$publicAssignmentSheetWidth: 20em;

$rosterMetadataHeight: 12em;
