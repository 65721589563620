.cancel-subscription-dialog {

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
  }

  .content {
    padding: 40px 60px 40px 60px;
    font-family: $fontRoundedBook;
  }

  .cancel-request {
    padding-top: 13px;
    padding-bottom: 20px;

  }

}
