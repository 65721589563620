assignment-sheet {
  position: relative;
  background-color: white;
  height: 2008px;
  width: 1004px;
  overflow: hidden;
  margin: 0 auto;
  visibility: hidden;

  svg {
    height: 100%;
    width: 100%;
    background-color: transparent;
  }

  .hidden-text-input {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    background-color: transparent;
    color: transparent;
    resize: none;
    outline: none;
    pointer-events: none;
    white-space: nowrap;
    opacity: 0;
  }

  .hidden-text-input::selection {
    color: transparent;
    background-color: transparent;
  }

  &.large-canvas {
    box-shadow: $boxShadowCard;
    display: inline-block;
    margin: 2em 0;
  }

  .archive-banner {
    position: relative !important;
    top: 0;
    left: 0;
    padding: 0 8px 0 29px;
    background-color: $ckWarn;
    color: $white;
    height: fit-content;
    width: 100%;
    position: absolute;
    z-index: 61;
    min-height: 7.2rem;

    .archive-container {
      padding: 8px;

      .archive-message {
        font-family: $fontRoundedBold;
        font-size: 1.4rem;
        line-height: 2.4rem;
        margin-bottom: 4px;
      }

    }
  }
}


