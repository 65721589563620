.ck-indicator-circle {
  position: relative;
  width: 8px;
  min-width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $ckGreen;
  top: -5px;
  left: 2px;
}
