.session-question {

  $cellWidth: 133px;
  $cellPadding: 10px;
  $headerCellHeight: 32px;
  $headerRowHeight: calc(#{$headerCellHeight} + #{$cellPadding} + #{$cellPadding});
  $studentInfoWidth: 280px;

  .loading, .error {
    text-align: center;

    .loading-text, .error-text {
      font-family: $fontRoundedBook;
      font-size: $bodyCopy;
    }
  }

  .error-container {
    background-color: $backgroundGrey;

    .error {
      background: white;
      box-sizing: border-box;
      padding: 1em 2em;
      text-align: center;
      width: 100%;
      font-size: 125%;
    }

    .no-roster {
      @extend .classkick-header;
      margin-top: 60px;
      background-color: $backgroundGrey;
      text-align: center;

      a {
        text-transform: none;
        cursor: pointer;
      }
    }
  }

  .body {

    .view-all-questions {
      padding-left: 15px;

      a {
        text-transform: none;

        i.ck.fa.ck-pagerback {
          margin-right: 4px;
          position: relative;
          bottom: 2px;
        }
      }
    }

    .body-content {
      overflow: auto;
      width: 100vw;
      background-color: $backgroundGrey;
      padding: 15px 17px 15px 17px;

      .thumbnail-container {

        .thumbnail {
          padding: 15px;

          &.offline {
            color: $lightText;
          }

          .student-info {
            height: 16px;
            margin-bottom: 5px;

            .name {
              margin: 2px 15px 0 0;
              outline: none;
              cursor: pointer;

              &:hover {
                color: $ckHeaderGrey;
              }
            }

            .md-button.peer-help-inbox-btn {
              color: $lightText;
              padding: 0;
              margin: -3px 0 0 0;
              max-width: 45px;
              min-width: 45px;
              height: 2.2rem;


              .ck-studenthelpers {
                margin: 1px 3px 0 0;
              }

              span {
                margin-top: 2px;
              }

              &.active {
                color: $ckGreen;
              }
            }

            .anon-tag {
              color: $lightText;
              margin: 1px 3px 0px 15px;
              background-color: $disabledGrey;
              border-radius: 2px;
              font-size: 6pt;
              min-width: 27px;
              padding: 3px 3px 2px 3px;
              cursor: pointer;
              outline: none;
            }

          }

          .md-button.work {
            @extend .md-button.square;

            margin: 0;
            padding: 0;
            color: $ckHeaderGrey;
            background-color: $disabledGrey;
            text-transform: none;
            height: 100%;
            width: 100%;

            &.selected-cell {
              border: 4px solid $ckGreenProgress;
            }

            &.hovering {
              border: 4px solid $ckClassroom;
            }

            assignment-sheet {
              height: inherit;
              width: inherit;
              margin: 0;
              background-color: $disabledGrey;

              svg {
                height: unset;
                pointer-events: none;
              }
            }

            &.started {
              background-color: white;

              assignment-sheet {
                background-color: white;
              }
            }

            .question-work-content {
              height: 0;
              max-height: 0;
              color: white;

              .question-answer {
                position: absolute;
                top: 0;
                min-width: 42px;
                height: 27px;
                margin: 4px;
                padding: 0 3px;

                span {
                  @extend .truncate;
                }
              }

              .question-answer.correct {
                color: $ckCharcoalGrey;
                background-color: $ckGradingMeets;
              }

              .question-answer.incorrect {
                color: $ckCharcoalGrey;
                background-color: $ckGradingBelow;
              }

              .question-answer.no-correct {
                color: white;
                background-color: $ckHeaderGrey;
              }

              .question-answer.hide {
                display: none;
              }

              .anchor {
                height: 0;
                width: 100%;

                .question-score {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  padding: 4px;
                  color: $ckHeaderGrey;

                  grade-input {
                    height: 2.6rem;
                    width: 120px;
                    background-color: white;
                    border-radius: 3px;
                  }
                }

                .md-button {
                  margin: 0;
                  z-index: 2;
                  position: absolute;
                  bottom: -8px;
                  right: 2px;

                  $handWidth: 45px;
                  $handHeight: $handWidth * 0.8;
                  width: $handWidth;
                  min-width: $handWidth;
                  height: $handHeight;
                  min-height: $handHeight;

                  box-shadow: $boxShadowCard;
                  border-radius: $handWidth $handWidth 0 0;
                  color: white;

                  i {
                    margin-bottom: 4px;
                  }
                  &.active-helper {
                    background-color: white;
                    i {
                      @extend .ck-studenthelpers;
                      color: $ckGreen;
                    }
                  }
                  &.help {
                    background-color: $ckYellow;
                  }
                  &.check {
                    background-color: $ckGreen;
                  }
                  &.chat {
                    background-color: white;
                    i {
                      @extend .ck-chat;
                      color: $ckGreen;
                    }
                  }
                }
              }
            }

            .current {
              position: absolute;
              top: 0;
              left: 0;
              box-sizing: border-box;
              width: 100%;
              height: 100%;
              border: 2px solid $ckHeaderGrey;
              pointer-events: none;
            }
          }
        }
      }

      .tip {
        @extend .section-header;

        height: 65px;
        box-sizing: border-box;

        a {
          cursor: pointer;
          text-transform: none;
        }

        a.class-code,
        a.show-students {
          margin-left: 0.5em;
          text-transform: uppercase;
        }
      }

      &.placing-sticker {
        $stickerCursor: url("../../../assets/images/cursors/quickfeedback.svg") 16 16, auto;

        cursor: $stickerCursor;

        .thumbnail .work {
          cursor: $stickerCursor;

          .md-button {
            cursor: $stickerCursor;
          }
        }
      }

      &.placing-stop {
        $closedEyeCursor: url("../../../assets/ckicon/closed-eye.svg") 16 16, auto;

        cursor: $closedEyeCursor;

        .thumbnail .work {
          cursor: $closedEyeCursor;

          .md-button {
            cursor: $closedEyeCursor;
          }
        }
      }
    }
  }
}
