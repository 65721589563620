.header-banner {
  position: relative !important;
  top: 0;
  left: 0;
  padding: 0 8px 0 29px;
  background-color: $azureBlue;
  color: $white;
  height: fit-content;
  width: 100%;
  position: absolute;
  z-index: 61;
  min-height: 7.2rem;

  .ck-warning {
    font-size: 1.7rem;
    margin-right: 22px;
  }

  .reminder {
    padding: 8px;

    .countdown-footer {
      font-family: $fontRoundedBold;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 4px;
    }

    a {
      text-transform: none;
      text-decoration: underline;
      color: $white;
      font-family: $fontRoundedMedium;
    }
  }

  .email-admin-white {
    margin: 8px 0;

    .ck-paper-airplane {
      font-size: 2.2rem;
      margin-right: 15px;
    }

    @extend .referral-button-assignment;
    @extend .white-button;
    padding: 0 1.4rem;
    margin-right: 19px;
  }

  .hide-banner {
    color: $white;
    min-width: auto;

    i {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 689px) {
    .email-admin-white {
      height: auto;
      margin-right: 40px;
    }

    .reminder {
      margin-right: 10px;
    }

    .hide-banner {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &.red {
    background-color: $ckWarn;

    .email-admin-white {

      &:hover,
      &:focus {
        background-color: $white;
        color: $ckWarn;
      }
    }

    @media screen and (max-width: 1364px) {
      .email-admin-white {
        height: auto;
        margin-right: 40px;
      }
    }
  }
}

.nav {

  md-sidenav,
  .main {
    min-height: 100vh;
    max-height: 100vh;
  }

  .shorten-height {
    max-height: calc(100vh - 72px);
    min-height: calc(100vh - 72px);
  }

  md-sidenav {
    &:not(.md-locked-open) {
      min-height: 100%;
      max-height: 100%;
    }

    box-shadow: $boxShadow;
    box-sizing: border-box;
    min-width: $teacherSidenavWidth;

    .header {
      border-bottom: 1px solid white;
      height: $teacherHeaderHeight;
      box-sizing: border-box;
      min-width: $teacherSidenavWidth;
      padding-left: 1.28rem;

      .logo {
        width: 40%;
      }
    }

    .notification-button {
      padding: 0.5rem;
      margin-top: 10px;
      margin-right: 20px;
      min-width: 2rem;
    }

    .notification-color{
      color: $ckHeaderGrey;
    }

    .green-bg {
      background: linear-gradient(96.98deg, $ckGreenProgress 0%, $ckGradientGreen 100%);
    }

    .tabs {
      overflow-y: auto;
    }

    .contract-admin {
      width: 100%;

      .tab-header {
        width: 100%;
        max-width: 100%;
        margin-top: 6px;
        padding: 1rem 2.4rem 0.5rem 2.4rem;
        box-sizing: border-box;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $ckHeaderGrey;
        font-size: $bodySub;
        font-family: $fontRoundedBook;
      }
    }

    .md-button.tab {
      display: inline-block;
      min-height: $teacherSidenavTabHeight;
      width: 100%;
      margin: 0;
      border-radius: 0;
      text-align: left;
      text-transform: none;
      color: $ckHeaderGrey;
      padding: 0 2.4rem;

      i {
        margin-right: 0.5em;
        text-align: center;
        min-width: 1em;

        &.ck-megaphone {
          margin: 4px 10px 0 3px;
        }

        &.ck-school-building {
          margin: 4px 10px 0 3px;
        }

        &.ck-pro {
          margin-right: 2px;
        }

        &.ck-metric {
          margin-right: 5px;
          margin-left: 4px;
        }
      }

      span {
        margin-top: 3px;
      }

      .md-ripple-container {
        border-radius: 0;
      }

      .indicator-circle {
        left: 0;
        bottom: 4px;
      }
    }

    .tab.active {
      background-color: $ckHeaderGrey;
      color: $white;
    }

    .tab.signout {
      margin-bottom: 1em;
    }

  }

  .main {
    @include flex(1, 1, auto);
    width: calc(100vw - #{$teacherSidenavWidth});
  }

  .admin-button {
    width: 100%
  }

  .universal-cta-container {
    padding: 1.8rem 1rem;

    .title,
    .body,
    .progress,
    .progress-label,
    .cta-button {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }

    .title {
      font-family: $fontRoundedMedium;
      font-size: 1.2rem;
      color: $ckHeaderGrey;
      font-weight: bold;
      margin-bottom: 1.2rem;
    }

    progress {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      border-radius: 48px;
      height: 1.5rem;
      margin: 1.2rem 0 .4rem 0;

      //IE10
      background-color: $disabledGrey;
      color: var(--progress-color);
    }

    //Safari and Chrome
    progress::-webkit-progress-bar {
      background-color: $disabledGrey;
      border-radius: 48px;
    }

    //Safari and Chrome
    progress::-webkit-progress-value {
      background-color: var(--progress-color);
      border-radius: 48px;
    }

    //Firefox
    progress::-moz-progress-bar {
      background-color: var(--progress-color);
      border-radius: 48px;
    }

    .cta-button {
      margin: 1.6rem 0 0 0;
      text-align: center;
      text-transform: uppercase;
      padding: 1.3rem 0;
      border-top: 2px solid $backgroundGrey;
      display: block;
      width: 100%;
      border-radius: 0px;
      height: auto;
    }
  }

  .universal-cta {
    border: 2px solid $backgroundGrey;
    padding: 1.6rem 0 0 0;
  }
}

.referral-toast {
  .md-toast-content {
    top: 70px;
    right: 16px;
    position: fixed;
    height: 60px;
    padding: 0 18px 0 10px;
    border-radius: 8px;
    font-weight: 400;
    color: $ckAltGrey !important;
    background-color: white !important;
    border-left: 8px solid $ckGreenProgress;
    font-family: $fontRoundedBold;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12), 0px 12px 16px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.08), 0px 24px 32px rgba(0, 0, 0, 0.04);

    button {
      color: $slate !important;
      padding: 0.2em 1.2em;

      &::after {
        content: url('../../../assets/ckicon/close-gray.svg');
        height: 14px;
        width: 14px;
        display: block;
        transform: scale(0.5);
        position: fixed;
        top: 19px;
        right: 32px;
      }
    }
  }
}

.copy-assignment-toast {
  @extend .referral-toast;

  .md-toast-content {
    bottom: 30px;
    right: 16px;
    top: auto;
  }
}

.order-toast {
  @extend .referral-toast;

  .md-toast-content {
    top: 88px;
  }
}

.cover-toast {
  @extend .referral-toast;

  .md-toast-content {
    top: 10px;
  }
}

.email-admin-toast {
  @extend .referral-toast;

  .md-toast-content {
    max-width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 24px;

    .md-action {
      margin-top: -15px;
    }
  }

  &.order-page {
    .md-toast-content {
      top: 88px;
    }
  }
}

/* Profile Button Styling (for string-based profile pic) */
.profile-btn-container {
  display: flex;
  align-items:center;
  gap: 10px;
  cursor: pointer;
  position: relative;
  margin-right: 170px;
}

.profile-pic {
  width: 0px; // Restore to 20px when making profile picture upload
  height: 0px; // Restore to 20px when making profile picture upload
  border-radius: 50%;
  background-color: #3498db;
  /* Choose any background color */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #ccc;
  text-transform: uppercase;
  /* Ensures initials are uppercase */
}

/* Dropdown Menu Styling */
.dropdown-menu {
  position: absolute;
  top: 110px;
  right: 90px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 100; 
}

.dropdown-menu:hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2); /* Slightly larger shadow on hover */
}

.inverted-carot {
  bottom: -1px;
  /* Position it just below the profile picture */
  right: -5px;
  /* Align it to the bottom right corner */
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 5px solid;
}

.carot-up {
  transform: rotate(180deg); /* Rotates the caret 180 degrees (up) */
}

.carot-down {
  transform: rotate(0deg); /* Default state (down) */
}

/* Profile Button Container */
.profile-btn-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  position: relative;
  padding-right: 170px; /* Changed to padding for responsive spacing */
  width: 100%; /* Container takes full width */
}

/* Greeting Styling */
.greeting {
  font-family: $fontRoundedBold;
  cursor: pointer;
  font-weight: 400;
  line-height: 2.4rem;
  font-size: 1.6rem;
  margin: 1.6rem 0;
  flex-grow: 1; /* Take up remaining horizontal space */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Add ellipsis if text is too long */
  min-width: 0; /* Ensure flex-grow works within container */
}

.greeting-container {
  display: flex;
  align-items: center;
  padding-left: 1.28rem;
}

.profile-type {
  padding: 1rem;
  border-radius: 8px; /* Rounded corners */
  color: white; /* White text for contrast */
  margin-left: 20px;
}

.green-background {
  background-color: #25C582; /* Green */
}

.purple-background {
  background-color: #9B59B6; /* Purple */
}
