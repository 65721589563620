.student-account-assignment-work {
  height: 100vh;

  .assignment-question-header {

    help-inbox {
      margin-left: 1em;
    }

    .feedback-inbox-button {
      @extend .bar-item;

      width: 75px;
    }

  }

  .error {
    text-align: center;
    font-size: 1.5em;
  }

  .loading {
    position: absolute;
    background-color: white;
    left: 0; top: 0; right: 0; bottom: 0;
    opacity: 60%;
  }
}
