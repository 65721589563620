.link-dialog {
  width: 700px;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
    margin-bottom: 45px;
  }

  form {
    height: 100%;
    width: 100%;
  }

  md-input-container {
    margin: 15px 130px 25px 130px;
  }

  .actions {
    padding: 4.5rem 2.4rem 2.4rem 2.4rem;

    .md-button {
      margin: 0 0 0 1.6rem;
    }
  }
}
