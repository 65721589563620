md-dialog.student-code-dialog {
  width: 410px;
  max-height: 95%;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
  }

  .body {
    border-top: none;

    md-input-container {
      width: 80%;
      margin: 16px 0 20px 0;
    }

    .actions {
      padding: 0 2.4rem 2.4rem 2.4rem;
      margin-top: 26px;

      .md-button {
        margin: 0 0 0 1.6rem;
      }
    }
  }
}
