.feedback-menu {
  max-height: 50vh;
  overflow: auto;
  font-size: $bodyCopy;

  md-list {
    background-color: white;

    md-list-item {
      border-bottom: 1px solid $backgroundGrey;
      font-family: $fontRoundedMedium;
      color: $ckHeaderGrey;

      .md-button {
        color: $ckHeaderGrey;
      }
    }

    md-list-item.feedback-item {
      span.message {
        height: 20px;
        font-family: $fontRoundedBook;
      }
      span.time {
        font-size: $bodySub;
        color: $lightText;
        font-family: GothamRoundedBook;
      }
      i {
        padding-left: 4em;
        font-size: 0.6em;
      }
    }

    md-list-item.title {
      padding: 0 16px;

      > * {
        padding: 0;
      }
    }

    md-list-item.current-helpers,
    md-list-item.current-helpers-item .md-button {
      color: $ckGreen;
    }

    md-list-item.empty-message {
      color: $lightText;
      font-family: $fontRoundedBook;
    }
  }
}

