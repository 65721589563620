$rosterEditColorCircleDiameter: 1.6rem;

.roster-edit {

  min-height: 100vh;
  max-height: 100vh;

  &.with-banner {
    min-height: calc(100vh - #{$teacherBanner});
    max-height: calc(100vh - #{$teacherBanner});
  }

  .header {

    .circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .classkick-header{
      flex: none;

      &.co-teaching-header {
        color: $ckOrange;
      }
    }
  }

  .roster-edit-view {
    padding: 0 2.3rem;
  }

  .nav-toggle {
    width: 99rem;
    margin-bottom: 2rem;
    position: sticky;
    .md-button {
      width: 33.3rem;
    }

    .co-teacher-pro {
      &.anchor {
        position: absolute;
        left: 50%;
        margin-left: 4.5rem;
        top: 0;
      }

      .pro-tag {
        padding: .3rem;
      }
    }

    &.co-teacher-nav {
      width: 66rem;
    }

    .background-border {
      width: inherit;
    }

    .background-border, .md-button, .md-button[disabled] {
      border-radius: 100px;
    }
  }

  .options-menu-button {
    height: inherit;
  }

  .clever-identifier {
    align-items: center;
    border: $borderGrey 1px solid;
    display: flex;
    height: 48px;
    margin-bottom: 16px;
    margin-left: 28px;
    width: 90%;
  }

  .blue-rectangle {
    background-color: $cleverBlue;
    height: 48px;
    width: 4px;
  }

  .clever-icon-div {
    height: 23px;
    margin: 0 20px 0 17px;
    width: 20px;
  }

  .clever-container{
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .mini-clever-icon {
    height: 1.3rem;
    width: 1.3rem;
  }

  .clever-icon {
    height: 18px;
    padding: 2.67px 3.84px 2.67px 2.83px;
    width: 17px;
  }

  .clever-message {
    font-family: $fontRoundedBook;
  }

  .disabled-edit {
    color: $disabledGrey;
    margin: 0 auto;
  }

  .metadata {
    padding: 1em 1em 0 1em;
    box-sizing: border-box;

    .co-teaching-message {
      text-align: center;
      font-family: $fontRoundedBook;
      color: $ckOrange;
    }

    .md-button.title,
    .title {
      font-size: $bodySub;
    }

    .name-and-color {
      padding: 4rem  1rem 2rem 1rem;

      .name {
        width: 50em;
        max-width: 70%;

        md-input-container {
          display:flex;
          flex-direction: column;
          width: 100%;
          margin-top: 0;
          padding: 0;

          .md-input {
            height: 4.2rem;
            margin: 0.5rem 0 0 0;
          }
        }

        md-input-container .md-errors-spacer {
          min-height: 0;
        }
      }

      .color {
        display:flex;
        flex-direction: column;

        .title {
          padding-left: 0.3rem;
        }

        .md-select-value{
          height: 4.2rem
        }

        md-input-container {
          margin: 0.5rem 1.6rem 0 0;
          padding-top: 0;

          &.color-selection {
            width: 6.4rem;
          }
        }

        .md-button {
          margin-top: 2px;
          margin-bottom: 0;
          min-width: $rosterEditColorCircleDiameter;
          width: calc(#{$rosterEditColorCircleDiameter} + 2em);
        }
      }
    }

    .configs {
      padding: 0 1em;

      .color,
      .peer,
      .new-members,
      .single-device {
        width: 15em;

        .md-button,
        md-checkbox {
          margin: 0;
        }
      }
    }

    md-checkbox {
      margin-top: 10px;
    }
  }

  .section-title {
    padding: 0 1.5rem;
    font-size: 2rem;
  }

  .student-list-section {
    md-list {
      overflow: auto;
    }

    .list-header,
    md-list-item {
      &.list-button {
        @extend .list-item;

        div.md-button:first-child{
          padding-left: 15px;
        }
      }

      &.edit,
      .edit {
        width: 60px;
        color: $ckHeaderGrey;
      }

      .col {
        margin: 0;
        &.name,
        &.email,
        &.status,
        &.account-type {
          text-overflow: ellipsis;
          min-width: 50px;

          md-input-container {
            margin: 0;

            .error-messages {
              max-height: 1rem;

              div {
                font-size: 0.7rem;
              }
            }
          }
        }

        &.helps{
          padding-left: 10px;
        }

        &.anon-user {
          color: $lightText;
        }

        &.tools,
        .tools {
          width: 60px;
        }

        &.checks,
        .checks,
        &.helps,
        .helps {
          @include flex-row(center, center, nowrap);

          min-width: 8.0rem;
          width: 8.0rem;

          span.check {
            color: $ckGreen;
          }

          span.help {
            color: $ckYellow;
          }

          span.request-carrot {
            @include flex-row(center, center, nowrap);
            margin: 0 0.2em;
            min-width: 1em;
            max-width: 1em;
            min-height: 1em;
            max-height: 1em;
          }

          .circle-green,
          .circle-yellow {
            border-radius: 50%;
            width: 35px;
            height: 35px;

            i.ck-hand {
              color: white;
            }
          }

          .circle-green {
            background-color: $ckYellow;
          }

          .circle-yellow {
            background-color: $ckGreen;
          }
        }

        .circle {
          min-width: 1em;
          min-height: 1em;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }

    md-list-item {
      .col,
      .md-input {
        font-family: $fontRoundedBook;
      }

      &.list-item.highlight {
        background-color: $highlightPrimary;
      }

      &.list-item md-input-container {
        padding-left: 0;
      }
    }

  }

  .error,
  .loading {
    text-align: center;

    .loading-text,
    .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }

  .roster-edit-content {
    overflow-y: auto;
  }

  .assignment-roster-section {
    margin-bottom: 2rem;
    border-bottom: 1px solid $dividerGrey;

    md-virtual-repeat-container {
      min-height: calc(100vh - 38.7rem);

      &.empty-list {
        min-height: calc(100vh - 38.7rem);
      }
    }

    .assignment-roster-list {
      @media screen and (max-width: 1224px)   {
        width: max-content;
      }

      .disabled {
        background-color: $dividerGrey;

        .col {
          color: $slate;
        }
      }
      .empty-assignment-message {
        text-align: center;
        font-size: 1.8rem;
        padding: 4rem;
      }
    }

    .bar-item.wide, .col.wide {
      min-width: 9.0rem;

      margin: 0;
      padding: 0;
    }

    .md-list-item-inner{
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 3em;
      overflow: hidden;
    }

    .md-button-text {
      font-family: $fontRoundedMedium;
      text-transform: uppercase;
    }

    .md-button.md-button-text {

      .contents {
        height: 13px;

        span {
          margin: 0 0.5rem 0 0;
        }
      }
    }

    md-list-item, .list-header {
      .col {
        margin: 0;

        &.wide {
          @include flex-row(center, center);
        }

        &.helps {
          @include flex-row(center, center);
          min-width: 6.0rem;
          width: 6.0rem;
        }

        &.hide-assignment, .hide-assignment,
        &.lock-assignment, .lock-assignment,
        &.show-grades, .show-grades,
        &.peer-helpers, .peer-helpers,
        &.allow-pdf, .allow-pdf {
          @include flex-row(center, center, nowrap);
          min-width: 8.4rem;
          cursor: pointer;

          .anchor {
            height: 0;
            width: 0;
            position: relative;

            .contextual-help {
              color: $ckHeaderGrey;
              margin-left: 0;
              margin-bottom: 8px;
              min-width: 24px;
              min-height: 24px;
              height: 24px;

              .ck-contextualhelp {
                margin-right: 1px;
              }

              &:hover {
                background-color: transparent;
              }
            }
          }

          ck-switch {
            label.switch {
              margin-right: 0;
            }
          }
        }

        &.assignment-name, .assignment-name {
          min-width: 3em;
          text-align: left;

          span {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .roster-circle {
          border-style: none;
        }

        &.roster-remove {
          margin-left: 0;
          padding-left: 0;
          width: 6.0rem;
        }

        &.export-grades {
          min-width: 16rem;
          width: 16rem
        }
      }

      div.md-button:first-child{
        padding-left: 16px !important;
      }
    }

    .new-ck-green-button {
      line-height: 2.8rem;
      font-family: $fontRoundedBold;
    }

    .export-grades-pro {
      &.anchor {
        position: absolute;
        margin-left: 13rem;
        top: 0.25rem;
      }

      .pro-tag {
        padding: .3rem;
      }
    }
}

  .co-teacher-section {
    margin-bottom: 2rem;
    border-bottom: 1px solid $dividerGrey;

    md-virtual-repeat-container {
      min-height: calc(100vh - 38.7rem);

      &.empty-list {
        min-height: calc(100vh - 38.7rem);
      }
    }

    .co-teacher {
      overflow-x: hidden;
    }

    .bar-item.wide, .col.wide {
      min-width: 9.0rem;

      margin: 0;
      padding: 0;
    }

    .md-list-item-inner{
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 3em;
      overflow: hidden;
    }

    .md-button-text {
      font-family: $fontRoundedMedium;
      text-transform: uppercase;
    }

    .md-button.md-button-text {

      .contents {
        height: 13px;

        span {
          margin: 0 0.5rem 0 0;
        }
      }
    }

    md-list-item, .list-header {
      .col {
        margin: 0;

        &.wide {
          @include flex-row(center, center);
        }

        .invite-co-teacher {
          min-width: 3em;
          text-align: left;

          span {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &.co-teacher-remove {
          margin-left: 0;
          padding-left: 0;
          width: 6.0rem;
        }
      }

      div.md-button:first-child{
        padding-left: 16px !important;
      }
    }

    md-list-item {
      .co-teacher-email, .last-accessed {
        font-family: $fontRoundedBook;
      }
    }
  }
}

.color-options {
  md-select-menu md-content{
    min-width: auto;
  }
}

.color-circle-selection {
  width: $rosterEditColorCircleDiameter;
  height: $rosterEditColorCircleDiameter;
  border-radius: 50%;
  margin: 0;
  outline: none;
}
