.feedback-indicator {
  color: $lightText;

  i.ck-notifications {
    margin-right: .3em;
  }

  .count {
    text-align: center;
    padding: 0 .5em;
    min-width: 2.5em;

    font-family: $fontRoundedMedium;
    line-height: 1.5em;
    border-radius:.75em;

    background-color: $backgroundGrey;
    color: $lightText;
  }

  &.active {
    i.ck-notifications {
      color: $ckHeaderGrey;
    }

    .count {
      background-color: $ckGreen;
      color: white;
    }
  }
}
