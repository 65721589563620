.help-request-indicator {

  color: $lightText;

  i.ck-hand {
    margin-right: 5px;
    color: $ckHeaderGrey;
  }

  .count {
    // FIXME: This was being overridden by teacher.scss `.md-list-item .col div`, preventing text-align
    // from behaving correctly. That css needs to be refined at some point
    display: block !important;
    text-align: center;
    padding: 0 .5em;
    min-width: 2.5em;

    font-family: $fontRoundedMedium;
    line-height: 1.5em;
    border-radius:.75em;

    background-color: $backgroundGrey;
    color: $lightText;
  }

  &.active {

    .count {
      background-color: $ckGreen;
      color: white;
    }
  }

  &.new-hand {
    i.ck-classkick-hand {
      font-size: 2rem;
      color: $ckAltGrey;
      margin-right: 13px;
    }

    .base-notification {
      width: 2.4rem;
      height:2.4rem;
      border-radius: 100px;
      min-width: auto;
      display: flex !important;
      justify-content: center;
      align-items: center;

      .total {
        font-size: 1rem;
        font-weight: bold;
      }

      &.red {
        background-color: $ckWarn;

        .total {
          color: white;
        }
      }

      &.gray {
        background-color: $disabledGrey;

        .total {
          color: gray;
        }
      }

    }
  }
}
