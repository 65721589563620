.user-notification-dialog {

  width: 550px;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
    margin-bottom: 24px;
  }

  .user-notification-text {
    font-family: $fontRoundedBook;
    text-align: center;
    margin: 0 60px 60px 60px;
  }

  .actions {
    padding: 0 2.4rem 2.4rem 2.4rem;
    margin-top: 26px;

    .md-button {
      margin: 0 0 0 1.6rem;
    }
  }
}

.md-alert-theme {
  h2:before {
    font-family: "ckicon";
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    content: "\e901";
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 1.2rem;
  }
  .md-dialog-content-body {
    text-align: center;
    color: #ff6c6c;
  }
}

