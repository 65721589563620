.anon-student-assignment-overview {
  @extend .student-account-assignment-overview;

  .assignment-question-header {
    .class-code-link {
      margin: 0 1em;
      padding-top: 1.75em;
      padding-left: 4.6em;
    }

    .username {
      color: $ckHeaderGrey;
    }
  }
}
