.hidden-teacher-rosters {
  min-height: calc(100vh);
  max-height: calc(100vh);

  .header {
    .search-input {
      margin-right: 0.8rem;
    }

    .saving-status {
      font-size: 1.3rem;
    }

    .back-button{
      width: 17.2rem;
      font-size: 1.4rem;
      line-height: 20px;
      color: $ckHeaderGrey;
      text-transform: inherit;
      padding: 0 1rem;
      border: 2px solid $ckHeaderGrey;
      height: 4.8rem;
      border-radius: 8px;
      margin-left: 8px;

      .md-ripple-container{
        border-radius: 8px;
      }

      .ck-back-arrow{
        margin-right: 1.4rem;
      }
    }
  }

  .md-menu {
    .md-button.md-fab {
      margin-left: 0.8rem;

      &:hover {
        margin-left: 0.8rem;
      }
    }
  }

  .list-header {
    .md-button {
      width: 100%;
    }

    .show-roster {
      text-transform: uppercase;
      font-size: 1rem;

      i {
        font-size: 1.5rem;
        margin-left: 1rem;
        color: $lightText;
      }
    }

    .sync {
      min-width:45px;
    }
  }

  md-list {
    min-height: $teacherListHeight;
    max-height: $teacherListHeight;
    overflow: auto;
  }

  md-list.with-banner {
    min-height: $teacherListHeightWithBanner;
    max-height: $teacherListHeightWithBanner;
  }

  .list-header, md-list-item {
    .col {
      min-width: 100px;

      &.checks,
      .checks,
      &.helps,
      &.roster-type,
      &.show-roster {
        @include flex-row(center, center, nowrap);

        min-width: 70px;
        width: 70px;

        span.check {
          color: $ckGreen;
        }

        span.request-carrot {
          @include flex-row(center, center, nowrap);
          margin: 0 0.2em;
          min-width: 1em;
          max-width: 1em;
          min-height: 1em;
          max-height: 1em;
        }

        .circle-green,
        .circle-yellow {
          border-radius: 50%;
          width: 35px;
          height: 35px;

          i.ck-hand {
            color: white;
          }
        }

        .circle-green {
          background-color: $ckYellow;
        }

        .circle-yellow {
          background-color: $ckGreen;
        }
      }

      &.tools,
      .tools {
        width: 70px;
        min-width: 70px;
      }

      .circle {
        min-width: 1em;
        min-height: 1em;
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .list-item {
    .md-button {
      .bar-item {
        min-width: 7rem;

        &.google, &.clever {
          display: flex;
          justify-content: center;
        }
      }
    }

    .show-checkbox {
      justify-content: center;
      align-items: center;

      md-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        .md-container {
          display: none;
        }
        .md-icon {
          display:none;
        }

        .md-label{
          margin-right: 25px;

          i {
            width: 2.4rem;
            height: 2.4rem;
            color: #b8babc;
            color: $lightText;
          }

          i.checkbox-icon::after {
            content: url('../../../assets/ckicon/empty-checkbox.svg');
          }

          i.checkbox-icon {
            &:hover::after {
              content: url('../../../assets/ckicon/hover-checkbox.svg');
            }

            &:active::after {
              content: url('../../../assets/ckicon/active-checkbox.svg');
            }
          }
        }

        &.md-checked {
          .md-label {
            i.checkbox-icon::after {
              content: url('../../../assets/ckicon/active-checkbox.svg');
            }

            i.checkbox-icon{
              &:hover::after {
                content: url('../../../assets/ckicon/active-checkbox.svg');
              }
            }
          }
        }
      }
    }

    .google-icon {
      width: 2.4rem;
      height: 2.4rem;
    }

    .clever-icon{
      width: 1.4rem;
      height: 1.6rem;
    }

    .delete-icon {
      color: $disabledGrey;
      margin: 0 auto;
    }
  }

  .error,
  .loading {
    text-align: center;

    .loading-text,
    .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }
}

.google-classroom-icon {
  height: 1.5em;
  width: 1.5em;
  margin-right: 0.9em;
}

.clever-delete-tooltip {
  height: auto;
  line-height: 1.4;
  max-width: 104px;
  padding: 5px 3px;
  text-align: center;
  white-space: normal;
}

.md-tooltip.show-tooltip {
  width: 250px;
  height: auto;
  white-space: normal;
  line-height: initial;
}
