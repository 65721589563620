.assignments-limit-paywall-dialog {

  max-height: 90%;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-body {
    width: 750px;
    padding: 0 60px 60px 60px;

    .ck-dialog-title {
      @extend .classkick-header;
      text-align: center;
      margin-bottom: 16px;
    }

    .top-message,
    .bottom-message {
      padding: 0 24px;
      font-family: $fontRoundedBook;
      text-align: center;
    }

    .top-message {
      margin-bottom: 24px;
    }

    .bottom-message {
      margin-top: 8px;
    }

    .actions {

      padding: 0 48px;

      .md-button {
        margin: 12px 0 0 0;

        .unlock-assignment-btn {
            width: 40rem;
        }
      }

      .pro-teacher-link {
        margin-top: 2.4rem;
      }

      .action {
        .incentive-btn {
          text-align: center;
        }

        .md-button {
          margin: 12px 0;
        }
      }
    }
  }
}
