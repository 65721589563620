.error-page {

  height: 100vh;

  .header {

    padding-left: 0;

    .logo {
      margin-left: 24px;
      height: 30px;
    }
    .logo:hover {
      cursor: pointer;
    }
    .logo:focus {
      outline: none;
    }
  }

  .body {
    background-color: $backgroundGreen;
    font-family: $fontRoundedBook;
    font-size: 1.8rem;
    text-align: center;

    img.bee {
      max-height: 250px;
      margin: 1em;
    }


    a {
      text-transform: none;
    }
  }

}
