saving-indicator {
  margin: 0 8px 0 8px;

  .saving-indicator {
    height: 100%;
    font-family: $fontRoundedBook;
    color: $lightText;

    div {
      margin-top: 2px;
    }
  }
}
