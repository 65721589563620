@import "variables";
@import "mixins";

@font-face {
  font-family: GothamRoundedMedium;
  src: url('../assets/fonts/GothamRnd-Medium.otf');
}

@font-face {
  font-family: GothamRoundedBook;
  src: url('../assets/fonts/GothamRnd-Book.otf');
}

@font-face {
  font-family: GothamRoundedLightItalic;
  src: url('../assets/fonts/GothamRnd-LightItal.otf');
}

@font-face {
  font-family: GothamRoundedBold;
  src: url('../assets/fonts/GothamRnd-Bold.otf');
}

@font-face {
  font-family: RobotoRegular;
  src: url('../assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: RobotoBold;
  src: url('../assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: LibreBaskervilleRegular;
  src: url('../assets/fonts/LibreBaskerville-Regular.otf');
}

@font-face {
  font-family: PTSerifRegular;
  src: url('../assets/fonts/PTSerif-Regular.otf');
}

@font-face {
  font-family: BebasNeueRegular;
  src: url('../assets/fonts/BebasNeue-Regular.otf');
}

@font-face {
  font-family: EBGaramondRegular;
  src: url('../assets/fonts/EBGaramond-Regular.otf');
}

@font-face {
  font-family: PacificoRegular;
  src: url('../assets/fonts/Pacifico-Regular.otf');
}

@font-face {
  font-family: CaveatRegular;
  src: url('../assets/fonts/Caveat-Regular.otf');
}

@font-face {
  font-family: IndieFlowerRegular;
  src: url('../assets/fonts/IndieFlower-Regular.otf');
}

@font-face {
  font-family: ComicNeueRegular;
  src: url('../assets/fonts/ComicNeue-Regular.otf');
}

@font-face {
  font-family: OpenSansRegular;
  src: url('../assets/fonts/OpenSans-Regular.otf');
}

@font-face {
  font-family: MontserratRegular;
  src: url('../assets/fonts/Montserrat-Regular.otf');
}

@font-face {
  font-family: LatoRegular;
  src: url('../assets/fonts/Lato-Regular.otf');
}

@font-face {
  font-family: CourierPrimeRegular;
  src: url('../assets/fonts/CourierPrime-Regular.otf');
}


html {
  // If standard browser font-size at 100% === 16px, then 62.5% === 10px
  // By setting this here, we can use "rem" units that are easier to convert to "px"
  // e.g. 1rem === 10px, 1.5rem === 15px, 2rem === 20px
  font-size: 62.5%;
}

body {
  height: 100%;
  overflow: hidden;
}

body, .normal-text {
  color: $ckHeaderGrey;
  font-family: $fontRoundedMedium;
  font-size: $bodyCopy;
}

.ck-green, .ck-primary {
  color: $ckGreen;
}

.ck-yellow {
  color: $ckYellow;
}

.ck-icon-grey {
  color: $ckHeaderGrey;
}

.ck-warn {
  color: $ckWarn;
}

.green-background {
  background-color: $ckGreen;
}

.hidden {
  display: none;
}

.full-width {
  min-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.full-height {
  height: 100%;
  margin-top: 0;
  margin-right: 0;
}

.vertical-scroll-container {
  height: 100vh;
  overflow: auto;
}

/* Brand Guidelines */

h1, .major-header {
  color: $ckHeaderGrey;
  font-size: 3.6rem;
  font-family: $fontRoundedMedium;
  font-weight: normal;
}

h2, .minor-header {
  color: $ckHeaderGrey;
  font-size: 2.8rem;
  font-weight: normal;
}

h3, .classkick-header {
  color: $ckHeaderGrey;
  font-size: 1.8rem;
  font-family: $fontRoundedMedium;
  font-weight: normal;
}

h4, .section-header {
  color: $ckHeaderGrey;
  font-size: 1.6rem;
  font-family: $fontRoundedMedium;
  font-weight: normal;
}

.body-subtext {
  color: $ckHeaderGrey;
  font-family: $fontRoundedBook;
  font-size: $bodySub;
}

.chat-message-text {
  font-size: 1.3rem;
  font-family: $fontRoundedBook;
  line-height: 1.4rem;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.indicator-circle {
  position: relative;
  width: 8px;
  min-width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $newCkGreen;

  &.yellow {
    background-color: $newCkYellow;
  }

  &.double-circle {
    background: linear-gradient(90deg, $ckYellow 50%, $newCkGreen 50%);
  }
}

.pro-tag {
  background-color: $ckPro;
  color: white;
  border-radius: .2rem;
  font-size: .75rem;
}

/*
 * Material Design skins
 */

.md-button {
  border-radius: 2em;
  padding: .2em 1em;
  font-family: $fontRoundedMedium;
  font-size: inherit;
  color: $ckGreen;
  line-height: 1;
  min-height: unset;
  height: 3.2rem;

  .md-ripple-container {
    border-radius: 2em;
  }
}

/** Styles for primary and secondary buttons **/
.md-button.ck-primary {
  background-color: $ckGreen;
  color: white;
  padding: .2em 2em;
}

.md-button.ck-primary:not([disabled]):hover,
.md-button.ck-primary:active,
.md-button.ck-primary:not([disabled]).md-focused {
  background-color: $ckGreenHover;
}

.md-button.ck-primary:disabled {
  color: $lightText;
  background-color: $disabledGrey;
}

.md-button.ck-secondary {
  border: 2px solid $ckHeaderGrey;
  background-color: white;
  color: $ckHeaderGrey;
  padding: .14em 2em;
}

.md-button.ck-gray-text {
  background-color: $ckGreen;
  color: $ckCharcoalGrey;
  padding: .2em 2em;
}

.md-button.ck-gray-text:not([disabled]):hover,
.md-button.ck-gray-text:active,
.md-button.ck-gray-text:not([disabled]).md-focused {
  background-color: $ckGreenHover;
}

.md-button.ck-secondary:not([disabled]):hover,
.md-button.ck-secondary:active,
.md-button.ck-secondary:not([disabled]).md-focused {
  background-color: $activeWhite;
}

.md-button.ck-secondary:disabled {
  border: 2px solid $disabledGrey;
  color: $disabledGrey;
}

.md-button.square {
  border-radius: 0;

  .md-ripple-container {
    border-radius: 0;
  }
}

/*
 * Anchors
 */
a {
  font-style: inherit;
  text-transform: uppercase;
  color: $ckGreen;
  font-family: $fontRoundedMedium;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.anchor-button {
  line-height: 13px;
  padding: 0 14px;
  margin: 6px 8px;
  text-align: center;
}

md-content {
  background-color: transparent;
  color: $ckHeaderGrey;
}

// Makes sure all tabs from Angular Material use Classkick Green
md-tabs md-ink-bar {
  color: $ckGreen;
  background-color: $ckGreen;
}

md-list {
  md-list-item .md-button {
    font-family: $fontRoundedBook;
  }

  md-list-item.list-button * {
    color: $ckGreen !important;
    font-family: $fontRoundedMedium !important;
    text-transform: uppercase !important;
  }
}

// Navigation toggle in assignment edit and session work/watch/question views
.nav-toggle {

  width: 332px;

  .md-button {
    margin: 20px 0;
    text-transform: none;
    min-width: 110px;
    width: 110px;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    color: $ckHeaderGrey;

    .md-ripple-container {
      border-radius: 2px;
    }
  }

  .background-border {
    position: absolute;
    border-radius: 4px;
    border: 2px solid $borderGrey;
    width: 332px;
    height: 3.2rem;
  }

  .md-button.left {
    border-radius: 4px 0 0 4px;
  }

  .md-button.middle {
    border-radius: 0;
  }

  .md-button.right {
    border-radius: 0 4px 4px 0;
  }

  .md-button[disabled] {
    border-radius: 4px;
    background-color: $ckHeaderGrey;
    border-color: $ckHeaderGrey;
    color: white;
  }
}

.ck-select-menu {
  md-select-menu {
    max-height: none;
    box-shadow: $boxShadowCard;

    md-content {
      max-height: 50vh;
      padding-top: 0;
      padding-bottom: 0;

      md-option {
        color: $ckHeaderGrey;
        font-family: $fontRoundedBook;

        &.secondary-action {
          font-family: $fontRoundedMedium;
          color: $ckGreen;
        }

        .help-request-indicator {
          margin-left: 5px;
        }
      }

      .divider {
        background-color: rgba(0,0,0,0.11);
        width: 100%;
        height: 1px;
        margin: 4px 0;
      }
    }
  }
}

md-select-menu:not(.md-overflow) md-content {
  padding: 0;
}

md-input-container {
  margin: 1.5rem 0 0 0;

  &.md-input-focused {
    .label {
      color: $ckGreen;
    }
  }

  &.md-input-invalid {
    .label {
      color: $ckWarn;
    }

    .md-input {
      border-color: $ckWarn;
    }
  }

  .label {
    font-size: $bodySub;
  }

  .error-messages .md-input-message-animation {
    font-size: $errorCopy;
    color: $ckWarn;
  }

  .md-input,
  .md-input.ng-invalid.ng-dirty,
  md-select .md-select-value,
  md-select .md-select-value.md-select-placeholder,
  md-select:not([disabled]):focus .md-select-value {
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    color: $ckHeaderGrey;
  }

  md-select:not([disabled]):focus .md-select-value {
    border-color: $ckGreen;
  }

  .md-input,
  md-select .md-select-value,
  md-select .md-select-value.md-select-placeholder {
    background-color: white;
    border-color: $borderGrey;
    height: 3.2rem;
    font-family: $fontRoundedBook;
    padding: 0 10px;
    text-align: left;
  }

  textarea.md-input {
    padding-top: 0.2rem !important;
    min-height: 3.2rem;
  }

  md-select .md-select-value,
  md-select .md-select-value.md-select-placeholder {
    text-transform: none;
    box-sizing: border-box;
  }

  .md-input:disabled {
    background-color: $disabledGrey;
    border: 2px solid $disabledGrey;
  }

  label:not(.md-no-float):not(._md-container-ignore), ._md-placeholder {
    overflow: visible;
  }

  label {
    margin: 0 10px;
    line-height: 1.6rem;
  }

  div.md-input-message-animation {
    text-transform: uppercase;
  }

  .md-errors-spacer {
    min-height: 0;
  }
}

md-input-container.md-input-resized .md-input,
md-input-container.md-input-focused .md-input {
  border-radius: 4px;
  border-width: 2px;
}

md-input-container:not(.md-input-invalid).md-input-focused .md-input {
  border-color: $ckGreen;
}

a.md-button.md-default-theme.md-raised[disabled],
a.md-button.md-raised[disabled],
a.md-button.md-default-theme.md-fab[disabled],
a.md-button.md-fab[disabled],
.md-button.md-default-theme.md-raised[disabled],
.md-button.md-raised[disabled],
.md-button.md-default-theme.md-fab[disabled],
.md-button.md-fab[disabled] {
  background-color: $disabledGrey;
}

md-checkbox {
  font-family: $fontRoundedMedium;
  font-size: $bodySub;

  .md-icon {
    transform: scale(0.7);
  }

  .md-label {
    height: 1.3rem;
    margin-top: 0.3rem;
  }
}

md-toast {
  font-family: $fontRoundedBook;
}

md-dialog {
  color: $ckHeaderGrey;
  box-shadow: $boxShadowCard;
}

.md-open-menu-container {
  box-shadow: $boxShadowCard;

  md-menu-content {
    padding: 0;

    md-menu-item .md-button {
      color: $ckHeaderGrey;
      font-size: inherit;
      font-family: $fontRoundedBook;

      &[disabled] {
        color: $disabledGrey;
      }

      &.disabled span {
        color: $ckHeaderGrey;
        opacity: .38;
      }

      .menu-item-content {
        height: 100%;

        i {
          margin-right: 12px;
          text-align: center;
          width: 24px;
        }

        i.ck-folder {
          height: 15.43px;
        }

        span {
          line-height: 100%;
        }
      }
    }

    .anchor {
      position: relative;
      width: 0;
      height: 0;
      display: block;
    }

    .pro-tag {
      @extend .pro-tag;
      position: absolute;
      padding: 3px;
    }
  }
}

.md-menu-content .container md-menu-item.half {
  display: inline-block;
  width: 50%;
}

.md-dialog-content .error-dialog-message, .spinner-dialog-message {
  white-space: pre;
}

/*
 * Additional styles for Material design buttons and nav/toolbar items
 */
.bar-item {
  width: 60px;
}

.bar-item, .md-button.bar-item {
  .contents {
    @include flex-row(center, center);
    height: 100%;
  }

  height: 100%;
}

div.bar-item {
  @include flex-row(center, center);
}

.md-button.bar-item {
  @extend .md-button.square;

  min-width: 3em;
  margin: 0;
  color: $ckHeaderGrey;
}

.md-button.bar-item.ck-green {
  color: $ckGreen;
}

.md-button.bar-item:disabled {
  color: $disabledGrey;
}

a.bar-item {
  padding: 0;
}

.md-button.text {
  padding: .5em 1em;
  width: auto;
}

.md-button.contextual-help {
  min-width: 32px;
  min-height: 32px;
  padding: .2em .2em;
  margin-left: 0.1rem;

  .ck.fa.ck-contextualhelp {
    font-size: $bodyCopy;
  }
}

.no-margin {
  margin: 0;
}

span.roster-circle {
  min-width: 1em;
  min-height: 1em;
  border-radius: .5em;
  border-style: solid;
  border-width: 1px;
  border-color: $dividerGrey;
  margin-right: 10px;
  display: inline-block;
}

.md-autocomplete-suggestions-container,
.md-virtual-repeat-container {
  &.ck-autocomplete-container {
    margin-top: 1.5rem;
    height: unset;
    box-shadow: $boxShadowCard;
    font-family: $fontRoundedBook;

    ul li {
      color: $ckHeaderGrey;
    }
  }

  &.ck-join-organization-autocomplete-container {
    @extend .ck-autocomplete-container;

    ul li {
      padding: 0 10px;
      height: 60px;
      line-height: 1;

      .organization-content {
        height: 60px;
      }

      .organization-secondary-row {
        margin-top: 7px;
      }

      .organization-name {
        font-family: $fontRoundedMedium;
      }

      .organization-location, .organization-members {
        font-size: $bodySub;
        color: $lightText;
      }

      .organization-members {
        margin-left: 8px;
      }
    }

    ul li:last-child {
      padding: 0;
      height: 48px;
      line-height: 48px;

      .add-organization {
        outline: none;
        padding: 0 10px;
        height: 48px;
        min-height: 48px;
      }
    }
  }

  &.ck-search-articles-autocomplete-container {
    height: unset;
    box-shadow: $boxShadowCard;
    font-family: $fontRoundedBook;

    ul li {
      color: $ckHeaderGrey;
      padding: 0 10px;
      height: 48px;
      line-height: 1;

      .article-content {
        height: 48px;
      }

      .article-name {
        font-family: $fontRoundedMedium;
      }

      .article-members {
        font-size: $bodySub;
        color: $lightText;
        margin-left: 8px;
      }
    }

    ul li:last-child {
      .ask-anything {
        outline: none;
        padding: 0 10px;
        height: 48px;
        min-height: 48px;
      }
    }
  }
}

md-autocomplete {

  md-progress-linear {
    display: none;
  }

  .loading-spinner {
    position: relative;
    height: 0;
    width: 97%;
    bottom: 23px;
    text-align: right;
  }

  md-input-container {
    margin-top: 16px;
    padding: 0;

    .label {
      position: absolute;
      left: 0;
      top: -15px;
    }

    label {
      display: none;
    }
  }
}

md-tooltip.ck-autocomplete-tooltip {
  margin-top: 0.7rem
}

.md-tooltip {
  font-size: $bodySub;
  height: unset;

  &.md-show {
    opacity: 0.95;
  }

  .title, .body {
    line-height: 1.4;
  }

  .title {
    margin-top: 4px;
  }

  .body {
    font-family: $fontRoundedBook;
    margin: 4px 0 6px 0;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

md-progress-linear .md-container.md-mode-indeterminate .md-bar {
  background-color: $ckGreen;
}

.search-input {

  .anchor {
    position: relative;
    height: 0;
    width: 0;
  }

  .ck.fa.ck-search {
    position: absolute;
    top: 1.2rem;
    left: 1.3rem;
    z-index: 1;
  }

  md-input-container {
    margin: 0 0;

    input {
      padding-left: 3rem;
      height: 3.2rem;
    }

    input::placeholder {
      position: relative;
      top: 0.1rem;
    }
  }
}

/*
 * Animations
 */

.ng-hide-add {
  display: block;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* IE/Edge */
  user-select: none;           /* non-prefixed version, currently
                                  not supported by any browser */
}

.enter-right.ng-hide-remove {
  animation: fadeInRight .3s;
}
.enter-right.ng-hide-add {
  animation: fadeOutRight .3s;
}
.enter-right.ng-enter {
  animation: fadeInRight .3s;
}
.enter-right.ng-leave {
  animation: fadeOutRight .3s;
}

.enter-up.ng-hide-remove {
  animation: fadeInUp .3s;
}
.enter-up.ng-hide-add {
  animation: fadeOutDown .9s;
}
.enter-up.ng-enter {
  animation: fadeInUp .3s;
}
.enter-up.ng-leave {
  animation: fadeOutDown .9s;
}

.enter-fade.ng-hide-remove {
  animation: fadeIn .5s;
}
.enter-fade.ng-hide-add {
  animation: fadeOut .5s;
}
.enter-fade.ng-enter {
  animation: fadeIn .5s;
}
.enter-fade.ng-leave {
  animation: fadeOut .5s;
}

.ck-pulse {
  animation: ckPulse 1s;
}

.ck-pulse-infinite {
  animation: ckPulse 3s infinite;
}

@keyframes ckPulse {
  0% {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px 0px;
  }
  50% {
    box-shadow: $ckGreen 0px 0px 10px 4px, $ckGreen 0px 0px 10px 4px;
  }
  100% {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px 0px;
  }
}

.rectangle-button {
  display:flex;
  justify-content: space-between;
  border-radius: 8px;
  height: 4.8rem;
  margin: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.04);
  font-size: 1.4rem;
  font-weight: 400;
  font-family: GothamRoundedBold, "Varela Round", sans-serif;
  letter-spacing: normal;
  line-height: 2.4rem;
  white-space: normal;
  text-transform: none;
  padding: 0 1.5rem;

  .md-ripple-container {
    border-radius: 8px;
    background-color: transparent;
  }
}

.referral-button-assignment {
  @extend .rectangle-button;
  color: $ckAltGrey;
  background-color: $ckGreen;

  &:hover, &:focus {
    background-color: $lightHover;
  }
}

.blue-button {
  background-color: $azureBlue;
  color: $white;

  .md-ripple-container {
    border-radius: 8px;
    background-color: transparent;
  }

  &:hover, &:focus {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), $azureBlue;
  }

  &:active {
    background-color: $ckAltGrey;
  }
}

.white-button {
  background-color: $white;
  color: $ckAltGrey;

  .md-ripple-container {
    border-radius: 8px;
    background-color: transparent;
  }

  &:hover, &:focus {
    background-color: $white;
    color: $ckGreenProgress;
  }

  &:active {
    background-color: $ckAltGrey;
    color: $white;
  }
}


.green-button {
    background-color: $ckGreen;
    color: $ckAltGrey;

    .md-ripple-container {
      border-radius: 8px;
      background-color: transparent;
    }

    &:hover, &:focus {
      background-color: $white;
      color: $ckGreenProgress;
    }

    &:active {
      background-color: $ckAltGrey;
      color: $white;
    }
  }


.new-ck-green-button {
  background-color: $newCkGreen;
  color: $white;
  font-family: $fontRoundedBold;

  .md-ripple-container {
    border-radius: 100px;
    background-color: transparent;
  }

  &:not([disabled]) {
    &:hover {
      background-image: linear-gradient(rgba(255, 255, 255, .1), rgba(255, 255, 255, .1));
      box-shadow: 0 4px 4px -3px rgba(0, 0, 0, .15);
      background-color: $newCkGreen;
    }
    &:active, &:focus{
      background-color: $darkGrey;
      color: $white;
    }
  }
}

.new-ck-white-button {
  background-color: $white;
  color: $newCkGreen;
  font-family: $fontRoundedBold;

  .md-ripple-container {
    border-radius: 100px;
    background-color: transparent;
  }

  &:not([disabled]) {
    &:hover {
      background-image: linear-gradient(rgba(255, 255, 255, .1), rgba(255, 255, 255, .1));
      background-color: $white;
      color: $newCkGreen;
    }
    &:active, &:focus{
      background-color: $darkGrey;
      color: $white;
    }
  }
}

md-backdrop.md-opaque {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.25) 100%);
}

.md-autocomplete-suggestions-container {
  height: unset !important;
}

.ck-checkbox-2023 {
  .md-icon {
      transform: scale(1.2);
      border-radius: 8px;
      border: 1px solid $slate;
  }

  &.md-checked .md-icon {
      background-color: $ckGreenProgress;
      border: 1px solid $ckGreenProgress;

      &:after {
          margin-left: 2px;
          margin-top: 1px;
          transform: rotate(29deg);
          border-radius: 1px;
          border-width: 2.5px;
          left: 3.5px;
      }
  }
}

.flex-prop {
  flex: 1 1;
  box-sizing: border-box;
}

.layout-column > .flex-prop {
  min-height: 0;
}

.layout-row > .flex-prop {
  min-width: 0;
}
