md-dialog.share-dialog {
  min-height: unset;
  min-width: unset;
  max-width: 920px;
  max-height: 100vh;

  .select-share-recipient {

    .ck-dialog-header {
      height: $teacherHeaderHeight;

      .ck-close {
        color: $ckGreen;
      }
    }

    .ck-dialog-title {
      @extend .classkick-header;
      text-align: center;
      margin: 0 24px;
    }

    .share-options {

      margin: 6rem;

      .share-option-row {
        margin: 0;
        padding: 20px;
        border-radius: 20px;
        box-shadow: $boxShadowCard;
        cursor: pointer;
        outline: none;

        img {
          width: 90px;
          height: 90px;
        }

        div.title {
          color: $ckGreen;
          margin: 15px 0 0 0;
          text-align: center;
        }
      }

      .spacer {
        width: 24px;
        height: 24px;
      }

    }
  }

  .share-with-teacher {

    width: 660px;
    height: 550px;

    .ck-dialog-header {
      height: 40px;

      .ck-close {
        color: $ckGreen;
      }
    }

    .ck-dialog-title {
      @extend .classkick-header;
      height: 25px;
      font-weight: 600;
      text-align: center;
      padding: 0 1rem 0rem 1rem;
    }


    .ck-dialog-subtitle {
      @extend .classkick-header;
      font-size: 0.9em; 
      font-weight: 400;
      text-align: center;
      padding: 0 1rem 0 1rem;
      margin: 0 auto;

      .highlight {
        font-weight: 600;
      }

      .ck-folder {
        padding-bottom: 3px;
      }
    }


    .tab-label {
      font-size: $bodyCopy;
      text-transform: none;
    }

    .md-active .tab-label {
      color: $ckGreen;
    }

    md-tab-content {
      width: auto;
      padding: 3.0rem 3.0rem 3.0rem 3.0rem;
      background-color: $white;

      .label {
        font-size: $bodySub;
        padding-top: 20px;
      }

      .share-preview {
        background-color: $backgroundGrey;
        padding: 30px 10px 10px 30px;
        height: 180px;

        .preview-button {
          text-align: center;
          margin-top: 1em;
          margin-bottom: 1em;

          img {
            outline: none;
          }
          img:hover {
            cursor: pointer;
          }
        }
      }

      .email-input {
        width: 100%;
        margin-top: 6px;
      }

      .link-input {
        margin-top: 15px;
      }

      .email-input, .link-input {
        width: 100%;
        position: relative;

        .submit-button {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 2px;
          margin-left: 30px;
        }

        .spinner {
          text-align: center;
        }

        .readonly-link {
          border: 1px solid $borderGrey;
          border-radius: 5px;
          padding: 12px;
          font-size: 1.2rem;
          width: 450px;
          text-overflow: ellipsis;
        }

        md-input-container {
          margin: 0 0 15px 0;
          padding-left: 0;
          padding-top: 0;

          input, textarea {
            border: 1px solid $borderGrey;
            border-radius: 5px;
            padding: 12px;
            font-size: 1.2rem;
            width: 500px;

            &:focus {
              border: 2px solid $ckGreenProgress;
            }

            &::placeholder {
              color: $lightText;
            }
          }
        }
      }

      .social-media {
        height: 3em;
      }
    }
  }

  .share-work-dialog-no-mdr {
    height: 300px;
  }

  .share-work-dialog {
    @extend .share-with-teacher;

    .ck-dialog-title {
      width: 85%;
      text-align: left;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2.5rem;
      padding: 0 3.5rem 0 3.5rem;
    }

    md-ink-bar {
      color: $white;
      background-color: $white;
    }

    md-tab-content {
      width: auto;

      .share-preview {
        margin-top: 2rem;
        font-size: 1.5rem;
      }
    }
  }

  .email-success {
    text-align: center;
    padding: 0 2.5rem 3.5rem 2.5rem;
    width: 550px;

    .ck-dialog-header {
      height: $teacherHeaderHeight;

      .ck-close {
        color: $ckGreen;
      }
    }

    .ck-dialog-title {
      @extend .classkick-header;
      text-align: center;
      padding: 0 60px;
    }

    i.ck-check {
      margin: 60px 0;
    }

    .email-success-message {
      @extend .classkick-header;
      font-family: $fontRoundedBook;
    }

    a {
      margin-top: 16px;
    }
  }

  .loading {

    .ck-dialog-body {
      overflow: hidden;
      padding: 0;

      .message {
        @extend .classkick-header;
        @extend .truncate;
        text-align: center;
        padding: 60px;
      }

      .spinner {
        margin: 60px 0 0 0;
      }
    }
  }

  .share-footer {
    position: fixed;
    height: 50px;
    background-color: $white;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
  }
}
