.assignment-edit-question {

  height: 100vh;

  .assignment-question-header {

    .classkick-header {
      color: $ckHeaderGrey;
    }

    .spacer {
      display: flex;
      width: fit-content;
      align-items: center;
      justify-content: flex-end;
    }

    .student-preview {
      color: $ckAltGrey;
      padding: 12px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      background-color: $veryLightGrey;
      border-radius: 8px;
      height: fit-content;

      &:hover {
        text-decoration: none;
        background-color: $ckAltGrey;
        color: $white;
      }

      .ck-roster {
        margin-right: 12px;
        font-size: 1.6rem;
      }

      .ck-play {
        margin-left: 12px;
        padding: 7px;
        font-size: 1.2rem;
      }

    }
  }

  .error {
    text-align: center;
    font-size: 1.5em;
  }
}
