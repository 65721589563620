.free-trial-dialog {
  min-height: 364px;
  padding: 72px 72px 24px;
  position: relative;
  width: 600px;

  h2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 40px;
    text-align: center;
  }

  h3, p {
    font-size: 13px;
    line-height: 20px;
  }

  .close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 30px;
    height: 30px;
    color: $ckGreen;
  }

  .ck-primary {
    position: absolute;
    bottom: 24px;
    right: 24px;
  }

  .loading {
    text-align: center;
  }

  .loading-spinner {
    display: block;
    font-size: 48px;
    position: relative;
    text-align: center;
    top: 16px;
  }
}

@media (max-width: 750px) {
  .free-trial-dialog {
    width: auto;
  }
}
