grade-input .grade {
  height: 100%;
  border-color: $disabledGrey;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  outline: none;
  font-family: $fontRoundedBook;

  input {
    min-width: 1em;
    width: 1em;
    border: none;
    text-align: center;
    outline: none;
    font-family: $fontRoundedBook;
    font-size: inherit;
    color: inherit;
    background-color: inherit;
    padding: 0;
    margin-right: 0.1em;
    -moz-appearance: textfield;
  }

  @include color-placeholder(inherit);
  @include hide-focused-placeholder();

  .nofocus {
    pointer-events: none;
  }

  .hidden-grade-span {
    display: block;
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }
}
