
$colorMenuCircleDiameter: 18px;
$colorMenuItemWidth: calc(#{$colorMenuCircleDiameter} * 2);

.ck-color-menu,
.ck-color-menu-wide {
  width: calc(#{$colorMenuItemWidth} * 2);
  @include flex-row(flex-start, flex-start, wrap);
  box-shadow: $boxShadow;
  background-color: white;
  border-radius: 4px;

  .md-button.color-item {
    padding: 0;
    margin: 0;
    border-radius: 0;
    min-width: $colorMenuItemWidth;
    max-width: $colorMenuItemWidth;
    min-height: $colorMenuItemWidth;
    max-height: $colorMenuItemWidth;

    .contents {
      @include flex-row(center, center, wrap);
    }

    .circle {
      min-width: $colorMenuCircleDiameter;
      max-width: $colorMenuCircleDiameter;
      min-height: $colorMenuCircleDiameter;
      max-height: $colorMenuCircleDiameter;
      border-radius: 50%;
    }
  }
}

.ck-color-menu-wide {
  width: calc(#{$colorMenuItemWidth} * 4);
}


