.error-dialog {
  text-align: center;

  .ck-dialog-header {
    height: $teacherHeaderHeight;
  }

  .ck-dialog-body {

    .icon-body {
      height: 65px;
      margin-bottom: 24px;
    }

    .primary-message {
      @extend .classkick-header;
      padding: 0 60px;
      margin-bottom: 24px;
    }

    .secondary-message {
      @extend .classkick-header;
      padding: 0 60px;
      margin-bottom: 24px;
      font-family: $fontRoundedBook;
    }

    .actions {
      padding: 0 2.4rem 2.4rem 2.4rem;
      margin-top: 26px;

      .md-button {
        margin: 0 0 0 1.6rem;
      }
    }

  }
}
