.clever-tooltip {
  height: auto;
  line-height: 1.4;
  max-width: 187px;
  padding: 13px 20px 10.29px;
  text-align: center;
  white-space: normal;
}

.clever-option-subheader {
  font-size: 10px;
  color: $ckHeaderGrey;
}

.contract-users-list {
  height: 100vh;

  .header {
    md-select {
      width: 12em;
    }
  }

  .role-filter {
    margin-left: 1em;
  }

  .bulk-actions {
    min-width: 127px;
  }

  .clever-icon-container {
    height: 16px;
    margin: 0 auto;
    width: 16px;

    &:hover {
      background-color: $white;
    }
  }

  .clever-icon {
    height: 10.66px;
    padding: 2.67px 3.84px 2.67px 2.83px;
    width: 9.33px;
  }

  .contract-overview {
    .contract-overview-header {
      margin: 1em;
    }

    .contract-overview-list {
      list-style: none;
      margin-left: 18px;
      padding: 0;
    }
  }

  .contract-options {
    margin: 1.3em 1.8em;
    justify-content: space-between;

    .contract-organization {
      md-select {
        width: 15.7em;
      }
    }

    .search-input {
      margin: 1.2em 0 0 1em;
    }
  }

  .list-header,
  md-list-item {
    .checkbox-header {
      width: 24px;
      margin-left: 3px;
      margin-right: 29px;
      margin-top: 16px;
    }

    .col {
      &.first-name,
      &.last-name {
        min-width: 100px;
      }

      &.role {
        min-width: 80px;
      }

      &.email {
        display: flex;
        min-width: 150px;
      }

      &.verified,
      &.clever {
        width: 60px;
      }

      .tools,
      &.tools {
        width: 60px;
      }

      &.tools:not([disabled]):hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }

  md-list-item {
    .col {
      &.clever {
        width: 105px;
        justify-content: flex-start;
      }

      &.tools {
        width: 77px;
      }
    }
  }

  .list-header {
    .role,
    .verified {
      padding-left: 5px;
    }
    .email-header{
      min-width: 150px;
    }
  }

  .error,
  .loading {
    text-align: center;

    .loading-text,
    .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }

  md-virtual-repeat-container {
    // Teacher list height minus the margin of the contract options container at the top of the page.
    min-height: calc(#{$teacherListHeight} - 2.6em);
    max-height: calc(#{$teacherListHeight} - 2.6em);
    overflow: auto;
  }

  md-virtual-repeat-container.with-banner {
    min-height: calc(#{$teacherListHeightWithBanner} - 6em);
    max-height: calc(#{$teacherListHeightWithBanner} - 6em);
  }

  .single-select-checkbox {
    margin-left: 3px;
    margin-right: 23px;
    margin-top: 15px;

    md-checkbox[disabled]:not(.md-checked) .md-icon {
      border-color: $disabledGrey;
    }

    md-checkbox.md-checked .md-icon {
      background: $ckGreen;
    }

  }
}
