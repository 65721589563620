.payment-success {
  height: 100vh;
  overflow: auto;

  .header {
    border-bottom: none;
  }

  .body-header {
    margin-bottom: 20px;
  }

  .body {
    width: 500px;
    padding: 10px;

    .date-created {
      margin-bottom: 8px;
    }

    .total {
      margin-top: 8px;
    }

    .line {
      height: 2px;
      background-color: $dividerGrey;
      margin: 16px 0;
    }

    .order-details {
      font-family: $fontRoundedBook;
      margin-bottom: 16px;

      span {
        font-family: $fontRoundedMedium;
      }
    }

    .print {
      font-family: $fontRoundedBook;
    }

    .resources {
      margin: 32px 0 32px 0;

      .md-button.resource {
        min-height: 210px;
        width: 225px;
        margin: 5px 0;
        text-align: center;
        box-shadow: $boxShadowCard;

        img {
          margin: 1em auto;
          width: 12rem;
        }

        div {
          text-transform: none;
        }
      }
    }

    .actions {
      margin-top: 16px;

      .md-button {
        margin: 0;
      }
    }

  }
}
