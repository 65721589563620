.feature-dialog {

  max-height: unset;
  box-shadow: $boxShadowCard;
  background-color: white;

  .feature-dialog-header {
    height: 48px;

    .bar-item {

      width: 48px;

      .ck-close {
        color: $ckGreen;
      }
    }
  }

  .feature-dialog-title {
    padding: 8px 0 16px 0;
    @extend .classkick-header;
  }

  .feature-dialog-image-container {

    .feature-dialog-image {
      margin: 0 56px;

      img {
        border: 2px solid $backgroundGrey;
        width: 100%;
      }
    }

  }

  .feature-dialog-footer {
    height: 48px;
  }

}
