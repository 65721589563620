.public-assignment-question {

  height: 100vh;

  .assignment-question-header {

    i.ck.fa.ck-youtube {
      margin-right: 0.5em;
    }

    i.ck.fa.ck-help {
      font-size: 1.5em;
    }

    .classkick-header {
      margin: 0 auto;
    }

    .use-assignment {
      margin-left: 3px;
    }
  }

  .loading, .error {
    text-align: center;

    .loading-text, .error-text {
      padding: 2em;
      font-family: $fontRoundedBook;
    }
  }
}
