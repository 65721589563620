.incentive-assignment-limit-v1-dialog {
  min-width: unset;
  width: 600px;
  border-radius: 5px;
  max-height: 90vh;

  .ck-dialog-body {
    min-height: 300px;
    overflow-x: hidden;
  }

  md-checkbox {
    .md-icon {
      transform: scale(1.2);
      border: 1px solid $slate;
    }

    &.md-checked .md-icon {
      background-color: $ckGreenProgress;
      border: 1px solid $ckGreenProgress;

      &:after {
        margin-left: 2px;
        margin-top: 1px;
        transform: rotate(29deg);
        border-radius: 1px;
        border-width: 2.5px;
        left: 3.5px;
      }
    }
  }

  .incentive-assignment-limit-v1-email {

    .incentive-assignment-limit-v1 {
      min-height: 48px;
      margin: 0.5rem 0 0;
      color: $ckAltGrey;

      .incentive-assignment-limit-v1-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 0 32px;

        div {
          min-height: 48px;
        }

        .close {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;

          &:hover {
            i {
              color: $ckGreenProgress;
            }
          }

          i {
            color: $ckAltGrey;
            font-size: 12px;
            font-weight: 800;
          }
        }

        .title {
          display: flex;
          align-items: center;
          font-size: 21px;
          font-family: $fontRoundedBold;
          text-align: center;
          max-width: 43rem;
        }
      }
    }

    .invite-toggle {
      font-size: 1.1rem;
      font-weight: 100;
      color: $ckHeaderGrey;
    }

    md-virtual-repeat-container {
      height: 150px;
    //   height: calc(100vh - 50rem);
      width: 535px;

      md-list {
        background: white;
        color: $ckHeaderGrey;
        padding: 0 0 0 0;

        md-list-item {
          box-sizing: border-box;
          font-family: $fontRoundedBook;
        //   width: 250px;
          min-height: 10px;
          height: 30px;
        }
        md-list-item-inner {
          min-height: 30px;
        }
      }
      border: 1px solid $borderGrey;
      border-radius: 5px;

      md-checkbox {
        .md-icon {
          transform: scale(0.8);
          border: 1px solid $slate;
        }

        &.md-checked .md-icon {
          background-color: $ckGreenProgress;
          border: 1px solid $ckGreenProgress;

          &:after {
            margin-left: 2px;
            margin-top: 1px;
            transform: rotate(29deg);
            border-radius: 1px;
            border-width: 2.5px;
            left: 3.5px;
          }
        }
      }

      .peers-list {
        background-color: transparent;

        .loading-spinner {
          display: flex;
          justify-content: center;
          font-size: 35px;
          margin: 8%;
        }

        .peer-checkbox {
          margin-right: 10px;
        }

        .checkbox-label {
          font-size: 0.8em;
          font-family: $fontRoundedBook;
          font-weight: 600;
          color: $ckAltGrey;
        }
      }
    }

    md-list-item md-checkbox {
      margin-right: 1em;
      margin-left: -2px;
    }

    .incentive {
      margin: 0 32px 32px 32px;

      .error-messages {

        @media screen and (max-width: 750px) {
          margin-left: 0px;
          left: 0px;
        }
      }

      .info {
        font-weight: bold;
        border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        display: flex;
        margin: 0px;
        color: $white;

        .announcement:after {
          position: relative;
          top: -4px;
          content: url('../../../assets/ckicon/announcement.svg');
        }

        .info-icon {
          font-size: 18px;
        }

        .info-title {
          color: $lightText;
          font-family: $fontRoundedMedium;
          font-size: 1.3rem;
          font-weight: 300;
          text-align: center;
        }

        .info-subtitle {
          display: flex;
          flex-direction: column;
          background-color: $referralGreen;
          margin-top: 20px;
          margin-bottom: 10px;
          color: $ckGreen;
          font-family: $fontRoundedMedium;
          font-size: 1.3rem;
          font-weight: 600;
          padding: 5px 5px 5px 5px;
          width: 50%;

          .incentive-banner-title {
            display: flex;
            font-size: 1.8rem;
            text-transform: uppercase;
            padding: 12px 10px 10px 5px;
          }

          .incentive-banner-subtitle {
            display: flex;
            align-items: center;
            font-size: 1.1rem;
            color: $ckHeaderGrey;
            padding: 10px 0 0 0;
          }
        }

        .info-body {
          color: $ckCharcoalGrey;
          font-family: $fontRoundedBook;
          font-size: 1.2rem;
          font-weight: 800;
          line-height: 20px;
          text-align: center;
        }
      }

      input, textarea {
        border: 1px solid $borderGrey;
        border-radius: 5px;
        padding: 12px;
        font-size: 1.1rem;
        width: 530px;

        &:focus {
          border: 2px solid $ckGreenProgress;
        }

        &::placeholder {
          color: $lightText;
        }
      }


      .submit-incentive-assignment-limit-v1 {
        width: auto;
        font-size: 14px;
        font-weight: 1000;
        height: 48px;
        margin-top: 17px !important;
        background-color: $ckGreenProgress;
        color: $ckAltGrey;
        font-size: 1.6rem;
        padding: 1.6rem 2.4rem;
      }

      .non-mdr-email-input {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 0.1rem;

        .incentive-assignment-limit-v1-container {
          input[type="text"] {
            margin: 0;
            border: 1px solid $borderGrey;
            border-radius: 5px;
            padding: 12px;
            font-size: 1.2rem;
            height: 34px;
            width: 260px;
          }
        }
      }

      .incentive-assignment-limit-v1-container {
        padding-left: 0;
        padding-right: 0;
        color: $ckAltGrey;
        margin: 5px 0px;
        width: 100%;

        p {
          color:$ckHeaderGrey;
          font-weight: 100;
        }
        md-input {
          color: $ckAltGrey;
        }

        md-select {
          margin: 0 0 0 16px;

          md-select-value {
            text-align: left;
            width: 345px;
          }

        }

        span {
          left: 0px;
          top: 0px;
          border-radius: 0px;
          font-size: 1rem;
          font-weight: 300;
          color: $ckHeaderGrey;
          text-transform: uppercase;
          width: 170px;

          @media screen and (max-width: 750px) {
            text-align: start;
          }
        }

        input, textarea {
          border: .5px solid $borderGrey;
          border-radius: 12px;
          padding: 12px;
          font-size: 1.4rem;
          height: 4.8rem;
          overflow:scroll;

          &:focus {
            border: 2px solid $ckGreenProgress;
          }
        }

        textarea {
          border: 1px solid $borderGrey;
          border-radius: 5px;
          margin-bottom: 50px;
          overflow: auto;
          font-size: 1.2rem;
          line-height: 2rem;
        }

        input[type="text"] {

          @media screen and (max-width: 750px) {
            margin-left: 0px;
            width: 100%;
          }

          @media screen and (max-width: 728px) {
            max-width: none;
          }

          &.error {
            border: 2px solid $ckWarn;
          }

          color: $ckAltGrey;
          font-size: 1.6rem;
          width: 245px;
          margin: 0 0 0 10px;

          &::placeholder {
            color: $lightText;
          }

          &:focus {
            &::placeholder {
              color: $lightText;
            }
          }
        }
      }
    }
  }

  .incentive-assignment-limit-v1-email-container {
    padding-bottom: 5.5em;

    .ck-dialog-header {
      height: 5.5em;
    }

    .ck-dialog-body {
      min-height: auto;
      margin: 0 132px;

      &.centered {
        margin: auto;
      }
    }
  }

  .loading {
    .ck-dialog-body {
      overflow: hidden;
    }
  }

  .address-book {
    font-weight: 100;
    color: $ckHeaderGrey;
  }

  .sub-title {
    font-size: 1.2rem;
    font-weight: 100;
    color: $ckHeaderGrey;
  }

  .incentive-footer {
    position: fixed;
    height: 50px;
    background-color: $white;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    border-top: 1px solid $lightText;
    .ck-primary-span {
        font-size: 1.1rem;
        font-weight: 100;
        color: $ckCharcoalGrey;
        margin: 0 0 0 30px;
        a {
          text-transform:none;
        }
      }
    }
    .md-button.ck-primary {
        margin: 0 30px 0 0;
    }
    .ck-primary-no-mdr-span {
        color: $ckCharcoalGrey;
        font-size: 1.1rem;
        font-weight: 100;
        margin: 20px 180px 0 0;
        a {
          text-transform:none;
        }
    }
    .md-button.ck-primary-no-mdr {
        margin: 10px 0 0 20px;
    }
    .skip-section {
      padding-left: 400px;
    }
    .skip-section-no-mdr {
      padding-left: 350px;
      margin: 18px;
    }
}
