.assignment-export-dialog {
  min-height: unset;
  min-width: unset;
  overflow: hidden;

  .main {

    .ck-dialog-header {
      height: $teacherHeaderHeight;

      .ck-close {
        color: $ckGreen;
      }

      .ck-back {
        color: $ckGreen;
      }
    }

    .ck-dialog-footer {
      margin-bottom: 20px;
    }

    .ck-dialog-body {
      overflow: hidden;
      width: 400px;
      padding: 0;

      .ck-dialog-title {
        @extend .classkick-header;
        text-align: center;
        margin-bottom: 20px;
      }

      .export-individually {
        margin-bottom: 15px;
        text-align: center;
      }

      md-list {
        border-top: 1px solid $dividerGrey;
        border-bottom: 1px solid $dividerGrey;

        min-height: 12em;
        overflow: auto;
        background-color: white;

        md-list-item {

          border-bottom: 1px solid $dividerGrey;

          .md-button {
            padding: 0;
          }

          .item-content {
            font-family: $fontRoundedBook;
            color: $ckHeaderGrey;
          }

          .md-list-item-inner {
            padding-left: 36px;
            padding-right: 36px;
          }
        }
      }

      .include-metadata {
        margin-top: 15px;
        padding-left: 36px;
        padding-right: 36px;
        text-align: center;
      }

      .export-all {
        margin-top: 40px;
        margin-bottom: 60px;

        .export-all-description {
          margin-bottom: 10px;
        }

        .md-button.ck-primary {
          margin: 0;
        }
      }
    }
  }

  .loading {
    height: 100px;
    width: 100px;
  }

  .exporting {

    .ck-dialog-header {
      height: $teacherHeaderHeight;

      .ck-close {
        color: $ckGreen;
      }
    }

    .ck-dialog-body {
      overflow: hidden;
      width: 400px;
      padding: 0;

      .exporting-message {
        @extend .classkick-header;
        @extend .truncate;
        text-align: center;
        padding: 0 35px;
      }

      .exporting-count {
        @extend .classkick-header;
        text-align: center;
        font-family: $fontRoundedBook;
      }

      .exporting-spinner {
        margin: 50px 0 60px 0;
      }

      .md-button {
        margin-bottom: 12px;
      }
    }
  }
}

.assignment-export-hidden assignment-sheet svg {
  visibility: hidden;
  position: absolute;
}
