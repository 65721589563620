.order {

  background-color: white;
  height: 100vh;
  overflow: auto;

  .header {
    border-bottom: none;
    height: 102px;
    padding: 25px 80px;

    .left {
      height: 100%;

      .logo {
        max-height: 100%;

        img {
          max-height: 100%;
        }

        &:hover {
          cursor: pointer;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .teacher-resources,
    .order-pro,
    .order-login {
      margin-left: 30px;
      cursor: pointer;
      outline: none;
    }

    .order-pro {
      color: $ckGreen;
      cursor: default;
    }

    .ck-menu {
      color: $ckHeaderGrey;
    }
  }

  .body {

    .body-title {
      @extend .major-header;
      margin-top: 32px;
      text-align: center;
    }

    .forward-info {
      margin-bottom: 36px;

      .md-button.ck-primary {
        font-size: 150%;
        height: unset;
        line-height: unset;
        padding: 2rem 6rem;

        i.ck-email {
          position: relative;
          top: 1px;
          margin-right: 6px;
        }
      }

      .message {
        margin-top: 16px;
        width: 550px;
        color: $ckAltGrey;
        font-size: 1.7rem;
        text-align: center;
        font-family: $fontRoundedMedium;
      }
    }

    .tabs {
      padding: 0 19px;
      height: 32px;
      margin-top: 8px;
      margin-bottom: 16px;
      font-size: 1.7rem;

      .tab {
        min-width: 75px;
        padding: 0 12px;
        margin: 0 24px 0 0;
        border-radius: 0;
        color: $ckGreen;
        text-transform: none;
        cursor: pointer;
        outline: none;
        border-bottom: 3px solid transparent;

        &.active {
          color: $ckHeaderGrey;
          border-bottom: 3px solid $ckHeaderGrey;
        }

        &:hover {
          background-color: white;
        }

        .md-ripple-container {
          border-radius: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .products-container {
      margin-bottom: 48px;

      .product {
        width: 860px;
        border-radius: 20px;
        box-shadow: $boxShadowCard;
        padding: 60px 48px;

        .product-main {
          color: $ckPro;

          .product-price {
            font-size: 24px;
            line-height: 1;

            &.teacher-plan {
              margin-top: 8px;
            }
          ;
          }
          .po-price {
            font-size: 15px;
            margin-top: 8px;
          }

          .product-price-info {
            font-size: 14.375pt;
            line-height: 1;
          }

          .product-price-info-strikethrough {
            text-decoration: line-through;
          }

          .product-price-subtext {
            margin-top: 8px;
            font-family: $fontRoundedBook;
            text-align: center;

            &.credit-card {
              margin-top: 0px;
            }
          }

          .product-disclaimer-text {
            font-size: 11px;
            font-family: $fontRoundedBook;
            text-align: center;
          }

          .product-price-config {
            margin-top: 8px;
            font-family: $fontRoundedBook;

            md-input-container {
              margin: 0 0 0 3px;
              padding: 0 0 0 3px;
              width: 47px;

              input {
                height: 2.6rem;
              }
            }

            .stepper {
              position: relative;
              top: -2px;
              margin-left: 6px;
              margin-right: 8px;

              .increment, .decrement {
                height: 12px;
                cursor: pointer;
                outline: none;

                i {
                  color: $ckHeaderGrey;
                }
              }
            }
          }

          .product-actions {
            margin-top: 24px;

            &.teacher-tab {
              margin-top: 0px;
            }

            .credit-card-section {
              margin-bottom: 10px;
            }

            .po-section {
              margin-top: 15px;
            }

            .md-button {
              border-color: $ckGreen;
              color: $ckGreen;
              margin: 0;
              font-size: 110%;
              padding: 2rem 3rem 3rem;

              &.po-button {
                margin: 10px 0 0 5px;
              }
            }

            .md-button:first-child {
              margin-right: 5px;
            }

            .md-button:last-child {
              margin-left: 5px;
            }
          }

          .product-asterisk {
            font-family: $fontRoundedBook;
            font-size: $bodySub;
            color: $lightText;

            i {
              position: relative;
              top: 0.15rem;
              color: $ckHeaderGrey;
            }
          }
        }

        .spacer {
          width: 32px;
        }

        .product-info {
          text-align: center;

          .product-info-title {
            font-size: 14.375pt;
            line-height: 17.5pt;
            color: $ckPro;

            .anchor {
              position: relative;
              width: 0;

              i.ck-contextualhelp {
                color: $ckHeaderGrey;
                font-size: $bodyCopy;
                position: absolute;
                top: 3px;
                left: 4px;
                cursor: pointer;
              }
            }

          }

          .product-info-body {
            font-family: $fontRoundedBook;

            a {
              text-transform: none;
            }
          }

        }
      }
    }
  }

  .learn-more {
    margin: 8px 0 32px 0;

    a {
      text-align: center;
      text-transform: none;

      div {
        line-height: 1.5;
      }
    }
  }

  .features {
    margin-bottom: 48px;

    .title {
      width: 100%;
    }

    .features-title {
      @extend .classkick-header;
      margin: 40px;
      text-align: center;
    }

    .features-container {
      width: 814px;

      table {
        width: 100%;
        border-collapse: collapse;

        th {
          font-weight: unset;
          padding-top: 10px;
        }

        td {
          font-family: $fontRoundedBook;
          border-bottom: 1px solid $dividerGrey;
          height: 48px;
          padding: 1rem;
        }

        tr.basic-features:nth-child(even),
        tr.pro-features:nth-child(odd) {
          background-color: $backgroundGrey;
        }

        .help {
          position: relative;
          top: 2px;
          left: 2px;
          outline: none;
          cursor: pointer;
          color: $ckGreen;
        }

        td.feature-heading {
          background-color: $borderGrey;
          font-weight: bolder;
          height: 20px;
        }

        th.standard,
        td.standard,
        th.pro,
        td.pro {
          text-align: center;
        }

        td.description {
          font-style: italic;
          border-bottom: none;
          text-align: center;
          padding-bottom: 0;
          margin-bottom: 0;
        }

        .md-button {
          border-color: $ckGreen;
          color: $ckGreen;
          margin: 0;
        }

        td.quote-button {
          text-align: center;
          padding-top: 0;
          padding-bottom: 0;
        }

        tr.pro-features:first-child {
          margin-top: 20px;
        }

        tr > :nth-child(1) {
          width: 40%;
        }

        tr > :nth-child(2),
        tr > :nth-child(3) {
          width: 30%;
        }

        tr > :nth-child(3) {
          border-left: 2px solid $ckGreen;
          border-right: 2px solid $ckGreen;
          position: relative;

          ~ td {
            border-top: none;
            border-bottom: none;
          }
        }

        tr:first-child > :nth-child(3)::before,
        tr:last-child > :nth-child(3)::after {
          content: "";
          position: absolute;
          left: -2px;
          right: -2px;
          border-top: 2px solid $ckGreen;
          border-bottom: 2px solid $ckGreen;
          z-index: 1;
        }

        tr > :nth-child(3) ~ td:nth-child(4) {
          border-left: 2px solid $ckGreen;
        }

        tr:first-child > :nth-child(3)::before {
          top: -1px;
          border-bottom: none;
        }

        tr:last-child > :nth-child(3)::after {
          bottom: -1px;
          border-top: none;
        }
      }
    }
  }

  .ask-anything {
    @extend .classkick-header;
    text-align: center;
    margin: 24px 0 64px 0;

    a {
      text-transform: none;
    }
  }

  @media screen and (max-height: 625px) {

    .body .body-title {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 600px) {
    .header {
      padding: 24px 25px;
    }
  }

  @media screen and (min-height: 800px) {
    .learn-more {
      display: none;
    }
  }
}
