.contextual-paywall-dialog {

  min-width: unset;
  max-height: unset;

  .contextual-paywall {

    min-width: 700px;
    height: 600px;

    .ck-dialog-header {
      height: $teacherHeaderHeight;

      .ck-close {
        color: $ckGreen;
      }
    }

    .ck-dialog-title {
      @extend .classkick-header;
      text-align: center;
      margin-bottom: 24px;
    }

    .ck-dialog-body {

      .image-container {
        width: 532px;
        min-width: 532px;
        height: 299px;
        overflow: hidden;
        margin: 0 100px 24px 100px;
        border: 2px solid $backgroundGrey;

        img {
          position: relative;
          width: 532px;
          min-width: 532px;
          height: 299px;
        }
      }

      .nav-buttons {

        margin: 0 0 24px 0;

        .dot-container {
          height: 100%;
        }

        .dot-button {
          outline: none;
          cursor: pointer;
        }

        .dot {
          width: 8px;
          height: 8px;
          background-color: $disabledGrey;
          border-radius: 4px;
          margin: 8px;

          &.active {
            background-color: $ckGreen;
          }
        }

        .md-button.prev, .md-button.next {
          width: 32px;
          min-width: 32px;
          padding: 0;
          margin: 2px;
        }

        .prev.md-button[disabled] ,
        .next.md-button[disabled] {
          color: $disabledGrey;
        }
      }

      .actions {

        padding: 0 48px;

        .md-button {
          width: 240px;
          margin: 0 48px 16px;
        }
      }

    }

  }

  @media (max-height: 599px) {
    height: 540px;

    .contextual-paywall {
      height: 540px;
    }

    .nav-buttons {
      display: none;
    }
  }

  .loading {
    width: 100px;
    height: 100px;
  }
}

