.add-new-sticker-dialog {
  padding: 3rem;

  .header {
    border: none;
    font-size: 1.8rem;
  }

  .close {
    min-width: fit-content;
    padding: 0px 1.5rem;
    position: absolute;
    right: 0;
    top: 1rem;
  }

  .change-image {
    .md-button {
      height: fit-content;
    }

    .contents {
      padding: 1rem;
    }
  }

  .change-points {
    align-items: center;
    margin: 1.5rem 0 0 0;

    .md-button {
      min-width: 3.2rem;
      width: 3.2rem;
      min-height: 3.2rem;
      padding: 0;
    }

    md-input-container {
      margin: 0;
      width: 30%;
    }

    input[name='stickerScore'] {
      margin-bottom: 1.6rem;
    }
  }

}