.select-purchase-period-dialog {
  min-width: unset;
  max-height: unset;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-body {
    max-width: 500px;
    min-width: 500px;
    margin: 0 60px 60px 60px;

    .ck-dialog-title {
      @extend .classkick-header;
      text-align: center;
      margin-bottom: 36px;
      text-transform: capitalize;
    }

    .md-button.ck-primary {
      margin: 20px 0 0 0;
    }

    .error-message {
      color: $ckWarn;
      text-align: center;
      height: 20px;
      margin: 8px;
      font-family: $fontRoundedBook;
    }
  }
}

