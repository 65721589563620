.signup-name {
  min-height: 100vh;
  width: 100vw;
  background: $ckGreen;
  background-image: url('../../../assets/images/scribs.png');
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 8px 5px 8px;

  .major-header {
    img {
      width: 16rem;
    }

    margin-top: 6rem;
    margin-bottom: .25em;
  }

  .body {
    width: 60rem;
    max-width: 100%;
    background-color: white;
    box-shadow: $boxShadowCard;
    border-radius: 24px;
    padding: 32px;

    .body-header {
      .classkick-header {
        font-size: 1.8em;
        font-weight: 800;
        line-height: 1.5em;
        text-align: center;
      }
      .info {
        font-size: 1.1em;
        margin-bottom: 2.3em;
        font-family: $fontRoundedBook;
        text-align: center;
        padding: 32px 0px 0px 0px;
        line-height: 2.4rem;
      }
    }

    .body-content {
      margin-top: 16px;

      &.teacher {
        md-input-container {
          margin: 0;
        }
        md-autocomplete md-autocomplete-wrap {
          border-radius: 8px;
          height: 4.8rem;
          border: .5px solid $ckAltGrey;
          box-shadow: none;
        }
        md-autocomplete input {
          padding: .5em 0em 0em 1em;
        }
        input::placeholder {
          font-size: 1.4rem;
        }

        .third {
          color: rebeccapurple;
          background-color: fuchsia;
          font-size: 30px;
        }
      }

      .grades-subjects, .school {
        padding-top: 1.6rem;
        gap: 10px;

        i.ic {
          position: relative;
          top: 45px;
          z-index: 52;
          font-size: 1.8em;   
          &.ck-backpack {
            right: 21.5rem;
          }   
          &.ck-library {
            right: 11rem;
          }
          &.ck-school-building {
            left: -15.4rem;
          }
          @media screen and (max-width: 600px) {
            &.ck-backpack {
              left: -10rem;
            }
          }
        }
        md-select-value {
          height: 4.8rem;
          border: .5px solid $ckAltGrey;
          border-radius: 8px;
          height: 4.8rem !important;
          padding-left: 48px;

          i {
            transform: rotate(270deg);
          }
        }
        md-input-container {
          width: 100%;
          max-width: 100%;
          overflow: hidden;
        }

        md-autocomplete md-autocomplete-wrap {
          border-radius: 8px;
          height: 4.8rem;
          border: .5px solid $ckAltGrey;
          box-shadow: none;
        }
        md-autocomplete input { 
          padding: .5em 0em 0em 48px;
        }
        input::placeholder {
          font-size: 1.4rem;
        }
      }
      

      md-input-container.first-name,
      md-input-container.last-name {
        margin: 12px 0 0 0;
      }

      md-input-container {
        padding-left: 0;
        padding-right: 0;

        input {
          border-radius: 8px;
          height: 4.8rem;
          border: .5px solid $ckAltGrey;
        }
      }

      .md-button.ck-primary {
        margin: 20px 0 0 0;
      }

      .get-started {
        width: 16.4rem;
        height: 4.8rem;
        margin-top: 32px !important;
        align-self: end;
        font-family: $fontRoundedBold;
        font-size: 1.5rem;
        box-shadow: 0px 12px 16px 0px #0000000A;
        box-shadow: 0px 4px 8px 0px #00000014;
        box-shadow: 0px 2px 4px 0px #0000001F;
      }
    }

    .body-footer {
      height: 1.6rem;
      padding-top: 32px;
      span {
        font-size: 3.2rem;
        letter-spacing: -7px;
        color: $backgroundGrey;
      }
      .active {
        color: $ckGreen;
        z-index: 1;
      }
    }
  }

  .loading {
    margin-top: 8em;
    height: 10rem;
    width: 100px;
    background-color: white;
    border-radius: 10px;
  }
  
  .label {
    display: inline-block;
    height: 2.4rem;
    font-family: $fontRoundedBold;
    font-size: 1.1em !important;
    color: $ckAltGrey;
    padding-left: 1em;
  
    &.full-bar {
      top: -2.5rem;
    }
  }
  .asterisk {
    color: $ckWarn;
  }
}
