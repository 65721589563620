.share-order-dialog {
  min-width: unset;
  width: 600px;
  border-radius: 12px;
  max-height: 100vh;

  .share-order {

    .ck-dialog-header  {

      &.forward {
        min-height: 48px;
        margin: 3.2rem 0 2.3rem 0;
        color: $ckAltGrey;

        .share-order-title {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          padding: 0 32px;

          div {
            min-height: 48px;
          }

          .close {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-start;

            &:hover {
              i {
                color: $ckGreenProgress;
              }
            }

            i {
              color: $ckAltGrey;
              font-size: 14px;
              font-weight: 600;
            }
          }

          .title {
            display: flex;
            align-items: center;
            font-size: 24px;
            font-family: $fontRoundedBold;
            text-align: center;
            max-width: 43rem;
            padding: 0 1.5rem;
          }
        }
      }
    }

    .ck-dialog-body {

      &.order {
        margin: 0 32px 32px 32px;
        overflow-x: hidden;

        .img-note {
          padding: 10px;
        }

        .footer {
          position: sticky;
          bottom: 0;
        }

        .share-order-container {
          padding-left: 0;
          padding-right: 0;
          margin-top: 0;
          margin: 8px 0px;

          &.to {
            .mdr-select {
              width: 100%;
              max-width: 464px;

              @media screen and (max-width: 747px) {
                margin-left: 69px;
              }


              @media screen and (max-width: 728px) {
                margin-left: 0px;
                max-width: none;
              }

              .error-messages {
                margin-left: 5px;
              }
            }
          }

          span {
            left: 0px;
            top: 0px;
            border-radius: 0px;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 2px;
            text-transform: uppercase;
            width: 70px;
            text-align: end;

            @media screen and (max-width: 750px) {
              text-align: start;
            }
          }

          .ck-building {
            font-size: 2.2rem;
            top: 14px;
            left: 98px;
            position: absolute;
          }

          input, textarea {
            border: .5px solid $ckHeaderGrey;
            border-radius: 8px;
            padding: 12px;
            font-size: 1.4rem;
            height: 4.8rem;

            &:focus {
              border: 2px solid $ckGreenProgress;
            }
          }

          .email-body {
            padding: 12px !important;
          }

          .email-body-min-height {
            min-height: 75px;
          }

          .ck-selected-admin-emails {
            margin-left: 16px;

            @media screen and (max-width: 750px) {
              margin-left: 0px;
            }
          }

          input[name="fromInput"], input[name="toInput"], input[name="subjectInput"] {
            max-width: 448px;
            margin-left: 16px;

            @media screen and (max-width: 750px) {
              margin-left: 0px;
            }

            @media screen and (max-width: 728px) {
              max-width: none;
            }

            &.error{
              border: 2px solid $ckWarn;
            }
          }

          input[name="fromInput"], input[name="toInput"] {
            color: $ckAltGrey;
            font-family: $fontRoundedBold;
            font-size: 1.4rem;

            &::placeholder {
              color: $slate;
            }

            &:focus {
              &::placeholder{
                color: $ckAltGrey;
              }
            }
          }

          textarea[name="bodyInput"] {
            line-height: 2.0rem;
            overflow: auto;
            padding-top: 8px !important;
          }
        }

        .error-messages {
          margin-left: 80px;
          left: 12px;

          div {
            font-size: 0.8rem;
          }

          @media screen and (max-width: 750px) {
            margin-left: 0px;
            left: 0px;
          }
        }

        .send-order-email{
          width: auto;
          text-transform: uppercase;
          font-weight: 1000;
          height: 48px;
          margin-top: 17px !important;
          font-size: 1.6rem;
          padding: 1.6rem 2.4rem;
          background-color: $azureBlue;
          color: $white;
          border-radius: 8px;
          white-space: normal;

          .md-ripple-container {
            border-radius: 8px;
            background-color: transparent;
          }

          &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), $azureBlue;
          }

          &:active {
            background-color: $ckAltGrey;
            color: $white;
          }

          @media screen and (max-width: 590px){
            height: auto;
            padding: 1.4rem 2.4rem;
          }
        }
        .scrollable-content {
          flex: 1;
          overflow-y: auto;

          .to {
            .mdr-select {
              width: 100%;
              max-width: 434px;
            }
          }

          input[name="fromInput"], input[name="toInput"], input[name="subjectInput"] {
            max-width: 434px;
          }
        }
      }
    }

    md-input-container, .md-input {
      color: $ckAltGrey;
    }

    .copy-invite {
      margin: 20px 20px 0 0;
      text-align: center;
      padding: 1.4rem;
      background-color: $veryLightGrey;
      display:flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      text-transform: uppercase;
      color: $ckAltGrey;
      font-size: 1rem;
      line-height: 1.6rem;
      font-weight: 600;
      letter-spacing: 2px;
      cursor: pointer;

      &:hover {
        color: $ckGreenProgress;

        &:active {
          background-color: $ckAltGrey;
          color: $white;
        }
      }

      .ck-new-copy-file {
        margin-right: 1.4rem;
        font-size: 2rem;
      }
    }

    input[name="subjectInput"]{
      font-weight: bold;
    }

    .org-reminder {
      margin-bottom: 0px !important;
    }

    .subtitle {
      margin: 0 40px 28px 40px;
      color: $ckAltGrey;
      text-align: center;
    }
  }

  .trial-email-container {
    padding-bottom: 5.5em;

    .ck-dialog-header{
      height: 5.5em;
    }

    .ck-dialog-body {
      min-height: auto;
      margin: 0 132px;
    }

    .md-button.bar-item{
      height: 70%;

      &.md-ink-ripple{
        padding: 0px;
        width: 45px;
      }
    }
  }

  .loading {
    .ck-dialog-body {
      overflow: hidden;
    }
  }
}

