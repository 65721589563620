@font-face {
  font-family: "ckicon";
  src: url("../assets/fonts/ckicon.eot?xplhq1");
  src: url("../assets/fonts/ckicon.eot?xplhq1#iefix") format("embedded-opentype"),
    url("../assets/fonts/ckicon.ttf?xplhq1") format("truetype"),
    url("../assets/fonts/ckicon.woff?xplhq1") format("woff"),
    url("../assets/fonts/ckicon.svg?xplhq1#ckicon") format("svg");
  font-weight: normal;
  font-style: normal;
}

i.ck {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ckicon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.ck-carrot,
  &.ck-carrot-up {
    font-size: 1rem;
  }

  &.ck-close {
    font-size: 1.1rem;
  }
}

/**
 * ONLY REPLACE FROM HERE DOWNWARD.
 *
 * If new font files are uploaded, modify the caching string ?xxxxx# above
 * in the font urls. That will cause the fonts to be re-downloaded
 * Remove color for all icons except "ck-active-checkbox .path1:before" and "ck-active-checkbox .path2:before"
 **/
 .ck-active-checkbox .path1:before {
  content: "\e900";
  color: rgb(151, 213, 74);
}
.ck-active-checkbox .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ck-admin:before {
  content: "\e902";
}
.ck-alert-outline:before {
  content: "\e903";
}
.ck-alert:before {
  content: "\e904";
}
.ck-announcement:before {
  content: "\e905";
}
.ck-aplus-assignment:before {
  content: "\e906";
}
.ck-apple:before {
  content: "\e907";
}
.ck-assignment-file:before {
  content: "\e908";
}
.ck-assignment:before {
  content: "\e909";
}
.ck-assignmentmarketplace:before {
  content: "\e90a";
}
.ck-audio:before {
  content: "\e90b";
}
.ck-back-arrow:before {
  content: "\e90c";
}
.ck-back:before {
  content: "\e90d";
}
.ck-backgroundcolor:before {
  content: "\e90e";
}
.ck-backpack:before {
  content: "\e90f";
}
.ck-bell:before {
  content: "\e910";
}
.ck-board:before {
  content: "\e911";
}
.ck-bordercolor:before {
  content: "\e912";
}
.ck-briefcase:before {
  content: "\e913";
}
.ck-camera:before {
  content: "\e914";
}
.ck-carrot-up:before {
  content: "\e915";
}
.ck-carrot:before {
  content: "\e916";
}
.ck-chat:before {
  content: "\e917";
}
.ck-check:before {
  content: "\e918";
}
.ck-chevron-down:before {
  content: "\e919";
}
.ck-classkick-hand:before {
  content: "\e91a";
}
.ck-close-gray:before {
  content: "\e91b";
}
.ck-close:before {
  content: "\e91c";
}
.ck-closed-eye:before {
  content: "\e91d";
}
.ck-cloud:before {
  content: "\e91e";
}
.ck-collapse:before {
  content: "\e91f";
}
.ck-contextualhelp:before {
  content: "\e920";
}
.ck-createfromfile:before {
  content: "\e921";
}
.ck-credit-card:before {
  content: "\e922"; 
}
.ck-draggable:before {
  content: "\e923";
}
.ck-dropdown:before {
  content: "\e924";
}
.ck-duplicate:before {
  content: "\e925";
}
.ck-edit:before {
  content: "\e926";
}
.ck-email-2023:before {
  content: "\e927";
}
.ck-email:before {
  content: "\e928";
}
.ck-empty-checkbox:before {
  content: "\e929";
}
.ck-eraser:before {
  content: "\e92a";
}
.ck-expand:before {
  content: "\e92b";
}
.ck-export:before {
  content: "\e92c";
}
.ck-exportpdf:before {
  content: "\e92d";
}
.ck-feedback:before {
  content: "\e92e";
}
.ck-fillintheblank:before {
  content: "\e92f";
}
.ck-filters:before {
  content: "\e930";
}
.ck-fire:before {
  content: "\e931";
}
.ck-folder:before {
  content: "\e932";
}
.ck-font-family:before {
  content: "\e933";
}
.ck-forward-arrow-2023:before {
  content: "\e934";
}
.ck-gift:before {
  content: "\e935";
}
.ck-google-drive1:before {
  content: "\e936";
}
.ck-grademinus:before {
  content: "\e937";
}
.ck-gradeplus:before {
  content: "\e938";
}
.ck-grid:before {
  content: "\e939";
}
.ck-hand:before {
  content: "\e93a";
}
.ck-handcheck:before {
  content: "\e93b";
}
.ck-help:before {
  content: "\e93c";
}
.ck-highlighter:before {
  content: "\e93d";
}
.ck-home-2023:before {
  content: "\e93e";
}
.ck-home:before {
  content: "\e93f";
}
.ck-hover-checkbox:before {
  content: "\e940";
}
.ck-image-document:before {
  content: "\e941";
}
.ck-library:before {
  content: "\e942";
}
.ck-linebreak:before {
  content: "\e943";
}
.ck-linedash:before {
  content: "\e944";
}
.ck-lineendleft:before {
  content: "\e945";
}
.ck-lineendright:before {
  content: "\e946";
}
.ck-linetool:before {
  content: "\e947";
}
.ck-linewidth:before {
  content: "\e948";
}
.ck-link:before {
  content: "\e949";
}
.ck-loading:before {
  content: "\e94a";
}
.ck-locked:before {
  content: "\e94b";
}
.ck-manipulative:before {
  content: "\e94c";
}
.ck-megaphone:before {
  content: "\e94d";
}
.ck-menu:before {
  content: "\e94e";
}
.ck-metric:before {
  content: "\e94f";
}
.ck-moreoptions:before {
  content: "\e950";
}
.ck-multiplechoice:before {
  content: "\e951";
}
.ck-new-copy-file:before {
  content: "\e952";
}
.ck-new-export:before {
  content: "\e953";
  
}
.ck-new-folder-2023:before {
  content: "\e954";
  
}
.ck-new-folder:before {
  content: "\e955";
  
}
.ck-next:before {
  content: "\e956";
}
.ck-notifications:before {
  content: "\e957";
}
.ck-opened-eye:before {
  content: "\e958";
}
.ck-options-assign:before {
  content: "\e959";
}
.ck-options-copy:before {
  content: "\e95a";
}
.ck-options-copyall:before {
  content: "\e95b";
}
.ck-options-duplicate:before {
  content: "\e95c";
}
.ck-options-move:before {
  content: "\e95d";
}
.ck-options-paste:before {
  content: "\e95e";
}
.ck-options-rename:before {
  content: "\e95f";
}
.ck-options-reorder:before {
  content: "\e960";
}
.ck-options-share:before {
  content: "\e961";
}
.ck-options-trash:before {
  content: "\e962";
}
.ck-options-view:before {
  content: "\e963";
}
.ck-pageback:before {
  content: "\e964";
}
.ck-pagenext:before {
  content: "\e965";
}
.ck-pagerback:before {
  content: "\e966";
}
.ck-pagerdown:before {
  content: "\e967";
}
.ck-pagernext:before {
  content: "\e968";
}
.ck-pagerup:before {
  content: "\e969";
}
.ck-pan:before {
  content: "\e96a";
}
.ck-paper-airplane:before {
  content: "\e96b";
  
}
.ck-pause:before {
  content: "\e96c";
}
.ck-pen:before {
  content: "\e96d";
}
.ck-pin-clicked:before {
  content: "\e96e";
  
}
.ck-pin-unclicked:before {
  content: "\e96f";
  
}
.ck-play:before {
  content: "\e970";
}
.ck-pleasecheck:before {
  content: "\e971";
}
.ck-plus:before {
  content: "\e972";
}
.ck-pound:before {
  content: "\e973";
  
}
.ck-print:before {
  content: "\e974";
  
}
.ck-pro:before {
  content: "\e975";
}
.ck-profile:before {
  content: "\e976";
}
.ck-quickfeedback:before {
  content: "\e977";
}
.ck-redo:before {
  content: "\e978";
}
.ck-rename:before {
  content: "\e979";
}
.ck-return:before {
  content: "\e97a";
}
.ck-roster:before {
  content: "\e97b";
}
.ck-school-building:before {
  content: "\e97c";
  
}
.ck-search:before {
  content: "\e97d";
}
.ck-select:before {
  content: "\e97e";
}
.ck-settings:before {
  content: "\e97f";
}
.ck-share:before {
  content: "\e980";
}
.ck-shopping-cart:before {
  content: "\e981";
  
}
.ck-signout:before {
  content: "\e982";
}
.ck-slide-play:before {
  content: "\e983";
  
}
.ck-smallplus:before {
  content: "\e984";
}
.ck-star-filled:before {
  content: "\e985";
  
}
.ck-star-unfilled:before {
  content: "\e986";
  
}
.ck-stop:before {
  content: "\e987";
  
}
.ck-studenthelpers:before {
  content: "\e988";
}
.ck-sync:before {
  content: "\e989";
}
.ck-text:before {
  content: "\e98a";
}
.ck-textdecrease:before {
  content: "\e98b";
}
.ck-textincrease:before {
  content: "\e98c";
}
.ck-time-clock:before {
  content: "\e98d";
  
}
.ck-trash-2023:before {
  content: "\e98e";
}
.ck-trash:before {
  content: "\e98f";
}
.ck-undo:before {
  content: "\e990";
}
.ck-unlocked:before {
  content: "\e991";
}
.ck-volume:before {
  content: "\e992";
  
}
.ck-warning:before {
  content: "\e993";
  
}
.ck-youtube:before {
  content: "\e994";
}
.ck-zoomin:before {
  content: "\e995";
}
.ck-zoomout:before {
  content: "\e996";
}
.ck-google-drive:before {
  content: "\ea8f";
}
