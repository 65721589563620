help-inbox {
  height: 100%;

  .help-inbox {
    padding: 0;

    &.new-hand {
      .bar-item {
        background-color: $veryLightGrey;
        border-radius: 100px;
      }
    }
    .md-button.bar-item {
      width: 75px;
    }
  }
}

/*
  Angular Material places md-menu-content globally
  so all css styles must be in global context
*/
.help-inbox-menu {
  max-height: 95vh;

  md-menu-item {
    border-bottom: 1px solid $dividerGrey;
    font-family: $fontRoundedMedium;
    color: $ckHeaderGrey;


    .md-button {
      padding: 0 16px;
    }
  }

  md-menu-item.no-requests,
  md-menu-item.no-notifications {
    font-size: $bodySub;
    color: $lightText;
    font-family: $fontRoundedBook;
  }

  md-menu-item.request {

    div img {
      width: 2.5em;
      margin-right: 1em;
    }

    span {
      font-family: $fontRoundedBook;
    }

    i {
      padding-left: 4em;
      font-size: 0.6em;
    }

  }

  md-menu-item.explanation {
    border-bottom: 1px solid $dividerGrey;

    span {
      width: unset;
    }

    a {
      width: 135px;
      text-transform: none;
      text-align: right;
      cursor: pointer;
    }
  }

  md-menu-item.check-subtitle,
  md-menu-item.help-subtitle,
  md-menu-item.chat-subtitle {
    max-height: 2.5em;
    min-height: 2.5em;
    padding: 0 16px;

    > * {
      padding: 0;
    }
  }

  md-menu-item.check-subtitle {

    .check-requests-count {
      color: $ckGreen;
      width: 1.5em;
      text-align: center;
      margin-top: 1px;
    }
  }

  md-menu-item.help-subtitle {

    .help-requests-count {
      color: $ckYellow;
      width: 1.5em;
      text-align: center;
      margin-top: 1px;
    }
  }

  md-menu-item.chat-subtitle {

    .chat-requests-count {
      color: $ckHeaderGrey;
      width: 1.5em;
      text-align: center;
      margin-top: 1px;
    }
  }
}
