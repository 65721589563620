.refer-choice {
  min-height: 100vh;
  width: 100vw;
  background: $ckGreen;
  background-image: url('../../../assets/images/scribs.png');
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 8px 8px 8px;
  overflow: auto;  
}

