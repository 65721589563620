.admin-info-dialog {
  max-width: 600px;
  width: 600px;

  .ck-dialog-header {
    margin: 40px 20px 0 32px;
    text-align: center;

    .ck-dialog-title {
      color: $ckHeaderGrey;
      font-family: $fontRoundedBold;
      font-size: 24px;
      line-height: 32px;
      width: 100%;
    }

    .bar-item {
      height: 3em;
      width: 3em;
      padding: 0;
    }

    .ck-close {
      color: $ckHeaderGrey;
      font-size: 14px;
    }
  }

  .ck-dialog-body {
    padding: 40px 32px 0px 32px;
    font-family: $fontRoundedBook;

    .button-row {
      align-items: center;
    }

    textarea {
      line-height: 1.2em;
      font-size: 1em;
      height: 250px;
    }
  }

  .md-input .admin-info-input {
    border-radius: 80px;
  }

  .admin-info-input ::placeholder {
    color: $lightText;
  }

  .admin-info-select ::placeholder {
    color: $lightText;
  }

  .info-block {
    background-color: $ckLightBlue;
    border-radius: 8px;
    font-family: $fontRoundedMedium;
    font-size: 12px;
    line-height: 16px;
    padding: 16px 16px 16px 14px;

    .info-icon {
      font-size: 20px;
      margin-right: 14px;
    }

    p {
      margin: 0;
    }
  }

  .admin-first-name,
  .admin-last-name {
    max-width: 260px;
  }

  .label {
    font-family: $fontRoundedBold;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .ck-submit-button {
    align-self: flex-end;
    border-radius: 24px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.08),
      0px 12px 16px rgba(0, 0, 0, 0.04);
    font-family: $fontRoundedBold;
    font-size: 16px;
    height: 48px;
    line-height: 24px;
    margin: 32px 4px 32px 0;
    padding: 12px 32px;
    width: 225px;
  }

  .ck-submit-button:disabled {
    box-shadow: none;
  }

  .success-message {
    margin: 0px 50px 82px;
  }
}
