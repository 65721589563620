.account-login-component {
  min-height: 100vh;
  width: 100vw;
  background: $ckGreen;
  background-image: url('../../../assets/images/scribs.png');
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 8px 20px 8px;

  &.shared-work-view {
    min-height: auto;
    .major-header {
      margin-top: 2rem;
    }

    &.loading-view {
      min-height: 50vh;
    }
  }
  .major-header {
    img {
      width: 16rem;
    }
    margin-top: 6rem;
    margin-bottom: .25em;
  }

  .body {
    width: 350px;
    max-width: 100%;
    background-color: $ckBackgroundGrey;
    box-shadow: $boxShadowCard;
    border-radius: 10px;

    .body-header {
      height: 5.6em;
    }

    .body-content {
      padding: 0 2.4rem 2.4rem 2.4rem;

      md-input-container {
        padding-right: 0;
        padding-left: 0;

        &.email-or-username {
          margin: 24px 0 0 0;
        }

        &.password {
          margin: 12px 0 0 0;
        }

        /* Change the white to any color ;) */
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active  {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
      }

      md-checkbox {
        margin: 16px 0;
      }

      md-checkbox.md-checked .md-icon {
        background: $ckGreen;
      }

      .md-button {
        margin: 0;

        &.ck-primary {
          margin-bottom: 20px ;
        }

        &.google-sign-in {

          margin-top: 4px;

          .alignment-container {
            width: 205px;
          }

          .img-container {
            margin-right: 10px;
          }

          img {
            width: 18px;
            margin-bottom: 2px;
          }
        }

        &.clever-sign-in {

          .alignment-container {
            width: 205px;
          }

          .img-container {
            margin-right: 12px;
          }

          img {
            width: 15px;
            margin-bottom: 2px;
          }

          margin-top: 16px;
        }
      }

      .separator {
        height: 2px;
        background-color: $dividerGrey;
        margin: 20px 0 20px 0;
      }

      .forgot-password-button {
        line-height: 13px;
        text-align: center;
      }

      p {
        margin: 0 0 16px 0;
        color: $ckWarn;
        text-align: center;
      }

      .md-button.signup-button {
        margin: 0;
      }
    }
  }

  .loading {
    margin-top: 8em;
    height: 100px;
    width: 100px;
    background-color: white;
    border-radius: 10px;
  }
}

.md-dialog-container.no-account {

  md-dialog {

    width: 700px;

    .ck-dialog-header {
      height: $teacherHeaderHeight;

      .ck-close {
        color: $ckGreen;
      }
    }

    .ck-dialog-title {
      @extend .classkick-header;
      text-align: center;
      margin-bottom: 15px;
    }

    .ck-dialog-body {
      @extend .classkick-header;
      font-family: $fontRoundedBook;
      text-align: center;
      margin-bottom: 60px;
    }

    .ck-dialog-actions {
      padding: 0 2.4rem 2.4rem 2.4rem;

      .md-button {
        margin: 0 0 0 1.6rem;
      }
    }
  }
}
