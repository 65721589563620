// teacher on web

/* Styles for header in teacher on web and login */
.header {
  @include flex-row(center, space-between, nowrap);
  height: $teacherHeaderHeight;
  padding: 0 0 0 0.5rem;
  border-bottom: 1px solid $dividerGrey;

  .bar-item {
    @include flex-row(center, center);
  }

  .body-subtext {
    padding-left: 10px;
  }

  .classkick-header {
    @include flex(1, 1, auto);
    text-align: center;
    color: $ckHeaderGrey;
  }

  md-input-container {
    margin: 0 0;
  }

  md-input-container .md-errors-spacer {
    min-height: 0;
  }

  .md-button.md-fab,
  .md-button.md-fab:not([disabled]):hover,
  .md-button.md-fab:enabled {
    background-color: $ckGreen;
    line-height: 3.2rem;
    height: 3.2rem;
    width: 3.2rem;
    margin-left: 1.6rem;
    margin-right: 2.4rem;

    .inner {
      @include flex-row(center, center, wrap);
    }
  }
}

.share-dialog,
.list-header,
.teacher-assignments {
  //// adjusts the way ripples look in buttons where corners are not rounded
  .md-button .md-ripple-container {
    border-radius: 0;
  }
}

md-list {
  padding: 0;
  background-color: $backgroundGrey;
}

// Defines the styles that should be the same in header and each list item
.list-header,
md-list-item.list-item {
  background-color: white;
  padding: 0 16px;
  color: $ckHeaderGrey;

  .hide {
    display: none !important;
  }

  .ck-carrot {
    @include flex-row(center, flex-start, wrap);
    margin-left: 0.3em;
  }

  div._md-list-item-inner {
    font-family: $fontRoundedBook;
    letter-spacing: 0.080em;
  }

  // Defines the styles that are common for all columns
  .col {
    @include flex-row(center, flex-start, nowrap);
    box-sizing: border-box;
    height: $teacherListHeaderHeight;
    margin: 0 8px;
    padding: 0;
    border-radius: 0;
    overflow: hidden;
    outline: none;
    display: flex;
    color: $ckHeaderGrey;

    div {
      @include flex-row(center, flex-start, nowrap)
    }
  }
}

md-list-item.list-item._md-button-wrap {
  padding: 0;
}

md-list-item.list-item {
  border-bottom: 1px solid $dividerGrey;
  height: $teacherListItemHeight;
  box-shadow: $boxShadow;
}

.list-header {
  height: $teacherListHeaderHeight;
  background-color: $listHeaderBackground;
  border-bottom: 1px solid $disabledGrey;
  font-family: $fontRoundedMedium;

  * {
    text-transform: capitalize;
  }
}

.list-helper {
  @extend .section-header;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 2em;
  outline: none;

  a {
    text-transform: none;
  }
}
