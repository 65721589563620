.edit-user-option{
  border-bottom: 1px solid $borderGrey;
}

.student-overview {
  height: 100vh;

  .header{
    .back-button{
      height: 100%;
    }

    .options-menu-button{
      height: inherit;

      .student-overview-more-options{
        height: 100%;
      }
    }
  }

  .body{
    font-weight: 400;

    .student-name{
      font-size: 2.8rem;
      line-height: 3.2rem;
      margin: 4.8rem 4rem;
    }

    .pro-student-container {
      padding: 4rem;

      .search-input {
        padding: 2rem 0;
      }

      .col {
        margin: 0;

        &.assignment-name,
        &.start-date,
        &.progress,
        &.grade {
          text-overflow: ellipsis;
          padding-left: 15px;
          min-width: 50px;
        }

        &.helps {
          text-overflow: ellipsis;
          min-width: 50px;
          justify-content: center;
          padding-left: 0
        }
      }

      md-virtual-repeat-container {
        height: calc(100vh - 30em);
        overflow: auto;
      }

      .student-overview-list{
        font-family: $fontRoundedBook;

        md-list-item.list-item{
          box-shadow: none;
        }

        progress {
          -webkit-appearance: none;
          appearance: none;
          width: 5.6rem;
          border-radius: 4px;
          height: 0.8rem;

          //IE10
          background-color: $disabledGrey;
          color: $ckGreenProgress;
        }

        //Safari and Chrome
        progress::-webkit-progress-bar {
          background-color: $disabledGrey !important;
          border-radius: 4px;
        }

        //Safari and Chrome
        progress::-webkit-progress-value {
          background-color: $ckGreenProgress !important;
          border-radius: 4px;
        }

        //Firefox
        progress::-moz-progress-bar {
          background-color: $ckGreenProgress !important;
          border-radius: 4px;
        }
      }
    }

    .upgrade-link {
      border: honeydew;
      background: none;
      font-style: inherit;
      color: $ckGreen;
      font-family: GothamRoundedMedium, "Varela Round", sans-serif;
      text-decoration: none;
      cursor: pointer;
      padding: 0;

      &:hover {
        text-decoration: underline;
      }
    }

    .paywall-text, .no-assignments-text{
      display: flex;
      font-size: 1.8rem;
      line-height: 2.8rem;
      padding: 0.7rem 4rem;

      a {
        text-transform: none;
      }
    }
  }
}
