.help-view {
  background: $ckGreen;
  background-image: url('../../../assets/images/scribs.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  overflow: auto;

  .header {
    background-color: $ckGreen;
    padding: 0 2.4rem;
    border-bottom: $ckGreen;

    .logo {
      width: 120px;
    }

    .title {
      color: white;
      margin-left: 8px;
    }

    a {
      color: white;
      text-transform: none;
    }
  }

  .body {
    .contents {
      background-color: #fff;
      border-radius: 5px;
      margin-top: 25px;
      padding: 15px 50px;

      .body-title {
        @extend .major-header;
        font-size: 20px;
        color: $ckHeaderGrey;
      }

      a {
        color: blue;
        padding: 0 5px;
      }
    }
  }
}
