.help-category {
  height: 100vh;
  overflow: auto;

  .header {
    background-color: $ckGreen;
    padding: 0 2.4rem;
    border-bottom: $ckGreen;

    .logo {
      width: 120px;
    }

    .title {
      color: white;
      margin-left: 8px;
    }

    a {
      color: white;
      text-transform: none;
    }
  }

  .body-top {

    .body-top-container {
      width: 1000px;

      .breadcrumbs {
        height: 70px;

        a, span {
          text-transform: none;
          line-height: 1;
        }

        .ck-pagernext {
          margin: 0 8px;
          color: $disabledGrey;
        }
      }

      .search .article-autocomplete {
        width: 250px;
      }

      .divider {
        background-color: $dividerGrey;
        height: 2px;
        width: 100%;
      }
    }
  }

  .what-is-classkick,
  .getting-started,
  .beyond-the-basics,
  .troubleshooting,
  .professional-development,
  .learn-about-features {

    .category-title-container, .articles-container {
      width: 1000px;
    }

    .category-title {
      font-size: 2.7rem;
      margin: 40px 0 16px 0;
    }

    p {
      font-family: $fontRoundedBook;
    }

    .video-container {
      width: 100%;
      padding: 24px 0;
      margin-bottom: 16px;
      background-color: $backgroundGrey;
    }

    .stretch-image-container {
      margin-bottom: 16px;
    }

    .articles {
      margin: 16px 0 100px 0;

      .article:not(:last-child) {
        margin-right: 16px;
      }

      .section-title {
        margin-bottom: 8px;
        cursor: pointer;
        outline: none;

        &:hover {
          color: $ckGreen;
        }
      }

      a {
        font-family: $fontRoundedBook;
        text-transform: none;
        margin-bottom: 8px;
      }

      .invisible-button {
        outline: none;
        height: 30px;
        width: 100%;
      }
    }
  }

  .connect-with-teacher {

    .connect-with-teacher-container {
      width: 1000px;
    }

    .category-title {
      font-size: 2.7rem;
      margin: 40px 0 16px 0;
    }

    p {
      font-family: $fontRoundedBook;
    }

    .sections-container {
      padding: 24px 0;

      .section {
        background-color: white;
        width: 226px;
        height: 170px;
        margin: 16px;
        padding: 32px;
        border-radius: 25px;
        outline: none;
        box-shadow: $boxShadowCard;
        cursor: pointer;

        .circle {
          @extend .major-header;
          height: 125px;
          width: 125px;
          background-color: #f2f5d5;
          color: $ckGreen;
          border-radius: 50%;
        }
      }

      .section:first-child {
        margin-left: 0;
      }

      .section:last-child {
        margin-right: 0;
      }
    }
  }
}
