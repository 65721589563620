.invite-teacher-dialog {

  max-height: 90%;
  min-width: unset;

  .invite {
    border-top: none;
    width: 600px;

    .ck-dialog-header {
      height: $teacherHeaderHeight;

      .ck-close {
        color: $ckGreen;
      }
    }

    .ck-dialog-title {
      @extend .classkick-header;
      text-align: center;
    }

    .invite-code {
      margin-top: 32px;
      color: $ckPenBlue;
      font-size: 35pt;
      height: 50px;
      font-family: $fontRoundedMedium;
    }

    a {
      text-transform: none;
    }

    .hint {
      font-family: $fontRoundedBook;
      margin-bottom: 32px;
    }

    .instructions {

      margin: 0 60px 60px 60px;

      .situation {
        margin-bottom: 8px;
      }

      .step {
        margin-bottom: 16px;
        font-family: $fontRoundedBook;

        span {
          font-family: $fontRoundedMedium;
          color: $ckPenBlue;
        }
      }
    }
  }

  .loading {

    .ck-dialog-body {
      overflow: hidden;
      padding: 0;

      .spinner {
        margin: 15px;
      }
    }
  }

}
